import React, { useEffect } from "react";
import styles from "./index.module.scss";
import CreateTeam from "../../../CreateTeam/CreateTeam";
import TeamList from "../../../TeamList";
import { setActivePage, uiSelector } from "../../../../store/ui";
import { useDispatch, useSelector } from "react-redux";
import TeamInvitationsList from "../../../TeamInvitationsList";
import RetroTemplates from "../../../Retro/RetroTemplates";
import CreateRetro from "../../../Retro/CreateRetro";
import RetroDashboard from "../../../Retro/RetroDashboard";
import RetroList from "../../../Retro/RetroList";
import ScrumPoker from "../../../ScrumPoker/ScrumPoker";
import ScrumPokerDashboard from "../../../ScrumPoker/ScrumPokerDashboard";
import { AppDispatch } from "../../../../store";

interface MainLayoutProps {
  type?: string;
}

const MainLayout = ({ type }: MainLayoutProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { activePage } = useSelector(uiSelector);
  useEffect(() => {
    if (type) {
      dispatch(setActivePage(type));
      localStorage.setItem("menuChildrenKey", type);
    }
  }, [dispatch, type]);
  return (
    <div className={styles.main}>
      {activePage === "Create Team" && <CreateTeam />}
      {activePage === "My Teams" && <TeamList />}
      {activePage === "Team Invitations" && <TeamInvitationsList />}
      {activePage === "Templates Management" && <RetroTemplates />}
      {activePage === "Create Retro" && <CreateRetro />}
      {activePage === "Retro Dashboard" && <RetroDashboard />}
      {activePage === "My Retros" && <RetroList />}
      {activePage === "Scrum Poker" && <ScrumPoker />}
      {activePage === "Scrum Poker Dashboard" && <ScrumPokerDashboard />}
    </div>
  );
};

export default MainLayout;
