import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { accountReducer } from "./account/reducer";
import { teamReducer } from "./team";
import { uiReducer } from "./ui";
import { notificationReducer } from "./notification";
import { conversationsReducer } from "./conversations";
import { retroReducer } from "./retro";
import { scrumPokerReducer } from "./scrumPoker";

export const store = configureStore({
  reducer: {
    account: accountReducer,
    team: teamReducer,
    ui: uiReducer,
    notification: notificationReducer,
    conversations: conversationsReducer,
    retro: retroReducer,
    scrum: scrumPokerReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;
