import { createReducer } from "@reduxjs/toolkit";
import { ConversationsState, Messages } from "./types";
import {
  getConversations,
  getMessages,
  sendMessage,
  socketAddMessage,
  markMessageRead,
} from "./actions";

const initialState: ConversationsState = {
  conversations: [
    {
      _id: "",
      participants: [
        {
          _id: "",
          username: "",
          firstName: "",
          lastName: "",
          email: "",
          image: "",
        },
      ],
      lastMessage: {
        message: "",
        createdAt: "",
        senderId: "",
      },
      lastMessageDate: "",
      lastMessageSender: "",
      chatId: "",
      isRead: true,
    },
  ],
  messages: {},
  getConversationsLoading: false,
};

export const conversationsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getConversations.pending, (state) => {
      state.getConversationsLoading = true;
    })
    .addCase(getConversations.rejected, (state) => {
      state.getConversationsLoading = false;
    })
    .addCase(getConversations.fulfilled, (state, action) => {
      state.getConversationsLoading = false;
      state.conversations = action.payload;
    })
    .addCase(getMessages.fulfilled, (state, action) => {
      const chatId = action.payload[0].chatId;

      state.messages[chatId] = action.payload;
    })
    .addCase(sendMessage.fulfilled, (state, action) => {
      const chatId = action.payload.chatId;
      if (state.messages[chatId]) {
        state.messages[chatId].push(action.payload as Messages);
      } else {
        state.messages[chatId] = [action.payload as Messages];
      }
      state.conversations = state.conversations.map((conversation) => {
        if (conversation.chatId === chatId) {
          return {
            ...conversation,
            lastMessage: {
              message: action.payload.message,
              createdAt: action.payload.createdAt,
              senderId: action.payload.senderId,
            },
            lastMessageDate: action.payload.createdAt,
            lastMessageSender: action.payload.senderId,
          };
        }
        return conversation;
      });
    })
    .addCase(socketAddMessage.fulfilled, (state, action) => {
      const chatId = action.payload.chatId;
      if (state.messages[chatId]) {
        state.messages[chatId].push(action.payload as Messages);
      } else {
        state.messages[chatId] = [action.payload as Messages];
      }
      state.conversations = state.conversations.map((conversation) => {
        if (conversation.chatId === chatId) {
          return {
            ...conversation,
            lastMessage: {
              message: action.payload.message,
              createdAt: action.payload.createdAt,
              senderId: action.payload.senderId,
            },
            lastMessageDate: action.payload.createdAt,
            lastMessageSender: action.payload.senderId,
            isRead: false,
          };
        }
        return conversation;
      });
    })
    .addCase(markMessageRead.fulfilled, (state, action) => {
      const chatId = action.payload.chatId;
      if (state.messages[chatId]) {
        state.messages[chatId] = state.messages[chatId].map((message) => {
          if (message.chatId === action.payload.chatId) {
            return { ...message, isRead: true };
          }
          return message;
        });
      }
      state.conversations = state.conversations.map((conversation) => {
        if (conversation.chatId === chatId) {
          return {
            ...conversation,
            isRead: true,
          };
        }
        return conversation;
      });
    });
});
