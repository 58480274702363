import { Retro, RetroState } from "./types";
import { createReducer } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getRetroTemplates,
  createRetroTemplate,
  deleteRetroTemplate,
  getRetro,
  socketUpdateRetro,
  getRetros,
} from "./actions";

const initialState: RetroState = {
  retroTemplateLoading: false,
  retroTemplates: [],
  retroLoading: false,
  retrosLoading: false,
  retros: [],
  retro: {
    _id: "",
    templateId: "",
    retroPrivate: false,
    teamId: "",
    retroName: "",
    columns: [
      {
        _id: "",
        name: "",
        description: "",
        items: [
          {
            _id: "",
            text: "",
            createdBy: "",
            createdAt: "",
            likes: [
              {
                _id: "",
                createdBy: "",
              },
            ],
            unLikes: [
              {
                _id: "",
                createdBy: "",
              },
            ],
            comments: [
              {
                _id: "",
                text: "",
                createdBy: "",
                createdAt: "",
              },
            ],
          },
        ],
      },
    ],
    createdBy: "",
    status: "",
    finishedAt: "",
    onShow: false,
    url: "",
    qrCode: "",
    questions: [
      {
        _id: "",
        questionText: "",
        createdBy: "",
        choices: [
          {
            _id: "",
            choiceText: "",
            createdBy: "",
            createdAt: "",
            answers: [
              {
                createdBy: "",
              },
            ],
          },
        ],
      },
    ],
    createdAt: "",
    team: {
      name: "",
      desripion: "",
      image: "",
    },
    template: {
      purpose: "",
      templateName: "",
    },
  },
};

export const retroReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getRetroTemplates.pending, (state) => {
      state.retroTemplateLoading = true;
    })
    .addCase(getRetroTemplates.rejected, (state) => {
      state.retroTemplateLoading = false;
      toast.error("Failed to get retro templates");
    })
    .addCase(getRetroTemplates.fulfilled, (state, action) => {
      state.retroTemplateLoading = false;
      const defaultTemplates = action.payload.defaultTemplates;
      const userTemplates = action.payload.userTemplates;
      state.retroTemplates = [...defaultTemplates, ...userTemplates];
    })
    .addCase(createRetroTemplate.pending, (state) => {
      state.retroTemplateLoading = true;
    })
    .addCase(createRetroTemplate.rejected, (state) => {
      state.retroTemplateLoading = false;
      toast.error("Failed to create retro template");
    })
    .addCase(createRetroTemplate.fulfilled, (state, action) => {
      state.retroTemplateLoading = false;
      state.retroTemplates.push(action.payload);
    })
    .addCase(deleteRetroTemplate.pending, (state) => {
      state.retroTemplateLoading = true;
    })
    .addCase(deleteRetroTemplate.rejected, (state) => {
      state.retroTemplateLoading = false;
      toast.error("Failed to delete retro template");
    })
    .addCase(deleteRetroTemplate.fulfilled, (state, action) => {
      state.retroTemplateLoading = false;
      state.retroTemplates = state.retroTemplates.filter(
        (template) => template._id !== action.payload.retroTemplateId
      );
    })
    .addCase(getRetro.pending, (state) => {
      state.retroLoading = true;
    })
    .addCase(getRetro.rejected, (state) => {
      state.retroLoading = false;
      toast.error("Failed to get retro");
    })
    .addCase(getRetro.fulfilled, (state, action) => {
      state.retroLoading = false;
      state.retro = action.payload as Retro;
    })
    .addCase(socketUpdateRetro, (state, action) => {
      const retroId = state.retro._id;
      if (retroId === action.payload.retro._id) {
        state.retro = action.payload.retro;
      }
    })
    .addCase(getRetros.pending, (state) => {
      state.retrosLoading = true;
    })
    .addCase(getRetros.rejected, (state) => {
      state.retrosLoading = false;
      toast.error("Failed to get retros");
    })
    .addCase(getRetros.fulfilled, (state, action) => {
      state.retrosLoading = false;
      state.retros = action.payload;
    });
});
