import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  UsergroupAddOutlined,
  TeamOutlined,
  UserSwitchOutlined,
  TrademarkOutlined,
  FileAddOutlined,
  BookOutlined,
  AppstoreAddOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import { Badge, Menu, MenuProps } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import { faSuperpowers } from "@fortawesome/free-brands-svg-icons";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { setActivePage, setCollapsed } from "../../store/ui";
import { notificationSelector } from "../../store/notification";

interface SidebarProps {}

const Sidebar = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { notifications } = useSelector(notificationSelector);
  const defaultMenuKey = localStorage.getItem("menuKey") || "retro-management";
  const defaultMenuChildrenKey =
    localStorage.getItem("menuChildrenKey") || "My Retros";
  const [collapse, setCollapse] = useState(false);
  const [menuKey, setMenuKey] = useState(defaultMenuKey);
  const [menuChildrenKey, setMenuChildrenKey] = useState(
    defaultMenuChildrenKey
  );
  const [isSpinning, setIsSpinning] = useState(false);

  useEffect(() => {
    dispatch(setCollapsed(collapse));
  }, [collapse]);

  const handleCollapse = () => {
    setCollapse(!collapse);
  };

  const handleMenuClick = (e: any) => {
    localStorage.setItem("menuKey", e.keyPath[1]);
    localStorage.setItem("menuChildrenKey", e.key);
    dispatch(setActivePage(e.key));
    setMenuKey(e.keyPath[1]);
    setMenuChildrenKey(e.key);
    if (e.key === "Scrum Poker") {
      setIsSpinning(true);
    } else {
      setIsSpinning(false);
    }
  };

  useEffect(() => {
    const menuChildrenKey = localStorage.getItem("menuChildrenKey");
    if (menuChildrenKey) {
      dispatch(setActivePage(menuChildrenKey));
    } else {
      dispatch(setActivePage("My Retros"));
    }
  }, [dispatch]);

  const teamItems: MenuProps["items"] = [
    {
      key: "team-management",
      title: "Team Management",
      icon: (
        <Badge
          dot={notifications.some((n) => !n.isRead && n.type === "teamRequest")}
          offset={[1, -3]}
          size="small"
          style={{ display: collapse ? "none" : "block" }}
        >
          <FontAwesomeIcon icon={faPeopleGroup} />
        </Badge>
      ),
      label: (
        <Badge
          dot={notifications.some((n) => !n.isRead && n.type === "teamRequest")}
          offset={[8, -4]}
          size="small"
          style={{ display: !collapse ? "none" : "block" }}
        >
          Team Management
        </Badge>
      ),
      children: [
        {
          key: "Create Team",
          icon: <UsergroupAddOutlined />,
          label: "Create Team",
        },
        {
          key: "My Teams",
          icon: <TeamOutlined />,
          label: "My Teams",
        },
        {
          key: "Team Invitations",
          icon: (
            <Badge
              count={
                notifications.filter(
                  (n) => !n.isRead && n.type === "teamRequest"
                ).length
              }
              offset={[4, -4]}
              size="small"
              style={{ display: collapse ? "none" : "block" }}
            >
              <UserSwitchOutlined />
            </Badge>
          ),
          label: (
            <Badge
              count={
                notifications.filter(
                  (n) => !n.isRead && n.type === "teamRequest"
                ).length
              }
              offset={[8, -4]}
              size="small"
              style={{ display: !collapse ? "none" : "block" }}
            >
              Team Invitations
            </Badge>
          ),
        },
      ],
    },
    {
      key: "retro-management",
      title: "Retro Management",
      icon: <TrademarkOutlined />,
      label: "Retro Management",
      children: [
        {
          key: "Create Retro",
          icon: <FileAddOutlined />,
          label: "Create Retro",
        },
        {
          key: "My Retros",
          icon: <BookOutlined />,
          label: "My Retros",
        },
        {
          key: "Templates Management",
          icon: <AppstoreAddOutlined />,
          label: "Templates Management",
        },
      ],
    },
    {
      key: "scrum-management",
      title: "Scrum Management",
      icon: (
        <FontAwesomeIcon
          icon={faSuperpowers}
          className={isSpinning ? styles.main_spin : ""}
        />
      ),
      label: "Scrum Poker Management",
      children: [
        {
          key: "Scrum Poker",
          icon: <FontAwesomeIcon icon={faCircleNotch} />,
          label: "Scrum Poker",
        },
      ],
    },
  ];

  return (
    <div className={`${styles.main} ${collapse ? styles.main_collapsed : ""}`}>
      <div className={styles.main_collapse} onClick={handleCollapse}>
        {collapse ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
      </div>
      <Menu
        style={{
          width: collapse ? "auto" : "3rem",
          borderInlineEnd: "none",
        }}
        defaultSelectedKeys={[menuKey]}
        defaultOpenKeys={[menuChildrenKey]}
        inlineCollapsed={!collapse}
        items={teamItems}
        mode="inline"
        onClick={handleMenuClick}
        triggerSubMenuAction="click"
      />
    </div>
  );
};

export default Sidebar;
