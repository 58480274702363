import React, { useState } from "react";
import styles from "./index.module.scss";
import Header from "./Components/Header";
import MainLayout from "./Components/MainLayout/MainLayout";

interface ContentProps {
  type?: string;
}

const Content = ({ type }: ContentProps) => {
  return (
    <div className={styles.main}>
      <Header />
      <MainLayout type={type} />
    </div>
  );
};

export default Content;
