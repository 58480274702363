import React, { useEffect, useMemo, useRef, useState } from "react";
import styles from "./index.module.scss";
import {
  QuestionCircleOutlined,
  PlusCircleOutlined,
  LoadingOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  PlusSquareOutlined,
  CloseCircleOutlined,
  SmileOutlined,
  EditOutlined,
  CommentOutlined,
  LikeOutlined,
  DislikeOutlined,
  LikeFilled,
  DislikeFilled,
  StopOutlined,
  FileJpgOutlined,
  FilePdfOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Input, Modal, Popover, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addChoice,
  addColumn,
  addColumnItem,
  addCommentItem,
  addLikeItem,
  addQuestion,
  deleteColumn,
  deleteColumnItem,
  deleteCommentItem,
  deleteLikeItem,
  deleteQuestion,
  getRetro,
  retroSelector,
  updateColumnItem,
  updateCommentItem,
} from "../../../store/retro";
import { AppDispatch } from "../../../store";
import { accountSelector } from "../../../store/account";
import { toast } from "react-toastify";
import { getTeams, teamSelector } from "../../../store/team";
import { setExportRetro, uiSelector } from "../../../store/ui";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { generateRandomLoremIpsum } from "../../../Hooks/Helper";

const emojiList = ["😀", "😂", "🤣", "😍", "😎", "👍", "🥳", "💯"];

const colors = [
  "#EDE1D2",
  "#F3D5C4",
  "#F9F2EB",
  "#EEEDE6",
  "#D6D3BF",
  "#F5E8DF",
  "#F9F2EB",
  "#EDE1D2",
];
const getColor = (columnIndex: any) => {
  return colors[columnIndex % colors.length];
};

const RetroDashboard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { retroLoading, retro } = useSelector(retroSelector);
  const { accountInfo } = useSelector(accountSelector);
  const { exportRetro } = useSelector(uiSelector);
  const { teams } = useSelector(teamSelector);
  const [activeColumnForInput, setActiveColumnForInput] = useState<
    string | null
  >(null);
  const [itemText, setItemText] = useState("");
  const [emojiPopoverVisible, setEmojiPopoverVisible] = useState(false);
  const [emojiEditPopoverVisible, setEmojiEditPopoverVisible] = useState(false);
  const [emojiCommentPopoverVisible, setEmojiCommentPopoverVisible] =
    useState(false);
  const [emojiEditCommentPopoverVisible, setEmojiEditCommentPopoverVisible] =
    useState(false);
  const [editableItemId, setEditableItemId] = useState(null);
  const [editText, setEditText] = useState("");
  const [commentingItemId, setCommentingItemId] = useState(null);
  const [commentText, setCommentText] = useState("");
  const [commentEditCommentingItemId, setCommentEditCommentingItemId] =
    useState(null);
  const [commentEditText, setCommentEditText] = useState("");
  const [addColumnModalVisible, setAddColumnModalVisible] = useState(false);
  const [newColumnName, setNewColumnName] = useState("");
  const [newColumnDescription, setNewColumnDescription] = useState("");
  const [addQuestionModalVisible, setAddQuestionModalVisible] = useState(false);
  const [choices, setChoices] = useState<any[]>([{ id: 1, choiceText: "" }]);
  const [question, setQuestion] = useState("");

  useEffect(() => {
    const retroId = localStorage.getItem("retroId");
    if (retroId) {
      dispatch(
        getRetro({
          retroId,
          onSuccess() {},
          onError() {},
        })
      );
      dispatch(getTeams());
    } else {
      localStorage.setItem("menuChildrenKey", "My Teams");
    }
  }, []);

  const isAdminOrCreator = useMemo(() => {
    const team = teams.find((team) => team._id === retro.teamId);
    const isCreator = retro.createdBy === accountInfo?._id;
    if (!team) {
      return isCreator;
    }
    const isAdmin = team.admin.some(
      (member) => member._id === accountInfo?._id
    );
    return isAdmin || isCreator;
  }, [teams, accountInfo, retro]);

  const handleAddItemClick = (columnId: string) => {
    setActiveColumnForInput(columnId);
  };

  const handleAddItem = (e: any) => {
    e.stopPropagation();
    dispatch(
      addColumnItem({
        retroId: retro._id,
        columnId: activeColumnForInput as string,
        text: itemText,
        onSuccess() {
          setActiveColumnForInput(null);
          setItemText("");
        },
      })
    );
  };

  const handleEmojiSelect = (emoji: string) => {
    setItemText((prev) => prev + emoji);
    setEmojiPopoverVisible(false);
  };

  const renderEmojiPicker = () => (
    <div className={styles.emojiPicker}>
      {emojiList.map((emoji) => (
        <span
          key={emoji}
          onClick={() => handleEmojiSelect(emoji)}
          style={{ cursor: "pointer", fontSize: "20px", padding: "3px" }}
        >
          {emoji}
        </span>
      ))}
    </div>
  );

  const handleEditEmojiSelect = (emoji: string) => {
    setEditText((prev) => prev + emoji);
    setEmojiEditPopoverVisible(false);
  };

  const renderEditEmojiPicker = () => (
    <div className={styles.emojiPicker}>
      {emojiList.map((emoji) => (
        <span
          key={emoji}
          onClick={() => handleEditEmojiSelect(emoji)}
          style={{ cursor: "pointer", fontSize: "20px", padding: "3px" }}
        >
          {emoji}
        </span>
      ))}
    </div>
  );

  const handleCommentEmojiSelect = (emoji: string) => {
    setCommentText((prev) => prev + emoji);
    setEmojiCommentPopoverVisible(false);
  };

  const renderCommentEmojiPicker = () => (
    <div className={styles.emojiPicker}>
      {emojiList.map((emoji) => (
        <span
          key={emoji}
          onClick={() => handleCommentEmojiSelect(emoji)}
          style={{ cursor: "pointer", fontSize: "20px", padding: "3px" }}
        >
          {emoji}
        </span>
      ))}
    </div>
  );

  const handleEditCommentEmojiSelect = (emoji: string) => {
    setCommentEditText((prev) => prev + emoji);
    setEmojiEditCommentPopoverVisible(false);
  };

  const renderEditCommentEmojiPicker = () => (
    <div className={styles.emojiPicker}>
      {emojiList.map((emoji) => (
        <span
          key={emoji}
          onClick={() => handleEditCommentEmojiSelect(emoji)}
          style={{ cursor: "pointer", fontSize: "20px", padding: "3px" }}
        >
          {emoji}
        </span>
      ))}
    </div>
  );

  const handleEdit = (item: any) => {
    setEditableItemId(item._id);
    setEditText(item.text);
  };

  const handleEditComment = (comment: any) => {
    setCommentEditCommentingItemId(comment._id);
    setCommentEditText(comment.text);
  };

  const handleSave = (itemId: any, columnId: any) => {
    dispatch(
      updateColumnItem({
        retroId: retro._id,
        columnId,
        itemId,
        text: editText,
        onSuccess() {
          setEditableItemId(null);
        },
      })
    );
    setEditableItemId(null);
  };

  const handleCancel = () => {
    setEditableItemId(null);
  };

  const handleCommentCancel = () => {
    setCommentEditCommentingItemId(null);
  };

  const handleComment = (item: any) => {
    setCommentingItemId(item._id);
    setEditableItemId(null);
    setEditText("");
  };

  const handleAddColumn = () => {
    dispatch(
      addColumn({
        retroId: retro._id,
        columnName: newColumnName,
        columnDescription: newColumnDescription,
        onSuccess() {
          setAddColumnModalVisible(false);
          setNewColumnName("");
          setNewColumnDescription("");
        },
      })
    );
  };

  const handleDeleteColumn = (columnId: string) => {
    dispatch(
      deleteColumn({
        retroId: retro._id,
        columnId,
        onSuccess() {},
      })
    );
  };

  const handleAddQuestion = () => {
    const validChoices = choices.filter(
      (choice) => choice.choiceText.trim() !== ""
    );

    dispatch(
      addQuestion({
        retroId: retro._id,
        question: question,
        choices: validChoices,
        onSuccess() {
          setAddQuestionModalVisible(false);
          setChoices([{ id: 1, choiceText: "" }]);
          setQuestion("");
        },
        onError(message) {
          toast.error(message);
        },
      })
    );
  };

  const handleChoiceTextChange = (id: string, text: string) => {
    setChoices((prev) =>
      prev.map((choice) =>
        choice.id === id ? { ...choice, choiceText: text } : choice
      )
    );
  };

  const downloadPDF = async () => {
    const element = document.getElementById("content");

    if (!element) {
      console.error("Element bulunamadı.");
      return;
    }

    const originalStyle = element.style.cssText;

    element.style.transform = "none";
    element.style.overflow = "visible";
    element.style.height = "auto";
    element.style.maxHeight = "none";

    try {
      const canvas = await html2canvas(element, {
        logging: true,
        useCORS: true,
        windowHeight: element.scrollHeight + window.scrollY + 1000,
        windowWidth: element.scrollWidth,
        scrollX: 0,
        scrollY: -window.scrollY,
      });

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 0, 0, 210, 297);
      pdf.save("retroThink.pdf");
    } catch (error) {
      console.error("Yakalama başarısız:", error);
    } finally {
      element.style.cssText = originalStyle;
    }
  };

  const downloadJPG = async () => {
    const element = document.getElementById("content");

    if (!element) {
      console.error("Element bulunamadı.");
      return;
    }

    const originalStyle = element.style.cssText;

    element.style.transform = "none";
    element.style.overflow = "visible";
    element.style.height = "auto";
    element.style.maxHeight = "none";

    try {
      const canvas = await html2canvas(element, {
        logging: true,
        useCORS: true,
        windowHeight: element.scrollHeight + window.scrollY + 600,
        windowWidth: element.scrollWidth,
        scrollX: 0,
        scrollY: -window.scrollY,
      });

      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = imgData;
      link.download = "retroThink.png";
      link.click();
    } catch (error) {
      console.error("Yakalama başarısız:", error);
    } finally {
      element.style.cssText = originalStyle;
    }
  };

  const renderRetro = () => {
    return (
      <div className={styles.main_retroArea} id="content">
        {retro?.questions.length > 0 && (
          <div className={styles.main_retroArea_question}>
            <div
              className={styles.main_retroArea_column_header}
              style={{
                height: "27px",
              }}
            >
              <div className={styles.main_retroArea_column_header_title}>
                Questions
              </div>
            </div>
            {retro?.questions.map((question) => (
              <div
                key={question._id}
                className={styles.main_retroArea_question_item}
              >
                <div className={styles.main_retroArea_question_item_title}>
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                    }}
                  >
                    {question.questionText}
                  </div>
                  <Tooltip title="Delete Question">
                    <div
                      className={styles.main_retroArea_question_item_title_icon}
                      onClick={() => {
                        dispatch(
                          deleteQuestion({
                            retroId: retro._id,
                            questionId: question._id,
                            onSuccess() {},
                          })
                        );
                      }}
                    >
                      <StopOutlined />
                    </div>
                  </Tooltip>
                </div>
                <div className={styles.main_retroArea_question_item_choices}>
                  {question.choices.map((choice) => (
                    <div
                      key={choice._id}
                      className={
                        styles.main_retroArea_question_item_choices_choice
                      }
                    >
                      <Checkbox
                        checked={choice.answers.includes(
                          accountInfo?._id as any
                        )}
                        onClick={() => {
                          dispatch(
                            addChoice({
                              retroId: retro._id,
                              questionId: question._id,
                              choiceId: choice._id,
                              onSuccess() {},
                            })
                          );
                        }}
                        disabled={retro?.status !== "active"}
                        style={{
                          display: "flex",
                          flex: 1,
                        }}
                      >
                        {choice.choiceText}
                      </Checkbox>
                      {choice.answers.length + " votes"}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}

        <div
          style={{
            display: "flex",
            gap: "6px",
            width: "100%",
            minHeight: "calc(100vh - 320px)",
          }}
        >
          {retro?.columns.map((column, columnIndex) => {
            const columnWidth = `calc(100% / ${retro.columns.length})`;
            const columnColor = getColor(columnIndex);

            return (
              <div
                key={column._id}
                className={styles.main_retroArea_column}
                style={{
                  width: columnWidth,
                }}
              >
                <div className={styles.main_retroArea_column_header}>
                  <div className={styles.main_retroArea_column_header_title}>
                    {column.name}
                  </div>
                  <div
                    className={styles.main_retroArea_column_header_icon}
                    onClick={() => {
                      retro?.status === "active" &&
                        handleAddItemClick(column._id);
                    }}
                    style={{
                      cursor:
                        retro?.status === "active" ? "pointer" : "not-allowed",
                    }}
                  >
                    <Tooltip title="Add Item">
                      <PlusSquareOutlined />
                    </Tooltip>
                  </div>
                  <div
                    className={styles.main_retroArea_column_header_icon}
                    onClick={() => {
                      if (retro?.status === "active" && isAdminOrCreator) {
                        handleDeleteColumn(column._id);
                      }
                    }}
                    style={{
                      cursor:
                        retro?.status === "active" && isAdminOrCreator
                          ? "pointer"
                          : "not-allowed",
                    }}
                  >
                    <Tooltip title="Delete Column">
                      <DeleteOutlined />
                    </Tooltip>
                  </div>
                </div>
                {activeColumnForInput === column._id && (
                  <div className={styles.main_retroArea_column_addItemArea}>
                    <Input.TextArea
                      placeholder="Add a new item..."
                      className={styles.main_retroArea_column_input}
                      style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
                      autoFocus
                      onPressEnter={handleAddItem}
                      value={itemText}
                      onChange={(e) => setItemText(e.target.value)}
                    />
                    <div
                      className={
                        styles.main_retroArea_column_addItemArea_actions
                      }
                    >
                      <div
                        className={
                          styles.main_retroArea_column_addItemArea_actions_button
                        }
                        onClick={handleAddItem}
                      >
                        <CheckCircleOutlined
                          style={{
                            color: "#52c41a",
                          }}
                        />
                      </div>
                      <div
                        className={
                          styles.main_retroArea_column_addItemArea_actions_button
                        }
                        onClick={() => {
                          setActiveColumnForInput(null);
                          setItemText("");
                        }}
                      >
                        <CloseCircleOutlined
                          style={{
                            color: "#f5222d",
                          }}
                        />
                      </div>
                      <Popover
                        content={renderEmojiPicker()}
                        trigger="click"
                        visible={emojiPopoverVisible}
                        onVisibleChange={setEmojiPopoverVisible}
                      >
                        <div
                          className={
                            styles.main_retroArea_column_addItemArea_actions_button
                          }
                        >
                          <SmileOutlined
                            style={{
                              width: "45px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          />
                        </div>
                      </Popover>
                    </div>
                  </div>
                )}
                <div className={styles.main_retroArea_column_items}>
                  {column.items.map((item, index) => (
                    <div
                      key={item._id}
                      className={styles.main_retroArea_column_items_item}
                      style={{
                        backgroundColor: columnColor,
                      }}
                    >
                      {editableItemId === item._id ? (
                        <Input.TextArea
                          className={styles.main_retroArea_column_input}
                          style={{
                            border: "1px solid #e0e0e0",
                            boxShadow: "none",
                          }}
                          autoFocus
                          value={editText}
                          onChange={(e) => setEditText(e.target.value)}
                          onPressEnter={() => handleSave(item._id, column._id)}
                        />
                      ) : (
                        <div
                          className={
                            styles.main_retroArea_column_items_item_text
                          }
                          style={{
                            filter:
                              retro.onShow ||
                              item.createdBy === accountInfo?._id
                                ? "none"
                                : "blur(3px)",
                            userSelect:
                              retro.onShow ||
                              item.createdBy === accountInfo?._id
                                ? "auto"
                                : "none",
                          }}
                        >
                          {retro.onShow || item.createdBy === accountInfo?._id
                            ? item.text
                            : generateRandomLoremIpsum(
                                item.text.split(" ").length
                              )}
                        </div>
                      )}
                      <div
                        className={
                          styles.main_retroArea_column_items_item_actions
                        }
                      >
                        {retro?.onShow && (
                          <div
                            style={{
                              display: "flex",
                              gap: "3px",
                              width: "100%",
                              justifyContent: "flex-start",
                            }}
                          >
                            <div
                              className={
                                styles.main_retroArea_column_items_item_actions_buttons
                              }
                              onClick={() => {
                                dispatch(
                                  addLikeItem({
                                    retroId: retro._id,
                                    columnId: column._id,
                                    itemId: item._id,
                                    onSuccess() {},
                                  })
                                );
                              }}
                            >
                              {item.likes.includes(accountInfo?._id as any) ? (
                                <>
                                  {retro?.status === "active" ? (
                                    <LikeFilled />
                                  ) : (
                                    <LikeOutlined />
                                  )}
                                </>
                              ) : (
                                <LikeOutlined />
                              )}
                              <span>{item.likes.length}</span>
                            </div>
                            <div
                              className={
                                styles.main_retroArea_column_items_item_actions_buttons
                              }
                              onClick={() => {
                                dispatch(
                                  deleteLikeItem({
                                    retroId: retro._id,
                                    columnId: column._id,
                                    itemId: item._id,
                                    onSuccess() {},
                                  })
                                );
                              }}
                            >
                              {item.unLikes.includes(
                                accountInfo?._id as any
                              ) ? (
                                <>
                                  {retro?.status === "active" ? (
                                    <DislikeFilled />
                                  ) : (
                                    <DislikeOutlined />
                                  )}
                                </>
                              ) : (
                                <DislikeOutlined />
                              )}
                              <span>{item.unLikes.length}</span>
                            </div>
                          </div>
                        )}
                        {retro?.status === "active" && (
                          <>
                            {item.createdBy === accountInfo?._id && (
                              <>
                                {editableItemId === item._id ? (
                                  <>
                                    <div
                                      className={
                                        styles.main_retroArea_column_items_item_actions_button
                                      }
                                      onClick={() =>
                                        handleSave(item._id, column._id)
                                      }
                                    >
                                      <Tooltip
                                        title="Save Item"
                                        placement="bottom"
                                      >
                                        <CheckCircleOutlined
                                          style={{
                                            width: "45px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color: "#52c41a",
                                          }}
                                        />
                                      </Tooltip>
                                    </div>
                                    <div
                                      className={
                                        styles.main_retroArea_column_items_item_actions_button
                                      }
                                      onClick={handleCancel}
                                    >
                                      <Tooltip
                                        title="Cancel Edit"
                                        placement="bottom"
                                      >
                                        <CloseCircleOutlined
                                          style={{
                                            width: "45px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        />
                                      </Tooltip>
                                    </div>
                                    <Popover
                                      content={renderEditEmojiPicker()}
                                      trigger="click"
                                      visible={emojiEditPopoverVisible}
                                      onVisibleChange={
                                        setEmojiEditPopoverVisible
                                      }
                                    >
                                      <div
                                        className={
                                          styles.main_retroArea_column_items_item_actions_button
                                        }
                                      >
                                        <SmileOutlined
                                          style={{
                                            width: "45px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        />
                                      </div>
                                    </Popover>
                                  </>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <div
                                      style={{ display: "flex", gap: "1px" }}
                                    >
                                      <div
                                        className={
                                          styles.main_retroArea_column_items_item_actions_button
                                        }
                                        onClick={() => handleEdit(item)}
                                      >
                                        <Tooltip
                                          title="Edit Item"
                                          placement="bottom"
                                        >
                                          <EditOutlined />
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <div
                                  className={
                                    styles.main_retroArea_column_items_item_actions_button
                                  }
                                  onClick={() => {
                                    dispatch(
                                      deleteColumnItem({
                                        retroId: retro._id,
                                        columnId: column._id,
                                        itemId: item._id,
                                        onSuccess() {},
                                      })
                                    );
                                  }}
                                >
                                  <Tooltip
                                    title="Delete Item"
                                    placement="bottom"
                                  >
                                    <DeleteOutlined
                                      style={{
                                        width: "45px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    />
                                  </Tooltip>
                                </div>
                              </>
                            )}
                            {retro?.onShow && (
                              <div
                                className={
                                  styles.main_retroArea_column_items_item_actions_button
                                }
                                onClick={() => handleComment(item)}
                              >
                                <Tooltip title="Add Comment" placement="bottom">
                                  <CommentOutlined
                                    style={{
                                      width: "45px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  />
                                </Tooltip>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      {commentingItemId === item._id && (
                        <div
                          className={styles.main_retroArea_column_addItemArea}
                        >
                          <Input.TextArea
                            className={styles.main_retroArea_column_input}
                            style={{
                              border: "1px solid #e0e0e0",
                              boxShadow: "none",
                            }}
                            autoFocus
                            placeholder="Add a new comment..."
                            value={commentText}
                            onChange={(e) => setCommentText(e.target.value)}
                            onPressEnter={() => {
                              dispatch(
                                addCommentItem({
                                  retroId: retro._id,
                                  columnId: column._id,
                                  itemId: item._id,
                                  text: commentText,
                                  onSuccess() {
                                    setCommentingItemId(null);
                                    setCommentText("");
                                  },
                                })
                              );
                            }}
                          />
                          <div
                            className={
                              styles.main_retroArea_column_addItemArea_actions
                            }
                          >
                            <div
                              className={
                                styles.main_retroArea_column_addItemArea_actions_buttons
                              }
                              onClick={() => {
                                dispatch(
                                  addCommentItem({
                                    retroId: retro._id,
                                    columnId: column._id,
                                    itemId: item._id,
                                    text: commentText,
                                    onSuccess() {
                                      setCommentingItemId(null);
                                      setCommentText("");
                                    },
                                  })
                                );
                              }}
                            >
                              <CheckCircleOutlined
                                style={{
                                  color: "#52c41a",
                                }}
                              />
                            </div>
                            <div
                              className={
                                styles.main_retroArea_column_addItemArea_actions_buttons
                              }
                              onClick={() => {
                                setCommentingItemId(null);
                                setCommentText("");
                              }}
                            >
                              <CloseCircleOutlined
                                style={{
                                  color: "#f5222d",
                                }}
                              />
                            </div>
                            <Popover
                              content={renderCommentEmojiPicker()}
                              trigger="click"
                              visible={emojiCommentPopoverVisible}
                              onVisibleChange={setEmojiCommentPopoverVisible}
                            >
                              <div
                                className={
                                  styles.main_retroArea_column_addItemArea_actions_buttons
                                }
                              >
                                <SmileOutlined
                                  style={{
                                    width: "45px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                />
                              </div>
                            </Popover>
                          </div>
                        </div>
                      )}
                      <div className={styles.main_retroArea_column_items}>
                        {item.comments.map((comment: any) => (
                          <div
                            key={comment._id}
                            className={styles.main_retroArea_column_items_item}
                          >
                            {commentEditCommentingItemId === comment._id ? (
                              <Input.TextArea
                                className={styles.main_retroArea_column_input}
                                style={{
                                  border: "1px solid #e0e0e0",
                                  boxShadow: "none",
                                }}
                                autoFocus
                                value={commentEditText}
                                onChange={(e) =>
                                  setCommentEditText(e.target.value)
                                }
                                onPressEnter={() => {
                                  dispatch(
                                    updateCommentItem({
                                      retroId: retro._id,
                                      columnId: column._id,
                                      itemId: item._id,
                                      commentId: comment._id,
                                      text: commentEditText,
                                      onSuccess() {
                                        setCommentEditCommentingItemId(null);
                                      },
                                    })
                                  );
                                }}
                              />
                            ) : (
                              <div
                                className={
                                  styles.main_retroArea_column_items_item_text
                                }
                                style={{
                                  filter:
                                    retro.onShow ||
                                    comment.createdBy === accountInfo?._id
                                      ? "none"
                                      : "blur(3px)",
                                  userSelect:
                                    retro.onShow ||
                                    comment.createdBy === accountInfo?._id
                                      ? "auto"
                                      : "none",
                                }}
                              >
                                {retro.onShow ||
                                comment.createdBy === accountInfo?._id
                                  ? comment.text
                                  : generateRandomLoremIpsum(
                                      comment.text.split(" ").length
                                    )}
                              </div>
                            )}
                            {retro?.status === "active" && (
                              <div
                                className={
                                  styles.main_retroArea_column_items_item_actions
                                }
                              >
                                {comment.createdBy === accountInfo?._id && (
                                  <>
                                    {commentEditCommentingItemId ===
                                    comment._id ? (
                                      <>
                                        <div
                                          className={
                                            styles.main_retroArea_column_items_item_actions_button
                                          }
                                          onClick={() =>
                                            dispatch(
                                              updateCommentItem({
                                                retroId: retro._id,
                                                columnId: column._id,
                                                itemId: item._id,
                                                commentId: comment._id,
                                                text: commentEditText,
                                                onSuccess() {
                                                  setCommentEditCommentingItemId(
                                                    null
                                                  );
                                                },
                                              })
                                            )
                                          }
                                        >
                                          <Tooltip
                                            title="Save Item"
                                            placement="bottom"
                                          >
                                            <CheckCircleOutlined />
                                          </Tooltip>
                                        </div>
                                        <div
                                          className={
                                            styles.main_retroArea_column_items_item_actions_button
                                          }
                                          onClick={handleCommentCancel}
                                        >
                                          <Tooltip
                                            title="Cancel Edit"
                                            placement="bottom"
                                          >
                                            <CloseCircleOutlined />
                                          </Tooltip>
                                        </div>
                                        <Popover
                                          content={renderEditCommentEmojiPicker()}
                                          trigger="click"
                                          visible={
                                            emojiEditCommentPopoverVisible
                                          }
                                          onVisibleChange={
                                            setEmojiEditCommentPopoverVisible
                                          }
                                        >
                                          <div
                                            className={
                                              styles.main_retroArea_column_items_item_actions_button
                                            }
                                          >
                                            <SmileOutlined
                                              style={{
                                                width: "45px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            />
                                          </div>
                                        </Popover>
                                      </>
                                    ) : (
                                      <div
                                        className={
                                          styles.main_retroArea_column_items_item_actions_button
                                        }
                                        onClick={() =>
                                          handleEditComment(comment)
                                        }
                                      >
                                        <Tooltip
                                          title="Edit Comment"
                                          placement="bottom"
                                        >
                                          <EditOutlined />
                                        </Tooltip>
                                      </div>
                                    )}

                                    <div
                                      className={
                                        styles.main_retroArea_column_items_item_actions_button
                                      }
                                      onClick={() => {
                                        dispatch(
                                          deleteCommentItem({
                                            retroId: retro._id,
                                            columnId: column._id,
                                            itemId: item._id,
                                            commentId: comment._id,
                                            onSuccess() {},
                                          })
                                        );
                                      }}
                                    >
                                      <Tooltip
                                        title="Delete Comment"
                                        placement="bottom"
                                      >
                                        <DeleteOutlined />
                                      </Tooltip>
                                    </div>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.main}>
      <Tooltip title="Add Question">
        <div
          className={styles.main_addQuestionArea}
          onClick={() => {
            retro?.status === "active" && setAddQuestionModalVisible(true);
          }}
          style={{
            cursor: retro?.status === "active" ? "pointer" : "not-allowed",
          }}
        >
          <QuestionCircleOutlined />
        </div>
      </Tooltip>
      <div className={styles.main_retroArea}>
        {retroLoading ? (
          <div className={styles.main_loading}>
            <LoadingOutlined />
          </div>
        ) : (
          renderRetro()
        )}
      </div>
      <Tooltip title="Add Column">
        <div
          className={styles.main_columnArea}
          onClick={() => {
            if (retro?.status === "active" && isAdminOrCreator) {
              setAddColumnModalVisible(true);
            }
          }}
          style={{
            cursor:
              retro?.status === "active" && isAdminOrCreator
                ? "pointer"
                : "not-allowed",
          }}
        >
          <PlusCircleOutlined />
        </div>
      </Tooltip>
      {addColumnModalVisible && (
        <Modal
          title="Add Column"
          open={addColumnModalVisible}
          onOk={() => setAddColumnModalVisible(false)}
          onCancel={() => setAddColumnModalVisible(false)}
          footer={null}
        >
          <div className={styles.main_modal}>
            <Input
              className={styles.main_modal_input}
              style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
              placeholder="Column Name"
              allowClear
              value={newColumnName}
              onChange={(e) => setNewColumnName(e.target.value)}
              onPressEnter={handleAddColumn}
            />
            <Input
              className={styles.main_modal_input}
              style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
              placeholder="Column Description"
              allowClear
              value={newColumnDescription}
              onChange={(e) => setNewColumnDescription(e.target.value)}
            />
            <Button
              className={styles.main_modal_button}
              type="primary"
              disabled={!newColumnName}
              onClick={handleAddColumn}
            >
              Add Column
            </Button>
          </div>
        </Modal>
      )}
      {addQuestionModalVisible && (
        <Modal
          title="Add Question"
          open={addQuestionModalVisible}
          onOk={() => setAddQuestionModalVisible(false)}
          onCancel={() => setAddQuestionModalVisible(false)}
          footer={null}
        >
          <div className={styles.main_modal}>
            <Input
              className={styles.main_modal_input}
              style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
              placeholder="Question"
              allowClear
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
            {choices.map((choice) => (
              <div key={choice.id} className={styles.main_modal_choice}>
                <Input
                  className={styles.main_modal_input}
                  style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
                  placeholder="Choice"
                  allowClear
                  value={choice.choiceText || ""}
                  onChange={(e) =>
                    handleChoiceTextChange(choice.id, e.target.value)
                  }
                />
                <div
                  className={styles.main_modal_deleteIcon}
                  onClick={() =>
                    setChoices((prev) => prev.filter((c) => c.id !== choice.id))
                  }
                >
                  <DeleteOutlined />
                </div>
              </div>
            ))}
            <Button
              className={styles.main_modal_button}
              type="primary"
              onClick={() =>
                setChoices((prev) => [
                  ...prev,
                  { id: prev.length + 1, choiceText: "" },
                ])
              }
            >
              Add Choice
            </Button>
            <Button
              className={styles.main_modal_button}
              type="primary"
              onClick={handleAddQuestion}
            >
              Add Question
            </Button>
          </div>
        </Modal>
      )}
      {exportRetro && (
        <Modal
          title="Export Retro"
          open={exportRetro}
          onCancel={() => dispatch(setExportRetro(false))}
          footer={null}
          width={210}
        >
          <div
            style={{
              fontSize: "5rem",
            }}
          >
            <FileJpgOutlined
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                downloadJPG();
                dispatch(setExportRetro(false));
              }}
            />
            <FilePdfOutlined
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                downloadPDF();
                dispatch(setExportRetro(false));
              }}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default RetroDashboard;
