import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import { Button, Input, Modal } from "antd";
import { isUser, teamSelector } from "../../../store/team";
import { addFriend, inviteFriend } from "../../../store/account";
import { toast } from "react-toastify";

interface AddFriendModalProps {
  visible: boolean;
  onClose: () => void;
}

const AddFriendModal = ({ visible, onClose }: AddFriendModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { addMemberLoading } = useSelector(teamSelector);
  const [user, setUser] = useState("");
  const [buttonLabel, setButtonLabel] = useState("Check User");
  const [friendId, setFriendId] = useState("");

  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleCheckEmail = () => {
    switch (buttonLabel) {
      case "Check User":
        dispatch(
          isUser({
            email: user,
            onSuccess: (id) => {
              setButtonLabel("Add Friend");
              setFriendId(id);
            },
            onError: () => {
              setButtonLabel("İnvite RetroThink");
            },
          })
        );
        break;
      case "Add Friend":
        dispatch(
          addFriend({
            userId: friendId,
            onSuccess: () => {
              onClose();
            },
          })
        );
        break;
      case "İnvite RetroThink":
        if (!isValidEmail(user)) {
          toast.error("Invalid email format.");
          setButtonLabel("Check User");
          return;
        }
        dispatch(
          inviteFriend({
            email: user,
            onSuccess: () => {
              onClose();
            },
          })
        );
        break;
    }
  };

  useEffect(() => {
    if (user && buttonLabel !== "Check User") {
      setButtonLabel("Check User");
    }
  }, [user]);

  return (
    <Modal title="Add Friend" open={visible} onCancel={onClose} footer={null}>
      <div className={styles.main}>
        <Input
          className={styles.main_input}
          style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
          placeholder="Username or Email"
          allowClear
          value={user}
          onChange={(e) => setUser(e.target.value)}
          type="text"
          onPressEnter={handleCheckEmail}
        />
        <Button
          className={styles.main_button}
          type="primary"
          disabled={!user}
          onClick={handleCheckEmail}
          loading={addMemberLoading}
        >
          {buttonLabel}
        </Button>
      </div>
    </Modal>
  );
};

export default AddFriendModal;
