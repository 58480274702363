import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ScrumPokerTypes } from "./types";
import axios from "axios";
import endpoints from "../../services/endpoints";
import authService, { http } from "../../services/authService";
import { RootState } from "../index";

export const getScrumPokerRoomId = createAsyncThunk(
  ScrumPokerTypes.GET_SCRUM_POKER_ROOM_ID,
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get(
        endpoints.baseUrl + endpoints.getScrumPokerRoomId
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createScrumPokerRoom = createAsyncThunk(
  ScrumPokerTypes.CREATE_SCRUM_POKER_ROOM,
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.post(
        endpoints.baseUrl + endpoints.createScrumPokerRoom
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface IScrumPokerState {
  roomId: string;
  onSuccess: (roomId: string) => void;
  onError: () => void;
}

export const isScrum = createAsyncThunk(
  ScrumPokerTypes.IS_SCRUM,
  async (payload: IScrumPokerState, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.isScrumPoker}`,
        payload
      );
      payload.onSuccess(payload.roomId);
      return response.data;
    } catch (error: any) {
      payload.onError();
      return rejectWithValue(error.response.data);
    }
  }
);

interface IGetScrumPokerState {
  roomId: string;
}

export const getScrumPoker = createAsyncThunk(
  ScrumPokerTypes.GET_SCRUM_POKER,
  async (payload: IGetScrumPokerState, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.getScrumPokerRoom}`,
        payload
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const socketUpdateScrumPoker = createAction(
  ScrumPokerTypes.SOCKET_SCRUM_POKER,
  (scrumPoker: any) => {
    return { payload: scrumPoker };
  }
);

interface IVoteScrumPokerState {
  roomId: string;
  value: string;
}

export const voteScrumPoker = createAsyncThunk(
  ScrumPokerTypes.VOTE_SCRUM_POKER,
  async (payload: IVoteScrumPokerState, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.voteScrumPoker}`,
        payload
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface IResetScrumPokerState {
  roomId: string;
  onSuccess: () => void;
}

export const resetScrumPoker = createAsyncThunk(
  ScrumPokerTypes.RESET_SCRUM_POKER,
  async (payload: IResetScrumPokerState, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.resetScrumPoker}`,
        payload
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface IChangeScrumPokerStatus {
  roomId: string;
  locked: boolean;
}

export const changeScrumPokerStatus = createAsyncThunk(
  ScrumPokerTypes.CHANGE_SCRUM_POKER_STATUS,
  async (payload: IChangeScrumPokerStatus, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.changeScrumPokerStatus}`,
        payload
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface IChangeScrumPokerTitle {
  roomId: string;
  title: string;
}

export const changeScrumPokerTitle = createAsyncThunk(
  ScrumPokerTypes.CHANGE_SCRUM_POKER_TITLE,
  async (payload: IChangeScrumPokerTitle, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.changeScrumPokerTitle}`,
        payload
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface IChangeScrumPokerDescription {
  roomId: string;
  description: string;
}

export const changeScrumPokerDescription = createAsyncThunk(
  ScrumPokerTypes.CHANGE_SCRUM_POKER_DESCRIPTION,
  async (payload: IChangeScrumPokerDescription, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.changeScrumPokerDescription}`,
        payload
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface IChangeScrumPokerCards {
  roomId: string;
  cards: any;
}

export const changeScrumPokerCards = createAsyncThunk(
  ScrumPokerTypes.CHANGE_SCRUM_POKER_CARDS,
  async (payload: IChangeScrumPokerCards, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.changeScrumPokerCards}`,
        payload
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface IDefaultScrumPokerCards {
  roomId: string;
}

export const defaultScrumPokerCards = createAsyncThunk(
  ScrumPokerTypes.DEFAULT_SCRUM_POKER_CARDS,
  async (payload: IDefaultScrumPokerCards, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.defaultScrumPokerCards}`,
        payload
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
