import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Button, Input, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store";
import {
  addMemberToTeam,
  getTeams,
  isUser,
  teamSelector,
} from "../../../../store/team";
import { toast } from "react-toastify";
import { inviteFriend } from "../../../../store/account";

interface AddMemberModalProps {
  visible: boolean;
  onClose: () => void;
  teamId?: string;
}

const AddMemberModal = ({ visible, onClose, teamId }: AddMemberModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { addMemberLoading } = useSelector(teamSelector);
  const [email, setEmail] = useState("");
  const [buttonLabel, setButtonLabel] = useState("Check User");

  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleCheckEmail = () => {
    if (!teamId) {
      toast.error("Team ID is missing.");
      return;
    }
    switch (buttonLabel) {
      case "Check User":
        dispatch(
          isUser({
            email,
            onSuccess: () => {
              setButtonLabel("Invite Team");
            },
            onError: () => {
              setButtonLabel("İnvite RetroThink");
            },
          })
        );
        break;
      case "Invite Team":
        dispatch(
          addMemberToTeam({
            teamId,
            user: email,
            onSuccess: () => {
              onClose();
              dispatch(getTeams());
            },
            onError: (message) => {
              toast.error(message);
            },
          })
        );
        break;
      case "İnvite RetroThink":
        if (!isValidEmail(email)) {
          toast.error("Invalid email format.");
          setButtonLabel("Check Email");
          return;
        }
        dispatch(
          inviteFriend({
            email,
            onSuccess: () => {
              onClose();
            },
          })
        );
        break;
    }
  };

  useEffect(() => {
    if (email && buttonLabel !== "Check User") {
      setButtonLabel("Check User");
    }
  }, [email]);

  return (
    <>
      <Modal
        title="Add Member"
        open={visible}
        onCancel={onClose}
        onOk={onClose}
        footer={null}
      >
        <div className={styles.main}>
          <Input
            className={styles.main_input}
            style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
            placeholder="username or email"
            allowClear
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            onPressEnter={handleCheckEmail}
          />
          <Button
            className={styles.main_button}
            type="primary"
            onClick={handleCheckEmail}
            loading={addMemberLoading}
            disabled={!email}
          >
            {buttonLabel}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default AddMemberModal;
