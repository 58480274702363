// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainLayout_main__37NJ1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: calc(100vh - 210px);
  box-sizing: border-box;
  flex-direction: row;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/Content/Components/MainLayout/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,uBAAA;EACA,WAAA;EACA,2BAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,uCAAA;EACA,qBAAA;EACA,gBAAA;AACF","sourcesContent":[".main {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  width: 100%;\n  height: calc(100vh - 210px);\n  box-sizing: border-box;\n  flex-direction: row;\n  background-color: white;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  border-radius: 0.5rem;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `MainLayout_main__37NJ1`
};
export default ___CSS_LOADER_EXPORT___;
