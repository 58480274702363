import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { accountSelector } from "../../store/account";
import { AppDispatch } from "../../store";
import { getTeams, teamSelector } from "../../store/team";
import { Button, Dropdown, Input, Modal, Select, Tooltip } from "antd";
import {
  ShareAltOutlined,
  LoginOutlined,
  UsergroupAddOutlined,
  LoadingOutlined,
  CopyOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";
import {
  createScrumPokerRoom,
  getScrumPokerRoomId,
  scrumSelector,
} from "../../store/scrumPoker";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGolang } from "@fortawesome/free-brands-svg-icons";
import endpoints from "../../services/endpoints";

const ScreumPoker = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loggedIn } = useSelector(accountSelector);
  const { teams } = useSelector(teamSelector);
  const { roomId, getRoomIdLoading } = useSelector(scrumSelector);

  const [selectedTeamId, setSelectedTeamId] = useState("");
  const [joinRoomId, setJoinRoomId] = useState("");
  const [shareYourOwnRoomModalVisible, setShareYourOwnRoomModalVisible] =
    useState(false);

  useEffect(() => {
    if (loggedIn) {
      dispatch(getTeams());
      dispatch(getScrumPokerRoomId());
    }
  }, [loggedIn]);
  return (
    <div className={styles.main}>
      <div className={styles.main_left}>
        <div className={styles.main_left_table}>
          <div className={styles.main_left_table_row}>
            <Input
              placeholder={roomId ? roomId : "You don't have a room yet"}
              className={styles.main_input}
              style={{
                border: "1px solid #e0e0e0",
                boxShadow: "none",
                width: " 40%",
                userSelect: "text",
              }}
              readOnly
              suffix={
                roomId ? (
                  <Tooltip title="Copy Room Id">
                    <CopyOutlined
                      onClick={() => {
                        navigator.clipboard.writeText(roomId);
                        toast.success("Room Id copied to clipboard");
                      }}
                    />
                  </Tooltip>
                ) : null
              }
            />
            <div style={{ display: "flex", gap: "0.3rem" }}>
              {roomId && (
                <Tooltip title="Join Your Own Room" placement="top">
                  <div
                    className={styles.main_left_table_row_icon}
                    onClick={() => {
                      if (roomId) {
                        window.location.href =
                          endpoints.fronendUrl + "/scrumPoker/" + roomId;
                      }
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faGolang}
                      size={"2x"}
                      color="#f35d5a"
                    />
                  </div>
                </Tooltip>
              )}

              <Tooltip
                title={roomId ? "Share Room Id" : "Create Room"}
                placement="right"
              >
                <div
                  className={styles.main_left_table_row_icon}
                  onClick={() => {
                    if (roomId) {
                      setShareYourOwnRoomModalVisible(true);
                    }
                  }}
                >
                  {getRoomIdLoading ? (
                    <LoadingOutlined />
                  ) : roomId ? (
                    <ShareAltOutlined
                      style={{
                        padding: " 0 0.8rem",
                      }}
                    />
                  ) : (
                    <span
                      style={{ padding: " 0 0.5rem", color: "#B8B8B8" }}
                      onClick={() => dispatch(createScrumPokerRoom())}
                    >
                      Create Room First
                    </span>
                  )}
                </div>
              </Tooltip>
            </div>
          </div>
          <div className={styles.main_left_table_row}>
            <Select
              placeholder="Select a team to join"
              allowClear
              className={styles.main_input}
              style={{
                border: "1px solid #e0e0e0",
                boxShadow: "none",
                width: " 40%",
              }}
              onChange={(value) => setSelectedTeamId(value)}
            >
              {teams.map((team) => (
                <Select.Option key={team._id} value={team._id}>
                  {team.name}
                </Select.Option>
              ))}
            </Select>
            <Tooltip title="Join Team Room" placement="right">
              <div
                className={styles.main_left_table_row_icon}
                style={{
                  cursor: selectedTeamId ? "pointer" : "not-allowed",
                }}
                onClick={() => {
                  if (selectedTeamId) {
                    window.location.href =
                      endpoints.fronendUrl + "/scrumPoker/" + selectedTeamId;
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={faGolang}
                  size={"2x"}
                  color={selectedTeamId ? "#f35d5a" : "#e0e0e0"}
                />
              </div>
            </Tooltip>
          </div>
          <div className={styles.main_left_table_row}>
            <Input
              placeholder="Enter Room ID to join"
              className={styles.main_input}
              style={{
                border: "1px solid #e0e0e0",
                boxShadow: "none",
                width: " 40%",
              }}
              onChange={(e) => setJoinRoomId(e.target.value)}
              type="number"
              allowClear
            />
            <Tooltip title="Join Room" placement="right">
              <div
                className={styles.main_left_table_row_icon}
                style={{
                  cursor: joinRoomId ? "pointer" : "not-allowed",
                }}
                onClick={() => {
                  if (joinRoomId) {
                    window.location.href =
                      endpoints.fronendUrl + "/scrumPoker/" + joinRoomId;
                  }
                }}
              >
                <FontAwesomeIcon
                  icon={faGolang}
                  size={"2x"}
                  color={joinRoomId ? "#f35d5a" : "#e0e0e0"}
                />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={styles.main_right}>
        <div className={styles.main_right_title}>Instant Room Setup</div>
        <div className={styles.main_right_content}>
          Create an instant Scrum poker room on retrothink.io. You can set up a
          room within seconds on your first use. Later, you can speed up the
          process even more by registering to reuse the same room number.
        </div>
        <div className={styles.main_right_title}>Invite Participants</div>
        <div className={styles.main_right_content}>
          Invite your colleagues to the Scrum poker session organized on
          retrothink.io. You can share the room ID, provide a QR code, or send
          them a direct link. If you are having trouble finding a suitable time,
          you can use the scheduling poll tool specifically developed for your
          project.
        </div>
        <div className={styles.main_right_title}>
          Share and Start the Scrum Poker Session
        </div>
        <div className={styles.main_right_content}>
          Once your team has joined the room on retrothink.io, you can see
          everyone online and start the Scrum poker session. Members can enter
          their estimates, monitor who has made estimates, and view the results.
        </div>
        <div className={styles.main_right_title}>
          Tips for Successful Story Estimation
        </div>
        <div className={styles.main_right_content}>
          Using story points to assess the complexity of tasks on retrothink.io
          helps reach consensus on acceptance criteria and the definition of the
          work. This aids the product owner in prioritizing the backlog more
          effectively.
        </div>
        <div className={styles.main_right_title}>
          Estimating with Scrum Poker on retrothink.io
        </div>
        <div className={styles.main_right_content}>
          On the retrothink.io platform, complexity estimates are made using the
          Fibonacci sequence. This process involves establishing a reference
          story and comparing other stories to this benchmark. After sufficient
          discussion on each story, the development team assigns story points to
          estimate its complexity.
        </div>
        <div className={styles.main_right_title}>
          Reveal the Estimation Results
        </div>
        <div className={styles.main_right_content}>
          After all estimates have been collected, if everyone's opinions are
          close, it indicates that the story has been successfully estimated. If
          there are discrepancies, this may show a lack of common understanding
          of the story's requirements, leading to a potential redefinition of
          the acceptance criteria.
        </div>
      </div>
      {shareYourOwnRoomModalVisible && (
        <Modal
          title="Share Your Room"
          visible={shareYourOwnRoomModalVisible}
          onCancel={() => setShareYourOwnRoomModalVisible(false)}
          footer={null}
        >
          <div className={styles.main_share}>
            <Input
              className={styles.main_actions_input}
              style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
              value={`${window.location.origin}/scrumPoker/${roomId}`}
              readOnly
            />
            <Button
              className={styles.main_share_button}
              type="primary"
              onClick={() => {
                navigator.clipboard.writeText(
                  `${window.location.origin}/scrumPoker/${roomId}`
                );
                toast.success("Link copied to clipboard");
              }}
            >
              <CopyOutlined />
            </Button>
            <Dropdown
              trigger={["click"]}
              overlay={
                <div className={styles.main_share_qr}>
                  <img
                    src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${roomId}`}
                    alt="qr"
                  />
                </div>
              }
            >
              <Button className={styles.main_share_button} type="primary">
                <QrcodeOutlined />
              </Button>
            </Dropdown>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ScreumPoker;
