// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_main__kd6P4 {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.Footer_main_copy__VtOVD {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
  font-size: 0.6rem;
  letter-spacing: 1px;
  color: #f45d5a;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,uBAAA;EACA,uCAAA;AACF;AAAE;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;EACA,cAAA;AAEJ","sourcesContent":[".main {\n  display: flex;\n  box-sizing: border-box;\n  width: 100%;\n  background-color: white;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  &_copy {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    width: 100%;\n    padding: 10px 20px;\n    font-size: 0.6rem;\n    letter-spacing: 1px;\n    color: #f45d5a;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Footer_main__kd6P4`,
	"main_copy": `Footer_main_copy__VtOVD`
};
export default ___CSS_LOADER_EXPORT___;
