import { TeamState } from "./types";
import {
  createTeam,
  getTeams,
  isUser,
  addMemberToTeam,
  getInvitations,
  acceptInvitation,
  rejectInvitation,
  deleteTeam,
  removeMember,
  leaveTeam,
  removeRequest,
  searchInvitations,
  searchTeams,
} from "./actions";
import { createReducer } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState: TeamState = {
  createTeamLoading: false,
  getTeamsLoading: false,
  addMemberLoading: false,
  invitationsLoading: false,
  isSearchResults: false,
  teams: [],
  invitations: [],
};

export const teamReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(createTeam.pending, (state) => {
      state.createTeamLoading = true;
    })
    .addCase(createTeam.fulfilled, (state) => {
      state.createTeamLoading = false;
    })
    .addCase(createTeam.rejected, (state) => {
      state.createTeamLoading = false;
      toast.error("Failed to create team");
    })
    .addCase(getTeams.pending, (state) => {
      state.getTeamsLoading = true;
    })
    .addCase(getTeams.rejected, (state) => {
      state.getTeamsLoading = false;
      toast.error("Failed to get teams");
    })
    .addCase(getTeams.fulfilled, (state, action) => {
      state.getTeamsLoading = false;
      state.isSearchResults = false;
      state.teams = action.payload;
    })
    .addCase(isUser.pending, (state) => {
      state.addMemberLoading = true;
    })
    .addCase(isUser.rejected, (state) => {
      state.addMemberLoading = false;
    })
    .addCase(isUser.fulfilled, (state) => {
      state.addMemberLoading = false;
    })
    .addCase(addMemberToTeam.pending, (state) => {
      state.addMemberLoading = true;
    })
    .addCase(addMemberToTeam.rejected, (state) => {
      state.addMemberLoading = false;
    })
    .addCase(addMemberToTeam.fulfilled, (state) => {
      state.addMemberLoading = false;
    })
    .addCase(getInvitations.pending, (state) => {
      state.invitationsLoading = true;
    })
    .addCase(getInvitations.rejected, (state) => {
      state.invitationsLoading = false;
    })
    .addCase(getInvitations.fulfilled, (state, action) => {
      state.invitationsLoading = false;
      state.invitations = action.payload;
    })
    .addCase(acceptInvitation.pending, (state) => {
      state.invitationsLoading = true;
    })
    .addCase(acceptInvitation.rejected, (state) => {
      state.invitationsLoading = false;
    })
    .addCase(acceptInvitation.fulfilled, (state, action) => {
      state.invitations = state.invitations.filter(
        (invitation) => invitation._id !== action.payload.teamId
      );
      state.invitationsLoading = false;
    })
    .addCase(rejectInvitation.pending, (state) => {
      state.invitationsLoading = true;
    })
    .addCase(rejectInvitation.rejected, (state) => {
      state.invitationsLoading = false;
    })
    .addCase(rejectInvitation.fulfilled, (state, action) => {
      state.invitations = state.invitations.filter(
        (invitation) => invitation._id !== action.payload.teamId
      );
      state.invitationsLoading = false;
    })
    .addCase(deleteTeam.pending, (state) => {
      state.getTeamsLoading = true;
    })
    .addCase(deleteTeam.rejected, (state) => {
      state.getTeamsLoading = false;
    })
    .addCase(deleteTeam.fulfilled, (state, action) => {
      state.teams = state.teams.filter(
        (team) => team._id !== action.payload.teamId
      );
      state.getTeamsLoading = false;
    })
    .addCase(removeMember.pending, (state) => {
      state.getTeamsLoading = true;
    })
    .addCase(removeMember.rejected, (state) => {
      state.getTeamsLoading = false;
    })
    .addCase(removeMember.fulfilled, (state, action) => {
      state.teams = state.teams.map((team) => {
        if (team._id === action.payload.teamId) {
          team.members = team.members.filter(
            (member) => member._id !== action.payload.userId
          );
        }
        return team;
      });
      state.getTeamsLoading = false;
    })
    .addCase(leaveTeam.pending, (state) => {
      state.getTeamsLoading = true;
    })
    .addCase(leaveTeam.rejected, (state) => {
      state.getTeamsLoading = false;
    })
    .addCase(leaveTeam.fulfilled, (state, action) => {
      state.teams = state.teams.filter(
        (team) => team._id !== action.payload.teamId
      );
      state.getTeamsLoading = false;
    })
    .addCase(removeRequest.pending, (state) => {
      state.getTeamsLoading = true;
    })
    .addCase(removeRequest.rejected, (state) => {
      state.getTeamsLoading = false;
    })
    .addCase(removeRequest.fulfilled, (state, action) => {
      state.teams = state.teams.map((team) => {
        if (team._id === action.payload.teamId) {
          team.requests = team.requests.filter(
            (request) => request._id !== action.payload.userId
          );
        }
        return team;
      });
      state.getTeamsLoading = false;
    })
    .addCase(searchInvitations.pending, (state) => {
      state.invitationsLoading = true;
    })
    .addCase(searchInvitations.rejected, (state) => {
      state.invitationsLoading = false;
    })
    .addCase(searchInvitations.fulfilled, (state, action) => {
      state.invitations = action.payload;
      state.invitationsLoading = false;
      state.isSearchResults = true;
    })
    .addCase(searchTeams.pending, (state) => {
      state.getTeamsLoading = true;
    })
    .addCase(searchTeams.rejected, (state) => {
      state.getTeamsLoading = false;
    })
    .addCase(searchTeams.fulfilled, (state, action) => {
      state.teams = action.payload;
      state.getTeamsLoading = false;
      state.isSearchResults = true;
    });
});
