import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserLogin from "./pages/login";
import Register from "./pages/register";
import { useDispatch } from "react-redux";
import { AppDispatch } from "./store";
import { getUserInfo } from "./store/account";
import Layout from "./pages/layout";
import ForgotPassword from "./pages/mails/ForgorPassword/ForgotPassword";
import EmailVerify from "./pages/mails/EmailVerify/EmailVerify";
import Redirect from "./pages/redirect";

function App() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const retroLinkPath = window.location.pathname.startsWith("/retro/");
    const scrumLinkPath = window.location.pathname.startsWith("/scrumPoker/");
    const redirect = localStorage.getItem("redirect");
    if (accessToken && !retroLinkPath && !scrumLinkPath && !redirect) {
      dispatch(getUserInfo());
    } else if (!accessToken && scrumLinkPath) {
      localStorage.setItem("redirect", window.location.pathname);
      window.location.href = "/login";
    } else if (!accessToken && retroLinkPath) {
      localStorage.setItem("redirect", window.location.pathname);
      window.location.href = "/login";
    } else if (accessToken && redirect) {
      navigate(redirect);
      localStorage.removeItem("redirect");
    }
  }, [dispatch, navigate]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route path="/login" element={<UserLogin />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset-password/:token" element={<ForgotPassword />} />
        <Route path="/verify-email/:token" element={<EmailVerify />} />
        <Route path="/retro/:id" element={<Redirect type={"retro"} />} />
        <Route path="/scrumPoker/:id" element={<Redirect type={"scrum"} />} />
        <Route path="create-team" element={<Layout type="Create Team" />} />
        <Route path="my-teams" element={<Layout type="My Teams" />} />
        <Route
          path="team-invitations"
          element={<Layout type="Team Invitations" />}
        />
        <Route
          path="templates-management"
          element={<Layout type="Templates Management" />}
        />
        <Route path="create-retro" element={<Layout type="Create Retro" />} />
        <Route
          path="retro-dashboard"
          element={<Layout type="Retro Dashboard" />}
        />
        <Route path="my-retros" element={<Layout type="My Retros" />} />
        <Route path="scrum-poker" element={<Layout type="Scrum Poker" />} />
      </Routes>
      <ToastContainer position="top-right" autoClose={2000} />
    </>
  );
}

export default App;
