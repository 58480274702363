import {
  Form,
  Input,
  Modal,
  Select,
  DatePicker,
  Button,
  Popover,
  Tooltip,
  message,
  Upload,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  accountSelector,
  changeMail,
  checkEmail,
  checkUsername,
  getUserInfo,
  updatePassword,
  updateProfile,
} from "../../../store/account";
import { useDispatch, useSelector } from "react-redux";
import styles from "./index.module.scss";
import { AppDispatch } from "../../../store";
import { debounce } from "lodash";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import endpoints from "../../../services/endpoints";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import ImgCrop from "antd-img-crop";
import { RcFile } from "antd/es/upload";
import { toast } from "react-toastify";

interface UpdateProfileProps {
  visible: boolean;
  onClose: () => void;
}

const UpdateProfile = ({ visible, onClose }: UpdateProfileProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    accountInfo,
    updateMailLoading,
    changePasswordLoading,
    updateProfileLoading,
  } = useSelector(accountSelector);

  const [firstName, setFirstName] = useState(accountInfo?.firstName);
  const [lastName, setLastName] = useState(accountInfo?.lastName);
  const [username, setUsername] = useState(accountInfo?.username);
  const [newUsername, setNewUsername] = useState("");
  const [oldEmail, setOldEmail] = useState(accountInfo?.email);
  const [newEmail, setNewEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [gender, setGender] = useState(accountInfo?.gender);
  const [checkEmailStatus, setCheckEmailStatus] = useState<boolean>(false);
  const [checkEmailResponse, setCheckEmailResponse] = useState<string>("");
  const [checkUsernameStatus, setCheckUsernameStatus] =
    useState<boolean>(false);
  const [checkUsernameResponse, setCheckUsernameResponse] =
    useState<string>("");
  const [isPasswordLongEnough, setIsPasswordLongEnough] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumberCase, setHasNumberCase] = useState(false);
  const [imageFile, setImageFile] = useState<File | null>();
  const [uploadFileList, setUploadFileList] = useState<UploadFile[]>([]);
  const [oldMailPassword, setOldMailPassword] = useState("");

  const debouncedCheckEmail = useRef(
    debounce((email) => {
      dispatch(
        checkEmail({
          email,
          onSuccess: () => {},
          onError: () => {},
        })
      );
    }, 1000)
  );

  const debouncedCheckUsername = useRef(
    debounce((username) => {
      dispatch(
        checkUsername({
          username,
          onSuccess: () => {},
          onError: () => {},
        })
      );
    }, 1000)
  );

  useEffect(() => {
    if (accountInfo?.image) {
      const fileList: UploadFile[] = [
        {
          uid: "-1",
          name: "Existing Image",
          status: "done",
          url: `${endpoints.baseUrl}/${accountInfo?.image}`,
        },
      ];
      setUploadFileList(fileList);
    } else {
      setUploadFileList([]);
    }
  }, [accountInfo]);

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG files!");
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
      return Upload.LIST_IGNORE;
    }

    setImageFile(file);
    const fileList: UploadFile[] = [
      {
        uid: file.uid,
        name: file.name,
        status: "done",
        url: URL.createObjectURL(file),
      },
    ];
    setUploadFileList([...uploadFileList, fileList[0]]);

    return false;
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url || "";
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as Blob);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onRemove = (file: UploadFile) => {
    const filteredList = uploadFileList.filter((item) => item.uid !== file.uid);
    setUploadFileList(filteredList);
    return true;
  };

  useEffect(() => {
    debouncedCheckEmail.current = debounce((email: string) => {
      dispatch(
        checkEmail({
          email,
          onSuccess: (message) => {
            setCheckEmailStatus(true);
            setCheckEmailResponse(message);
          },
          onError: (message) => {
            setCheckEmailStatus(false);
            setCheckEmailResponse(message);
          },
        })
      );
    }, 1000);
  }, [dispatch]);

  useEffect(() => {
    debouncedCheckUsername.current = debounce((username: string) => {
      dispatch(
        checkUsername({
          username,
          onSuccess: (message) => {
            setCheckUsernameStatus(true);
            setCheckUsernameResponse(message);
          },
          onError: (message) => {
            setCheckUsernameStatus(false);
            setCheckUsernameResponse(message);
          },
        })
      );
    }, 1000);
  }, [dispatch]);

  const handleCheckEmail = (email: string) => {
    debouncedCheckEmail.current(email);
  };

  const handleCheckUsername = (username: string) => {
    debouncedCheckUsername.current(username);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\s/g, "");
    setNewEmail(inputValue);
    handleCheckEmail(inputValue);
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\s/g, "");
    setNewUsername(inputValue);
    handleCheckUsername(inputValue);
  };

  const onPasswordChange = (e: any) => {
    const value = e.target.value;
    setPassword(value);
    setIsPasswordLongEnough(value.length >= 6);
    setHasUpperCase(/[A-Z]/.test(value));
    setHasNumberCase(/\d/.test(value));
  };

  const passwordCriteria = (
    <div className={styles.password_criteria}>
      <div>
        {isPasswordLongEnough ? (
          <CheckCircleOutlined
            style={{ color: "#00a35c", paddingRight: "10px" }}
          />
        ) : (
          <CloseCircleOutlined
            style={{ color: "#f45d5a", paddingRight: "10px" }}
          />
        )}
        <span>At least 6 characters</span>
      </div>
      <div>
        {hasUpperCase ? (
          <CheckCircleOutlined
            style={{ color: "#00a35c", paddingRight: "10px" }}
          />
        ) : (
          <CloseCircleOutlined
            style={{ color: "#f45d5a", paddingRight: "10px" }}
          />
        )}
        <span>Contains uppercase letter</span>
      </div>
      <div>
        {hasNumberCase ? (
          <CheckCircleOutlined
            style={{ color: "#00a35c", paddingRight: "10px" }}
          />
        ) : (
          <CloseCircleOutlined
            style={{ color: "#f45d5a", paddingRight: "10px" }}
          />
        )}
        <span>Contains number</span>
      </div>
    </div>
  );

  const handleUpdateProfile = () => {
    dispatch(
      updateProfile({
        firstName: firstName ? firstName : "",
        lastName: lastName ? lastName : "",
        username: newUsername,
        email: newEmail,
        gender: gender ? gender : "",
        image: imageFile as File,
        onSuccess() {
          dispatch(getUserInfo());
          onClose();
        },
      })
    );
  };

  const handleUpdatePassword = () => {
    dispatch(
      updatePassword({
        oldPassword,
        newPassword: password,
        onSuccess(message) {
          toast.success(message);
          setOldPassword("");
          setPassword("");
          setConfirmPassword("");
          onClose();
        },
      })
    );
  };

  const handleChangeMail = () => {
    dispatch(
      changeMail({
        password: oldMailPassword,
        email: newEmail,
        onSuccess() {
          toast.success("Send verification email to your new email");
          setOldPassword("");
          setNewEmail("");
          onClose();
        },
      })
    );
  };

  return (
    <Modal
      title="Update Profile"
      open={visible}
      onCancel={onClose}
      footer={null}
      width={1200}
    >
      <div className={styles.main}>
        <div className={styles.main_content}>
          <div className={styles.main_content_title}>Update Profile</div>
          <div className={styles.main_content_form}>
            <div className={styles.main_content_form_title}>First Name</div>
            <Input
              className={styles.main_content_form_input}
              style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              allowClear
            />
            <div className={styles.main_content_form_title}>Last Name</div>
            <Input
              className={styles.main_content_form_input}
              style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              allowClear
            />
            <div className={styles.main_content_form_title}>Username</div>
            <Input
              className={styles.main_content_form_input}
              style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
              value={username}
              disabled
            />
            <Input
              className={styles.main_content_form_input}
              style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
              value={newUsername}
              onChange={handleUsernameChange}
              allowClear
              placeholder="New Username"
              suffix={
                newUsername.length > 0 ? (
                  checkUsernameStatus ? (
                    <Tooltip title={checkUsernameResponse} placement="right">
                      <CheckCircleOutlined style={{ color: "#00a35c" }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title={checkUsernameResponse} placement="right">
                      <CloseCircleOutlined style={{ color: "#f45d5a" }} />
                    </Tooltip>
                  )
                ) : null
              }
            />
            <div className={styles.main_content_form_title}>Gender</div>
            <Select
              defaultValue={gender}
              options={[
                { value: "male", label: "male" },
                { value: "female", label: "female" },
                { value: "other", label: "other" },
              ]}
              onChange={(value) => setGender(value)}
            />
            <div className={styles.main_content_form_title}>Profile Image</div>
            <ImgCrop rotationSlider>
              <Upload
                listType="picture-card"
                fileList={uploadFileList}
                onPreview={onPreview}
                beforeUpload={beforeUpload}
                onRemove={onRemove}
              >
                {uploadFileList.length < 2 && "+ Upload"}
              </Upload>
            </ImgCrop>

            <Button
              type="primary"
              className={styles.main_content_form_button}
              onClick={handleUpdateProfile}
              loading={updateProfileLoading}
            >
              Update Profile
            </Button>
          </div>
        </div>
        <div className={styles.main_content}>
          <div className={styles.main_content_title}>Update Email</div>
          <div className={styles.main_content_form}>
            <div className={styles.main_content_form_title}>
              Current Password
            </div>
            <Input.Password
              className={styles.main_content_form_input}
              style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
              value={oldMailPassword}
              onChange={(e) => setOldMailPassword(e.target.value)}
              allowClear
              placeholder="Current Password"
            />
            <div className={styles.main_content_form_title}>Current Email</div>
            <Input
              className={styles.main_content_form_input}
              style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
              value={oldEmail}
              disabled
            />
            <div className={styles.main_content_form_title}>New Email</div>
            <Input
              className={styles.main_content_form_input}
              style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
              value={newEmail}
              onChange={handleEmailChange}
              allowClear
              placeholder="New Email"
              suffix={
                newEmail.length > 0 ? (
                  checkEmailStatus ? (
                    <Tooltip title={checkEmailResponse} placement="right">
                      <CheckCircleOutlined style={{ color: "#00a35c" }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title={checkEmailResponse} placement="right">
                      <CloseCircleOutlined style={{ color: "#f45d5a" }} />
                    </Tooltip>
                  )
                ) : null
              }
            />
            <Button
              type="primary"
              className={styles.main_content_form_button}
              disabled={!checkEmailStatus}
              loading={updateMailLoading}
              onClick={handleChangeMail}
            >
              Update Email
            </Button>
          </div>
        </div>
        <div className={styles.main_content}>
          <div className={styles.main_content_title}>Update Password</div>
          <div className={styles.main_content_form}>
            <div className={styles.main_content_form_title}>
              Current Password
            </div>
            <Input.Password
              className={styles.main_content_form_input}
              style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              allowClear
              placeholder="Current Password"
            />
            <div className={styles.main_content_form_title}>New Password</div>
            <Popover
              content={passwordCriteria}
              placement="left"
              trigger="focus"
            >
              <Input.Password
                className={styles.main_content_form_input}
                style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
                value={password}
                onChange={onPasswordChange}
                allowClear
                placeholder="New Password"
              />
            </Popover>
            <div className={styles.main_content_form_title}>
              Confirm Password
            </div>
            <Input.Password
              className={styles.main_content_form_input}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              allowClear
              placeholder="Confirm Password"
              style={
                confirmPassword.length > 0
                  ? password === confirmPassword
                    ? {
                        backgroundColor: "transparent",
                        border: "1px solid  #e0e0e0",
                        boxShadow: "none",
                      }
                    : {
                        backgroundColor: "#f45d5a30",
                        border: "1px solid  #e0e0e0",
                        boxShadow: "none",
                      }
                  : {
                      backgroundColor: "transparent",
                      border: "1px solid #e0e0e0",
                      boxShadow: "none",
                    }
              }
            />
            <Button
              type="primary"
              className={styles.main_content_form_button}
              onClick={handleUpdatePassword}
              disabled={password !== confirmPassword || !isPasswordLongEnough}
              loading={changePasswordLoading}
            >
              Update Password
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateProfile;
