import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { NotificationActionTypes } from "./types";
import axios from "axios";
import endpoints from "../../services/endpoints";
import authService, { http } from "../../services/authService";
import { RootState } from "../index";

interface GetNotificationsPayload {
  onSuccess: (message: string) => void;
  onError: (message: string) => void;
}

export const getNotifications = createAsyncThunk(
  NotificationActionTypes.GET_NOTIFICATIONS,
  async (payload: GetNotificationsPayload, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `${endpoints.baseUrl}${endpoints.getNotifications}`
      );
      payload.onSuccess(response.data.message);
      return response.data;
    } catch (error: any) {
      payload.onError(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

interface socketAddNotificationPayload {
  _id: string;
  user: string;
  team: {
    _id: string;
    name: string;
    description: string;
  };
  content: string;
  type: string;
  url: string;
  isRead: boolean;
  createdAt: string;
}

export const socketAddNotification = createAsyncThunk(
  NotificationActionTypes.SOCKET_ADD_NOTIFICATION,
  async (payload: socketAddNotificationPayload, { rejectWithValue }) => {
    try {
      return payload;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateNotification = createAction<string[]>(
  NotificationActionTypes.UPDATE_NOTIFICATION
);

interface DeleteNotificationPayload {
  notificationId: string;
  onSuccess: (message: string) => void;
  onError: (message: string) => void;
}

export const deleteNotification = createAsyncThunk(
  NotificationActionTypes.DELETE_NOTIFICATION,
  async (payload: DeleteNotificationPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.deleteNotification}`,
        { notificationId: payload.notificationId }
      );
      payload.onSuccess(response.data.message);
      return response.data;
    } catch (error: any) {
      payload.onError(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

interface MarkReadPayload {
  notificationId: string;
  onSuccess: (message: string) => void;
  onError: (message: string) => void;
}

export const markRead = createAsyncThunk(
  NotificationActionTypes.MARK_READ,
  async (payload: MarkReadPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.readNotification}`,
        { notificationId: payload.notificationId }
      );
      payload.onSuccess(response.data.message);
      return response.data;
    } catch (error: any) {
      payload.onError(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);
