import React, { useState } from "react";
import styles from "./index.module.scss";
import Logo from "../../components/Logo";
import { Button, Form, Input, Modal, Popover } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { accountSelector, forgotPassword, login } from "../../store/account";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const UserLogin = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { loginLoading, forgotPasswordLoading } = useSelector(accountSelector);

  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordLongEnough, setIsPasswordLongEnough] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumberCase, setHasNumberCase] = useState(false);
  const [forgotPasswordModalVisible, setForgotPasswordModalVisible] =
    useState(false);
  const [email, setEmail] = useState("");

  const onPasswordChange = (e: any) => {
    const value = e.target.value;
    setPassword(value);
    setIsPasswordLongEnough(value.length >= 6);
    setHasUpperCase(/[A-Z]/.test(value));
    setHasNumberCase(/\d/.test(value));
  };

  const passwordCriteria = (
    <div className={styles.password_criteria}>
      <div>
        {isPasswordLongEnough ? (
          <CheckCircleOutlined
            style={{ color: "#00a35c", paddingRight: "10px" }}
          />
        ) : (
          <CloseCircleOutlined
            style={{ color: "#f45d5a", paddingRight: "10px" }}
          />
        )}
        <span>At least 6 characters</span>
      </div>
      <div>
        {hasUpperCase ? (
          <CheckCircleOutlined
            style={{ color: "#00a35c", paddingRight: "10px" }}
          />
        ) : (
          <CloseCircleOutlined
            style={{ color: "#f45d5a", paddingRight: "10px" }}
          />
        )}
        <span>Contains uppercase letter</span>
      </div>
      <div>
        {hasNumberCase ? (
          <CheckCircleOutlined
            style={{ color: "#00a35c", paddingRight: "10px" }}
          />
        ) : (
          <CloseCircleOutlined
            style={{ color: "#f45d5a", paddingRight: "10px" }}
          />
        )}
        <span>Contains number</span>
      </div>
    </div>
  );

  const onFinish = () => {
    dispatch(
      login({
        user,
        password,
        onSuccess: (message) => {
          setUser("");
          setPassword("");
          toast.success(message);
          navigate("/");
        },
      })
    );
  };
  const onForgotFinish = () => {
    dispatch(
      forgotPassword({
        email,
        onSuccess: (message) => {
          setEmail("");
          toast.success(message);
          setForgotPasswordModalVisible(false);
        },
      })
    );
  };
  return (
    <div className={styles.main}>
      <div className={styles.main_content}>
        <div className={styles.main_content_form}>
          <span className={styles.main_content_form_title}>Login</span>
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item label="Username or Email">
              <Input
                className={styles.main_content_form_input}
                style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
                placeholder="Username or Email"
                allowClear
                value={user}
                onChange={(e) => setUser(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Password">
              <Popover
                content={passwordCriteria}
                placement="left"
                trigger="focus"
              >
                <Input.Password
                  className={styles.main_content_form_input}
                  style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
                  placeholder="Password"
                  allowClear
                  value={password}
                  onChange={onPasswordChange}
                />
              </Popover>
            </Form.Item>
            <Form.Item>
              <Button
                className={styles.main_content_form_button}
                type="primary"
                htmlType="submit"
                loading={loginLoading}
              >
                Login
              </Button>
            </Form.Item>
            <span className={styles.main_content_form_footer}>
              <a
                onClick={() => {
                  setForgotPasswordModalVisible(true);
                }}
              >
                Forgot password?
              </a>
            </span>
            <span className={styles.main_content_form_footer}>
              <a href="/register">Don't have an account?</a>
            </span>
          </Form>
        </div>
      </div>
      <div className={styles.main_footer}>
        <Logo size="large" />
      </div>
      {forgotPasswordModalVisible && (
        <Modal
          title="Forgot Password"
          open={forgotPasswordModalVisible}
          onOk={() => setForgotPasswordModalVisible(false)}
          onCancel={() => setForgotPasswordModalVisible(false)}
          footer={null}
        >
          <Form layout="vertical" onFinish={onForgotFinish}>
            <Form.Item label="Email">
              <Input
                className={styles.main_content_form_input}
                style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
                placeholder="Email"
                allowClear
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button
                className={styles.main_content_form_button}
                type="primary"
                htmlType="submit"
                loading={forgotPasswordLoading}
              >
                Send Email
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default UserLogin;
