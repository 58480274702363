import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Tooltip,
  Upload,
  message,
} from "antd";
import ImgCrop from "antd-img-crop";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store";
import { Team } from "../../../../store/team/types";
import { updateTeam, getTeams } from "../../../../store/team";
import { RcFile } from "antd/es/upload";
import endpoints from "../../../../services/endpoints";
import { accountSelector } from "../../../../store/account";
import { toast } from "react-toastify";

interface EditTeamModalProps {
  visible: boolean;
  onCancel: () => void;
  selectedTeam: Team;
}

const EditTeamModal = ({
  visible,
  onCancel,
  selectedTeam,
}: EditTeamModalProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { accountInfo } = useSelector(accountSelector);
  const [newTeamName, setNewTeamName] = useState(selectedTeam.name);
  const [newDescription, setNewDescription] = useState(
    selectedTeam.description
  );
  const [imageFile, setImageFile] = useState<File | null>();
  const [admins, setAdmins] = useState<string[]>(
    selectedTeam.admin.map((admin) => admin._id)
  );

  useEffect(() => {
    if (selectedTeam.image) {
      const fileList: UploadFile[] = [
        {
          uid: "-1",
          name: "Existing Image",
          status: "done",
          url: `${endpoints.baseUrl}/${selectedTeam.image}`,
        },
      ];
      setUploadFileList(fileList);
    } else {
      setUploadFileList([]);
    }
  }, [selectedTeam]);

  const [uploadFileList, setUploadFileList] = useState<UploadFile[]>([]);

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG files!");
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
      return Upload.LIST_IGNORE;
    }

    setImageFile(file);
    const fileList: UploadFile[] = [
      {
        uid: file.uid,
        name: file.name,
        status: "done",
        url: URL.createObjectURL(file),
      },
    ];
    setUploadFileList([...uploadFileList, fileList[0]]);

    return false;
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url || "";
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as Blob);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onRemove = (file: UploadFile) => {
    const filteredList = uploadFileList.filter((item) => item.uid !== file.uid);
    setUploadFileList(filteredList);
    return true;
  };

  const onFinish = () => {
    if (admins.length === 0) {
      toast.error("Please select at least one admin");
      return;
    }
    dispatch(
      updateTeam({
        teamId: selectedTeam._id,
        name: newTeamName,
        description: newDescription,
        image: imageFile as File,
        admins,
        onSuccess: () => {
          onCancel();
          dispatch(getTeams());
        },
      })
    );
  };

  const isUserAdmin = selectedTeam.admin.some(
    (admin) => admin._id === accountInfo?._id
  );

  return (
    <Modal title="Edit Team" open={visible} onCancel={onCancel} footer={null}>
      <Form layout="vertical" onFinish={onFinish} className={styles.main}>
        <Form.Item label="Team Name">
          <Input
            className={styles.main_input}
            style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
            value={newTeamName}
            onChange={(e) => setNewTeamName(e.target.value)}
            allowClear
          />
        </Form.Item>
        <Form.Item label="Description">
          <Input
            className={styles.main_input}
            style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
            allowClear
          />
        </Form.Item>
        {isUserAdmin && (
          <Form.Item label="Admin">
            <Select
              mode="multiple"
              style={{ width: "100%", marginBottom: "10px" }}
              placeholder="Add Admin"
              value={admins}
              onChange={(value) => setAdmins(value)}
              options={selectedTeam.members.map((member) => ({
                label: member.username,
                value: member._id,
              }))}
            />
            {admins.length === 0 && (
              <span
                style={{
                  color: "#f35d5a",
                }}
              >
                Please select at least one admin
              </span>
            )}
          </Form.Item>
        )}
        <Form.Item>
          <ImgCrop rotationSlider>
            <Upload
              listType="picture-card"
              fileList={uploadFileList}
              onPreview={onPreview}
              beforeUpload={beforeUpload}
              onRemove={onRemove}
            >
              {uploadFileList.length < 2 && "+ Upload"}
            </Upload>
          </ImgCrop>
        </Form.Item>
        <Tooltip
          title={admins.length === 0 ? "Please select at least one admin" : ""}
          placement="topRight"
        >
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.main_button}
            >
              Update
            </Button>
          </Form.Item>
        </Tooltip>
      </Form>
    </Modal>
  );
};

export default EditTeamModal;
