// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditTeamModal_main__oL4ev {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.EditTeamModal_main_input__IldHK {
  display: flex;
  align-items: center;
  justify-self: center;
  box-sizing: border-box;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  height: 3rem;
}
.EditTeamModal_main_input__IldHK:hover {
  border-color: #1f1f1f;
}
.EditTeamModal_main_input__IldHK:focus {
  border-color: #1f1f1f !important;
}
.EditTeamModal_main_button__qvj57 {
  display: flex;
  align-items: center;
  justify-self: center;
  box-sizing: border-box;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  height: 3rem;
  background-color: #f35d5a;
  font-size: 1rem;
}
.EditTeamModal_main_button__qvj57:hover {
  background-color: rgba(243, 92, 90, 0.8509803922) !important;
}
.EditTeamModal_main__oL4ev .ant-select-selector {
  display: flex;
  align-items: center;
  justify-self: center;
  box-sizing: border-box;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  height: 3rem;
}
.EditTeamModal_main__oL4ev .ant-select-selector:hover {
  border-color: #e0e0e0 !important;
  box-shadow: none !important;
}
.EditTeamModal_main__oL4ev .ant-select-selector:focus {
  border-color: #e0e0e0 !important;
  box-shadow: none !important;
}`, "",{"version":3,"sources":["webpack://./src/components/TeamList/Components/EditTeamModal/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,sBAAA;EACA,WAAA;EACA,qBAAA;EACA,wCAAA;EACA,YAAA;AAEJ;AADI;EACE,qBAAA;AAGN;AADI;EACE,gCAAA;AAGN;AAAE;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,sBAAA;EACA,WAAA;EACA,qBAAA;EACA,wCAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;AAEJ;AADI;EACE,4DAAA;AAGN;AAAE;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,sBAAA;EACA,WAAA;EACA,qBAAA;EACA,wCAAA;EACA,YAAA;AAEJ;AADI;EACE,gCAAA;EACA,2BAAA;AAGN;AADI;EACE,gCAAA;EACA,2BAAA;AAGN","sourcesContent":[".main {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  &_input {\n    display: flex;\n    align-items: center;\n    justify-self: center;\n    box-sizing: border-box;\n    width: 100%;\n    border-radius: 0.5rem;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);\n    height: 3rem;\n    &:hover {\n      border-color: #1f1f1f;\n    }\n    &:focus {\n      border-color: #1f1f1f !important;\n    }\n  }\n  &_button {\n    display: flex;\n    align-items: center;\n    justify-self: center;\n    box-sizing: border-box;\n    width: 100%;\n    border-radius: 0.5rem;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);\n    height: 3rem;\n    background-color: #f35d5a;\n    font-size: 1rem;\n    &:hover {\n      background-color: #f35c5ad9 !important;\n    }\n  }\n  :global(.ant-select-selector) {\n    display: flex;\n    align-items: center;\n    justify-self: center;\n    box-sizing: border-box;\n    width: 100%;\n    border-radius: 0.5rem;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);\n    height: 3rem;\n    &:hover {\n      border-color: #e0e0e0 !important;\n      box-shadow: none !important;\n    }\n    &:focus {\n      border-color: #e0e0e0 !important;\n      box-shadow: none !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `EditTeamModal_main__oL4ev`,
	"main_input": `EditTeamModal_main_input__IldHK`,
	"main_button": `EditTeamModal_main_button__qvj57`
};
export default ___CSS_LOADER_EXPORT___;
