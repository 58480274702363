import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RetroActionTypes } from "./types";
import axios from "axios";
import endpoints from "../../services/endpoints";
import authService, { http } from "../../services/authService";
import { RootState } from "../index";

export const getRetroTemplates = createAsyncThunk(
  RetroActionTypes.GET_RETRO_TEMPLATES,
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `${endpoints.baseUrl}${endpoints.getRetroTemplates}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface CreateRetroTemplatePayload {
  templateName: string;
  columns: { name: string; description: string }[];
  purpose: string;
  onSucces: () => void;
}

export const createRetroTemplate = createAsyncThunk(
  RetroActionTypes.CREATE_RETRO_TEMPLATE,
  async (payload: CreateRetroTemplatePayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.createRetroTemplate}`,
        payload
      );
      payload.onSucces();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface DeleteRetroTemplatePayload {
  retroTemplateId?: string;
  onSucces: () => void;
}

export const deleteRetroTemplate = createAsyncThunk(
  RetroActionTypes.DELETE_RETRO_TEMPLATE,
  async (payload: DeleteRetroTemplatePayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.deleteRetro}`,
        payload
      );
      payload.onSucces();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface CreateRetroPayload {
  templateId: string;
  retroPrivate: boolean;
  teamId?: string;
  retroName: string;
  status: string;
  finishedAt: string;
  onShow: boolean;
  onSucces: (data: string) => void;
}

export const createRetro = createAsyncThunk(
  RetroActionTypes.CREATE_RETRO,
  async (payload: CreateRetroPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.createRetro}`,
        payload
      );
      payload.onSucces(response.data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface IsRetroPayload {
  retroId: string;
  onSuccess: (retroId: string) => void;
  onError: () => void;
}

export const isRetro = createAsyncThunk(
  RetroActionTypes.IS_RETRO,
  async (payload: IsRetroPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.isRetro}`,
        payload
      );
      payload.onSuccess(payload.retroId);
      return response.data;
    } catch (error: any) {
      payload.onError();
      return rejectWithValue(error.response.data);
    }
  }
);

interface GetRetroPayload {
  retroId: string;
  onSuccess: () => void;
  onError: () => void;
}

export const getRetro = createAsyncThunk(
  RetroActionTypes.GET_RETRO,
  async (payload: GetRetroPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.getRetro}`,
        payload
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      payload.onError();
      return rejectWithValue(error.response.data);
    }
  }
);

interface AddColumnItemPayload {
  retroId: string;
  columnId: string;
  text: string;
  onSuccess: () => void;
}

export const addColumnItem = createAsyncThunk(
  RetroActionTypes.ADD_COLUMN_ITEM,
  async (payload: AddColumnItemPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.addColumnItem}`,
        payload
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface DeleteColumnItemPayload {
  retroId: string;
  columnId: string;
  itemId: string;
  onSuccess: () => void;
}

export const deleteColumnItem = createAsyncThunk(
  RetroActionTypes.DELETE_COLUMN_ITEM,
  async (payload: DeleteColumnItemPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.deleteColumnItem}`,
        payload
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const socketUpdateRetro = createAction(
  RetroActionTypes.SOCKET_UPDATE_RETRO,
  (retro: any) => {
    return { payload: retro };
  }
);

interface UpdateColumnItemPayload {
  retroId: string;
  columnId: string;
  itemId: string;
  text: string;
  onSuccess: () => void;
}

export const updateColumnItem = createAsyncThunk(
  RetroActionTypes.UPDATE_COLUMN_ITEM,
  async (payload: UpdateColumnItemPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.updateColumnItem}`,
        payload
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface AddCommentItemPayload {
  retroId: string;
  columnId: string;
  itemId: string;
  text: string;
  onSuccess: () => void;
}

export const addCommentItem = createAsyncThunk(
  RetroActionTypes.ADD_COMMENT,
  async (payload: AddCommentItemPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.addCommentItem}`,
        payload
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface UpdateCommentItemPayload {
  retroId: string;
  columnId: string;
  itemId: string;
  commentId: string;
  text: string;
  onSuccess: () => void;
}

export const updateCommentItem = createAsyncThunk(
  RetroActionTypes.UPDATE_COMMENT,
  async (payload: UpdateCommentItemPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.updateCommentItem}`,
        payload
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface DeleteCommentItemPayload {
  retroId: string;
  columnId: string;
  itemId: string;
  commentId: string;
  onSuccess: () => void;
}

export const deleteCommentItem = createAsyncThunk(
  RetroActionTypes.DELETE_COMMENT,
  async (payload: DeleteCommentItemPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.deleteCommentItem}`,
        payload
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface AddLikeItemPayload {
  retroId: string;
  columnId: string;
  itemId: string;
  onSuccess: () => void;
}

export const addLikeItem = createAsyncThunk(
  RetroActionTypes.ADD_LIKE,
  async (payload: AddLikeItemPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.addLikeItem}`,
        payload
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface DeleteLikeItemPayload {
  retroId: string;
  columnId: string;
  itemId: string;
  onSuccess: () => void;
}

export const deleteLikeItem = createAsyncThunk(
  RetroActionTypes.DELETE_LIKE,
  async (payload: DeleteLikeItemPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.deleteLikeItem}`,
        payload
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface AddColumnPayload {
  retroId: string;
  columnName: string;
  columnDescription: string;
  onSuccess: () => void;
}

export const addColumn = createAsyncThunk(
  RetroActionTypes.ADD_COLUMN,
  async (payload: AddColumnPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.addColumn}`,
        payload
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface DeleteColumnPayload {
  retroId: string;
  columnId: string;
  onSuccess: () => void;
}

export const deleteColumn = createAsyncThunk(
  RetroActionTypes.DELETE_COLUMN,
  async (payload: DeleteColumnPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.deleteColumn}`,
        payload
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface AddQuestionPayload {
  retroId: string;
  question: string;
  choices?: string[];
  onSuccess: () => void;
  onError: (message: string) => void;
}

export const addQuestion = createAsyncThunk(
  RetroActionTypes.ADD_QUESTION,
  async (payload: AddQuestionPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.addQuestion}`,
        payload
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      payload.onError(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

interface AddChoicePayload {
  retroId: string;
  questionId: string;
  choiceId: string;
  onSuccess: () => void;
}

export const addChoice = createAsyncThunk(
  RetroActionTypes.ADD_CHOICE,
  async (payload: AddChoicePayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.addAnswer}`,
        payload
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface DeleteQuestionPayload {
  retroId: string;
  questionId: string;
  onSuccess: () => void;
}

export const deleteQuestion = createAsyncThunk(
  RetroActionTypes.DELETE_QUESTION,
  async (payload: DeleteQuestionPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.deleteQuestion}`,
        payload
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface ChangeRetroStatusPayload {
  retroId: string;
  status: string;
  onSuccess: () => void;
}

export const changeRetroStatus = createAsyncThunk(
  RetroActionTypes.CHANGE_RETRO_STATUS,
  async (payload: ChangeRetroStatusPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.changeRetroStatus}`,
        payload
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface ChangeRetroOnShowPayload {
  retroId: string;
  onShow: boolean;
  onSuccess: () => void;
}

export const changeRetroOnShow = createAsyncThunk(
  RetroActionTypes.CHANGE_RETRO_ONSHOW,
  async (payload: ChangeRetroOnShowPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.changeRetroOnShow}`,
        payload
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface ChangeRetroFinishPayload {
  retroId: string;
  finishedAt: string;
  onSuccess: () => void;
}

export const changeRetroFinish = createAsyncThunk(
  RetroActionTypes.CHANGE_RETRO_FINISH,
  async (payload: ChangeRetroFinishPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.changeRetroFinishedAt}`,
        payload
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getRetros = createAsyncThunk(
  RetroActionTypes.GET_RETROS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `${endpoints.baseUrl}${endpoints.getRetros}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface DeleteMyRetroPayload {
  retroId: string;
  onSuccess: () => void;
}

export const deleteMyRetro = createAsyncThunk(
  RetroActionTypes.DELETE_MY_RETRO,
  async (payload: DeleteMyRetroPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.deleteMyRetro}`,
        payload
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
