// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateTeam_main__gceyf {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
  padding: 1rem 2rem;
}
.CreateTeam_main_form__8HLTA {
  display: flex;
  width: 50%;
}
.CreateTeam_main_form_input__xl3vv {
  display: flex;
  align-items: center;
  justify-self: center;
  box-sizing: border-box;
  width: 100%;
  min-width: 15rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  height: 3rem;
}
.CreateTeam_main_form_input__xl3vv:hover {
  border-color: #1f1f1f;
}
.CreateTeam_main_form_input__xl3vv:focus {
  border-color: #1f1f1f !important;
}
.CreateTeam_main_form_button__rtsGK {
  display: flex;
  align-items: center;
  justify-self: center;
  box-sizing: border-box;
  width: 100%;
  min-width: 15rem;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  height: 3rem;
  background-color: #f35d5a;
  font-size: 1rem;
}
.CreateTeam_main_form_button__rtsGK:hover {
  background-color: rgba(243, 92, 90, 0.8509803922) !important;
}
.CreateTeam_main_image__fYrwm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
}
.CreateTeam_main_image__fYrwm img {
  width: 80%;
  height: 80%;
  object-fit: cover;
  border-radius: 0.5rem;
  opacity: 0.8;
}
@media (max-width: 768px) {
  .CreateTeam_main_image__fYrwm {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/CreateTeam/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,SAAA;EACA,kBAAA;AACF;AAAE;EACE,aAAA;EACA,UAAA;AAEJ;AADI;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,sBAAA;EACA,WAAA;EACA,gBAAA;EACA,qBAAA;EACA,wCAAA;EACA,YAAA;AAGN;AAFM;EACE,qBAAA;AAIR;AAFM;EACE,gCAAA;AAIR;AADI;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,sBAAA;EACA,WAAA;EACA,gBAAA;EACA,qBAAA;EACA,wCAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;AAGN;AAFM;EACE,4DAAA;AAIR;AAAE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;EACA,YAAA;AAEJ;AADI;EACE,UAAA;EACA,WAAA;EACA,iBAAA;EACA,qBAAA;EACA,YAAA;AAGN;AADI;EAbF;IAcI,aAAA;EAIJ;AACF","sourcesContent":[".main {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  gap: 1rem;\n  padding: 1rem 2rem;\n  &_form {\n    display: flex;\n    width: 50%;\n    &_input {\n      display: flex;\n      align-items: center;\n      justify-self: center;\n      box-sizing: border-box;\n      width: 100%;\n      min-width: 15rem;\n      border-radius: 0.5rem;\n      box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);\n      height: 3rem;\n      &:hover {\n        border-color: #1f1f1f;\n      }\n      &:focus {\n        border-color: #1f1f1f !important;\n      }\n    }\n    &_button {\n      display: flex;\n      align-items: center;\n      justify-self: center;\n      box-sizing: border-box;\n      width: 100%;\n      min-width: 15rem;\n      border-radius: 0.5rem;\n      box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);\n      height: 3rem;\n      background-color: #f35d5a;\n      font-size: 1rem;\n      &:hover {\n        background-color: #f35c5ad9 !important;\n      }\n    }\n  }\n  &_image {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 50%;\n    height: 100%;\n    img {\n      width: 80%;\n      height: 80%;\n      object-fit: cover;\n      border-radius: 0.5rem;\n      opacity: 0.8;\n    }\n    @media (max-width: 768px) {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `CreateTeam_main__gceyf`,
	"main_form": `CreateTeam_main_form__8HLTA`,
	"main_form_input": `CreateTeam_main_form_input__xl3vv`,
	"main_form_button": `CreateTeam_main_form_button__rtsGK`,
	"main_image": `CreateTeam_main_image__fYrwm`
};
export default ___CSS_LOADER_EXPORT___;
