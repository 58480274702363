import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import Header from "../Header";
import Footer from "../Footer";
import Sidebar from "../Sidebar";
import Content from "../Content/Content";
import { useDispatch, useSelector } from "react-redux";
import { accountSelector, socketAddFriendRequest } from "../../store/account";
import Pusher from "pusher-js";
import endpoints from "../../services/endpoints";
import { AppDispatch } from "../../store";
import { socketAddNotification } from "../../store/notification";
import { toast } from "react-toastify";
import { getInvitations, getTeams } from "../../store/team";
import { setActiveChatUserIds, uiSelector } from "../../store/ui";
import Chat from "../Chat";
import { socketAddMessage } from "../../store/conversations";
import Welcome from "../Welcome";
import { socketUpdateRetro } from "../../store/retro";
import { socketUpdateScrumPoker } from "../../store/scrumPoker";

interface MainContentProps {
  type?: string;
}

const MainContent = ({ type }: MainContentProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { loggedIn, accountInfo } = useSelector(accountSelector);
  const { activePage } = useSelector(uiSelector);

  useEffect(() => {
    if (accountInfo) {
      const pusherKey = process.env.REACT_APP_PUSHER_KEY as string;

      const pusherCluster = process.env.REACT_APP_PUSHER_CLUSTER as string;

      const pusher = new Pusher(pusherKey, {
        cluster: pusherCluster,
        forceTLS: true,
      });

      const currentPage = localStorage.getItem("menuChildrenKey");
      if (currentPage === "Retro Dashboard") {
        const retroId = localStorage.getItem("retroId");
        const retroChannel = pusher.subscribe(`retro-${retroId}`);
        retroChannel.bind("retroActions", function (data: any) {
          dispatch(socketUpdateRetro(data));
        });

        retroChannel.bind("retroNotification", function (data: any) {
          toast.info(data.message);
        } as any);
      }
      if (currentPage === "Scrum Poker Dashboard") {
        const scrumRoomId = localStorage.getItem("scrumPokerRoomId");
        const scrumPokerChannel = pusher.subscribe(`scrumPoker-${scrumRoomId}`);
        scrumPokerChannel.bind("scrumPokerAction", function (data: any) {
          dispatch(socketUpdateScrumPoker(data));
        });
      }

      const notificationsChannel = pusher.subscribe(
        `notifications-${accountInfo._id}`
      );

      notificationsChannel.bind("newNotification", function (data: any) {
        dispatch(socketAddNotification(data));
        if (data.type === "teamRequest" && activePage === "Team Invitations") {
          dispatch(getInvitations());
        }
        if (data.type === "teamPromotion" && activePage === "My Teams") {
          dispatch(getTeams());
        }
        if (data.type === "retroCreated" && activePage === "My Retros") {
          console.log("new retro created");
        }

        toast.info(data.content);
      });

      const friendRequestChannel = pusher.subscribe(
        `friendRequests-${accountInfo._id}`
      );
      friendRequestChannel.bind("newFriendRequest", function (data: any) {
        dispatch(socketAddFriendRequest(data));
        toast.info(`New friend request from ${data.username}`);
      });

      const messageChannel = pusher.subscribe(`messages-${accountInfo._id}`);
      messageChannel.bind("newMessage", function (data: any) {
        dispatch(
          socketAddMessage({
            _id: data._id,
            senderId: data.senderId,
            receiverId: data.receiverId,
            message: data.message,
            chatId: data.chatId,
            createdAt: data.createdAt,
            isRead: data.isRead,
          })
        );
      });

      return () => {
        notificationsChannel.unbind_all();
        notificationsChannel.unsubscribe();
        friendRequestChannel.unbind_all();
        friendRequestChannel.unsubscribe();
        pusher.disconnect();
      };
    }
  }, [accountInfo, activePage]);

  useEffect(() => {
    const activeUserIds = localStorage.getItem("chatIds");
    if (activeUserIds) {
      dispatch(setActiveChatUserIds(JSON.parse(activeUserIds)));
    }
  }, []);

  return (
    <div className={styles.main}>
      <Header />
      <div className={styles.main_content}>
        {loggedIn ? (
          <>
            <Sidebar />
            <Content type={type} />
          </>
        ) : (
          <Welcome />
        )}
      </div>
      <div className={styles.main_chatArea}>
        <Chat />
      </div>

      <Footer />
    </div>
  );
};

export default MainContent;
