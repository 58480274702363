export enum ActionTypes {
  REGISTER = "REGISTER",
  CHECK_USERNAME = "CHECK_USERNAME",
  CHECK_EMAIL = "CHECK_EMAIL",
  LOGIN = "LOGIN",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  GET_USER_INFO = "GET_USER_INFO",
  LOGOUT = "LOGOUT",
  ADD_FRIEND = "ADD_FRIEND",
  DELETE_FRIEND_REQUEST = "DELETE_FRIEND_REQUEST",
  SOCKET_ADD_FRIEND_REQUEST = "SOCKET_ADD_FRIEND_REQUEST",
  ACCEPT_FRIEND_REQUEST = "ACCEPT_FRIEND_REQUEST",
  REJECT_FRIEND_REQUEST = "REJECT_FRIEND_REQUEST",
  DELETE_FRIEND = "DELETE_FRIEND",
  GET_FRIENDS = "GET_FRIENDS",
  RESEND_VERIFICATION_EMAIL = "RESEND_VERIFICATION_EMAIL",
  UPDATE_PROFILE = "UPDATE_PROFILE",
  UPDATE_PASSWORD = "UPDATE_PASSWORD",
  UPDATE_MAIL = "UPDATE_MAIL",
  RESET_PASSWORD = "RESET_PASSWORD",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  CHANGE_VERIFY_EMAIL = "CHANGE_VERIFY_EMAIL",
  CHANGE_NICKNAME = "CHANGE_NICKNAME",
  INVITE_FRIEND = "INVITE_FRIEND",
}

export interface AccountState {
  registerLoading: boolean;
  loginLoading: boolean;
  forgotPasswordLoading: boolean;
  accountInfoLoading: boolean;
  friendLoading: boolean;
  loggedIn: boolean;
  accountInfo?: UserData;
  changePasswordLoading: boolean;
  updateProfileLoading: boolean;
  updateMailLoading: boolean;
  verifyEmailLoading: boolean;
}

export interface UserData {
  _id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  image: string;
  gender: string;
  friends: User[];
  sendFriendRequests: User[];
  receiveFriendRequests: User[];
  role: string;
  isVerified: boolean;
  accessToken: string;
  birthdate: string;
  nickname: string;
}

export interface User {
  _id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  image: string;
}
