import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../index";

export const selectAction = (state: RootState) => state.notification;

export const notificationSelector = createSelector(
  selectAction,
  (notification) => notification
);
