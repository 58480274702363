const endpoints = {
  baseUrl: "https://www.retrothink.io",
  fronendUrl: "https://www.retrothink.io",
  // baseUrl: "http://localhost:5023",
  // fronendUrl: "http://localhost:3000",
  register: "/api/users/register",
  checkUsername: "/api/users/check-username",
  checkEmail: "/api/users/check-email",
  login: "/api/users/login",
  forgotPassword: "/api/users/forgot-password",
  getUserInfo: "/api/users/profile",
  logout: "/api/users/logout",
  reSendVerificationEmail: "/api/users/resend-verification-email",
  updateProfile: "/api/users/update-profile",
  updatePassword: "/api/users/change-password",
  updateMail: "/api/users/change-email",
  resetPassword: "/api/users/reset-password",
  verifyEmail: "/api/users/verify-email",
  chandeVerifyEmail: "/api/users/verify-change-email",

  createTeam: "/api/teams/create-team",
  getTeams: "/api/teams/get-teams",
  isUser: "/api/teams/is-user",
  addMember: "/api/teams/add-user-to-team",
  getInvitations: "/api/teams/get-invitations-to-team",
  acceptInvitation: "/api/teams/accept-team-request",
  rejectInvitation: "/api/teams/reject-team-request",
  deleteTeam: "/api/teams/delete-team",
  removeMember: "/api/teams/remove-user-from-team",
  leaveTeam: "/api/teams/leave-team",
  removeRequest: "/api/teams/remove-user-from-requests",
  updateTeam: "/api/teams/update-team",
  searchTeams: "/api/teams/search-teams-for-user",
  searchInvitations: "/api/teams/search-teams-invitations-for-user",

  getNotifications: "/api/notification/get-notifications",
  readNotification: "/api/notification/mark-as-read",
  deleteNotification: "/api/notification/delete-notification",

  addFriend: "/api/friends/add-friend",
  acceptFriendRequest: "/api/friends/accept-friend-request",
  rejectFriendRequest: "/api/friends/reject-friend-request",
  deleteFriendRequest: "/api/friends/delete-friend-request",
  deleteFriend: "/api/friends/delete-friend",
  getFriendAndRequests: "/api/friends/get-friends-and-friend-requests",
  inviteFriend: "/api/friends/invite-friend",

  getConversations: "/api/messages/get-conversations",
  getMessages: "/api/messages/get-messages",
  sendMessage: "/api/messages/send-message",
  readConversation: "/api/messages/read-conversation",

  getRetroTemplates: "/api/retro/get-retro-templates",
  createRetroTemplate: "/api/retro/create-retro-template",
  deleteRetro: "/api/retro/delete-retro-template",
  createRetro: "/api/retro/create-retro",
  isRetro: "/api/retro/is-retro",
  getRetro: "/api/retro/get-retro",
  addColumnItem: "/api/retro/add-retro-item",
  deleteColumnItem: "/api/retro/delete-retro-item",
  updateColumnItem: "/api/retro/update-retro-item",
  addCommentItem: "/api/retro/add-retro-comment",
  updateCommentItem: "/api/retro/update-retro-comment",
  deleteCommentItem: "/api/retro/delete-retro-comment",
  addLikeItem: "/api/retro/add-like",
  deleteLikeItem: "/api/retro/add-unlike",
  addColumn: "/api/retro/add-retro-column",
  deleteColumn: "/api/retro/delete-retro-column",
  addQuestion: "/api/retro/add-question",
  addAnswer: "/api/retro/add-answer",
  deleteQuestion: "/api/retro/delete-question",
  changeRetroStatus: "/api/retro/change-retro-status",
  changeRetroOnShow: "/api/retro/change-retro-on-show",
  changeRetroFinishedAt: "/api/retro/change-retro-finished-at",
  getRetros: "/api/retro/get-retros",
  deleteMyRetro: "/api/retro/delete-retro",

  getScrumPokerRoomId: "/api/scrum-poker/get-user-scrum-poker-room-id",
  createScrumPokerRoom: "/api/scrum-poker/create-scrum-poker-room-id",
  isScrumPoker: "/api/scrum-poker/is-scrum-poker",
  getScrumPokerRoom: "/api/scrum-poker/get-scrum-poker-room",
  voteScrumPoker: "/api/scrum-poker/vote-scrum-poker",
  resetScrumPoker: "/api/scrum-poker/reset-votes",
  changeScrumPokerStatus: "/api/scrum-poker/change-scrum-poker-locked",
  changeScrumPokerTitle: "/api/scrum-poker/change-scrum-poker-title",
  changeScrumPokerDescription:
    "/api/scrum-poker/change-scrum-poker-description",
  changeScrumPokerCards: "/api/scrum-poker/change-scrum-poker-cards",
  defaultScrumPokerCards: "/api/scrum-poker/default-scrum-poker-cards",

  pusher: "/api/pusher/trigger",
};

export default endpoints;
