export enum ConversationTypes {
  GET_CONVERSATIONS = "GET_CONVERSATIONS",
  GET_MESSAGES = "GET_MESSAGES",
  SEND_MESSAGE = "SEND_MESSAGE",
  SOCKET_ADD_MESSAGE = "SOCKET_ADD_MESSAGE",
  MARK_MESSAGE_READ = "MARK_MESSAGE_READ",
  READ_CONVERSATION = "READ_CONVERSATION",
}

export interface ConversationsState {
  conversations: Conversation[];
  messages: { [chatId: string]: Messages[] };
  getConversationsLoading: boolean;
}

export interface Conversation {
  _id: string;
  participants: User[];
  lastMessage: Message;
  lastMessageDate: string;
  lastMessageSender: string;
  chatId: string;
  isRead: boolean;
}

export interface Message {
  message: string;
  createdAt: string;
  senderId: string;
}

export interface User {
  _id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  image: string;
}

export interface Messages {
  _id: string;
  senderId: string;
  receiverId: string;
  message: string;
  chatId: string;
  createdAt: string;
  updatedAt: string;
  isRead?: boolean;
}
