import React, { useEffect } from "react";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { accountSelector } from "../../store/account";
import Logo from "../../components/Logo";
import MainContent from "../../components/MainContent";
import { AppDispatch } from "../../store";
import { uiSelector } from "../../store/ui";

interface LayoutProps {
  type?: string;
}

const Layout = ({ type }: LayoutProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const { accountInfoLoading } = useSelector(accountSelector);
  const { activePage } = useSelector(uiSelector);
  useEffect(() => {
    const menuChildrenKey = localStorage.getItem("menuChildrenKey");
    if (menuChildrenKey) {
      if (menuChildrenKey !== "Retro Dashboard") {
        localStorage.removeItem("retroId");
      }
      if (menuChildrenKey !== "Scrum Poker Dashboard") {
        localStorage.removeItem("scrumPokerRoomId");
      }
    }
  }, [activePage]);

  return (
    <>
      {accountInfoLoading ? (
        <div className={styles.main_logo}>
          <div className={styles.main_logo_slideIn}>
            <Logo size="large" />
          </div>
        </div>
      ) : (
        <MainContent type={type} />
      )}
    </>
  );
};

export default Layout;
