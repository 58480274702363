export enum UIActionTypes {
  SET_COLLAPSED = "SET_COLLAPSED",
  ACTIVE_PAGE = "ACTIVE_PAGE",
  ACTIVE_CHAT_USER = "ACTIVE_CHAT_USER",
  DELETE_CHAT_USER = "DELETE_CHAT_USER",
  EXPORT_RETRO = "EXPORT_RETRO",
}

export interface UIState {
  collapsed: boolean;
  activePage: string;
  activeChatUser: string[];
  exportRetro: boolean;
}
