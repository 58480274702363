import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTeam,
  getTeams,
  leaveTeam,
  removeRequest,
  teamSelector,
} from "../../store/team";
import { AppDispatch } from "../../store";
import {
  LoadingOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  DownOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  StopOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  UsergroupAddOutlined,
  CrownFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu, Modal, Table, Tooltip } from "antd";
import endpoints from "../../services/endpoints";
import {
  acceptFriendRequest,
  accountSelector,
  addFriend,
  deleteFriend,
  deleteFriendRequest,
  rejectFriendRequest,
} from "../../store/account";
import { setActivePage, uiSelector } from "../../store/ui";
import { Team, TeamUsers } from "../../store/team/types";
import AddMemberModal from "./Components/AddMemberModal/AddMemberModal";
import { ColumnType } from "antd/lib/table";
import RemoveMemberModal from "./Components/RemoveMemberModal/RemoveMemberModal";
import EditTeamModal from "./Components/EditTeamModal";
import { toast } from "react-toastify";

const TeamList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { collapsed } = useSelector(uiSelector);

  const { getTeamsLoading, teams, isSearchResults } = useSelector(teamSelector);
  const { accountInfo, loggedIn } = useSelector(accountSelector);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [hoveredId, setHoveredId] = useState("");
  const [selectedTeam, setSelectedTeam] = useState<Team>();
  const [addMemberModalVisible, setAddMemberModalVisible] = useState(false);
  const [removeMemberModalVisible, setRemoveMemberModalVisible] =
    useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedInvitation, setSelectedInvitation] = useState("");
  const [blink, setBlink] = useState(false);
  const [deleteTeamConfirmModalVisible, setDeleteTeamConfirmModalVisible] =
    useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (loggedIn) {
      dispatch(getTeams());
      const teamId = localStorage.getItem("teamId");
      if (teamId) {
        setSelectedInvitation(teamId);
        localStorage.removeItem("teamId");
        setBlink(true);
      }
    }
  }, [dispatch]);

  const handleDeleteTeam = (teamId: string) => {
    dispatch(
      deleteTeam({
        teamId,
        onSuccess: () => {},
        onError: (message) => {
          toast.error(message);
          dispatch(getTeams());
        },
      })
    );
  };

  const handleLeaveTeam = (teamId: string) => {
    dispatch(leaveTeam({ teamId, onSuccess: () => {} }));
  };

  const handleRemoveRequest = (teamId: string, userId: string) => {
    dispatch(
      removeRequest({
        teamId,
        userId,
        onSuccess: () => {},
      })
    );
  };

  const mergedColumns: ColumnType<Team>[] = [
    {
      title: "Logo",
      dataIndex: "image",
      key: "logo",
      render: (image: string, record: { name: string }) => {
        const imgUrl = image ? `${endpoints.baseUrl}/${image}` : "";
        return image ? (
          <div className={styles.main_table_avatar}>
            <img src={imgUrl} alt="team" />
          </div>
        ) : (
          <div className={styles.main_table_avatar}>
            {record.name.charAt(0).toUpperCase()}
            {record.name.charAt(1).toUpperCase()}
          </div>
        );
      },
      width: "5rem",
      responsive: ["sm"],
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      responsive: ["sm"],
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) => {
        const date = new Date(createdAt);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      },
      responsive: ["xl"],
    },
    {
      title: "Team Leader",
      dataIndex: "admin",
      key: "admin",
      render: (admin: TeamUsers[]) => {
        const menu = (
          <Menu>
            {admin.map((leader) => (
              <Menu.Item key={leader.username}>
                <div className={styles.main_table_userInfo}>
                  <div className={styles.main_table_userInfo_avatar}>
                    {leader.image ? (
                      <img
                        src={`${endpoints.baseUrl}/${leader.image}`}
                        alt="user"
                      />
                    ) : (
                      <div className={styles.main_table_userInfo_avatar_text}>
                        {leader.username.charAt(0).toUpperCase()}
                        {leader.username.charAt(1).toUpperCase()}
                      </div>
                    )}
                  </div>
                  <div className={styles.main_table_userInfo_text}>
                    <div>
                      {leader.firstName
                        ? `${leader.firstName} ${leader.lastName}`
                        : leader.username}
                    </div>
                  </div>
                </div>
              </Menu.Item>
            ))}
          </Menu>
        );
        return admin.length > 1 ? (
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            overlayStyle={{ userSelect: "none" }}
          >
            <span>
              {admin.length} admins{" "}
              <DownOutlined
                style={{
                  cursor: "pointer",
                }}
              />
            </span>
          </Dropdown>
        ) : (
          <>
            {accountInfo?.username === admin[0].username ? (
              <div className={styles.main_table_userInfo}>
                <div className={styles.main_table_userInfo_avatar}>
                  {admin[0].image ? (
                    <img
                      src={`${endpoints.baseUrl}/${admin[0].image}`}
                      alt="user"
                    />
                  ) : (
                    <div className={styles.main_table_userInfo_avatar_text}>
                      {admin[0].username.charAt(0).toUpperCase()}
                      {admin[0].username.charAt(1).toUpperCase()}
                    </div>
                  )}
                </div>
                <div className={styles.main_table_userInfo_text}>
                  <div>
                    {admin[0].firstName
                      ? `${admin[0].firstName} ${admin[0].lastName}`
                      : admin[0].username}
                    {admin[0].username === accountInfo?.username && (
                      <Tooltip title="Me">
                        <CrownFilled
                          style={{ color: "#f35d5a", fontSize: "1rem" }}
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.main_table_userInfo}>
                <div className={styles.main_table_userInfo_avatar}>
                  {admin[0].image ? (
                    <img
                      src={`${endpoints.baseUrl}/${admin[0].image}`}
                      alt="user"
                    />
                  ) : (
                    <div className={styles.main_table_userInfo_avatar_text}>
                      {admin[0].username.charAt(0).toUpperCase()}
                      {admin[0].username.charAt(1).toUpperCase()}
                    </div>
                  )}
                </div>
                <div className={styles.main_table_userInfo_text}>
                  <div>
                    {admin[0].firstName
                      ? `${admin[0].firstName} ${admin[0].lastName}`
                      : admin[0].username}
                  </div>
                </div>
              </div>
            )}
          </>
        );
      },
      responsive: ["sm"],
    },
    {
      title: "Members",
      dataIndex: "members",
      key: "members",
      render: (members: TeamUsers[]) => {
        const menu = (
          <Menu>
            {members.map((member) => (
              <Menu.Item key={member.username}>
                <div className={styles.main_table_userInfo}>
                  <div className={styles.main_table_userInfo_avatar}>
                    {member.image ? (
                      <img
                        src={`${endpoints.baseUrl}/${member.image}`}
                        alt="user"
                      />
                    ) : (
                      <div className={styles.main_table_userInfo_avatar_text}>
                        {member.username.charAt(0).toUpperCase()}
                        {member.username.charAt(1).toUpperCase()}
                      </div>
                    )}
                  </div>
                  <div className={styles.main_table_userInfo_text}>
                    <div>
                      {member.firstName
                        ? `${member.firstName} ${member.lastName}`
                        : member.username}
                    </div>
                  </div>
                  {member._id !== accountInfo?._id && (
                    <div>
                      {accountInfo?.friends?.some(
                        (friend) => friend._id === member._id
                      ) ? (
                        <Tooltip title="Remove Friend">
                          <div
                            className={styles.main_table_userInfo_icon}
                            onClick={() =>
                              dispatch(
                                deleteFriend({
                                  userId: member._id,
                                  onSuccess: () => {},
                                })
                              )
                            }
                          >
                            <UserDeleteOutlined />
                          </div>
                        </Tooltip>
                      ) : accountInfo?.sendFriendRequests?.some(
                          (request) => request._id === member._id
                        ) ? (
                        <div className={styles.main_table_userInfo_actions}>
                          <Tooltip title="Friend Request Sent">
                            <div className={styles.main_table_userInfo_icon}>
                              <InfoCircleOutlined
                                style={{ color: "#FEBB46" }}
                              />
                            </div>
                          </Tooltip>
                          <Tooltip title="Cancel Friend Request">
                            <div
                              className={styles.main_table_userInfo_icon}
                              onClick={() =>
                                dispatch(
                                  deleteFriendRequest({
                                    userId: member._id,
                                    onSuccess: () => {},
                                  })
                                )
                              }
                            >
                              <StopOutlined style={{ color: "#fd271f" }} />
                            </div>
                          </Tooltip>
                        </div>
                      ) : accountInfo?.receiveFriendRequests?.some(
                          (request) => request._id === member._id
                        ) ? (
                        <div className={styles.main_table_userInfo_actions}>
                          <Tooltip title="Accept Friend Request">
                            <div
                              className={styles.main_table_userInfo_icon}
                              onClick={() => {
                                dispatch(
                                  acceptFriendRequest({
                                    userId: member._id,
                                    onSuccess: () => {},
                                  })
                                );
                              }}
                            >
                              <CheckCircleOutlined
                                style={{ color: "#01b503" }}
                              />
                            </div>
                          </Tooltip>
                          <Tooltip title="Reject Friend Request">
                            <div
                              className={styles.main_table_userInfo_icon}
                              onClick={() => {
                                dispatch(
                                  rejectFriendRequest({
                                    userId: member._id,
                                    onSuccess: () => {},
                                  })
                                );
                              }}
                            >
                              <CloseCircleOutlined
                                style={{ color: "#fd271f" }}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      ) : (
                        <Tooltip title="Add Friend">
                          <div
                            className={styles.main_table_userInfo_icon}
                            onClick={() =>
                              dispatch(
                                addFriend({
                                  userId: member._id,
                                  onSuccess: () => {},
                                })
                              )
                            }
                          >
                            <UserAddOutlined />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  )}
                </div>
              </Menu.Item>
            ))}
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <span>
              {members.length} members <DownOutlined />
            </span>
          </Dropdown>
        );
      },
      responsive: ["md"],
    },
    {
      title: "Invitations",
      dataIndex: "requests",
      key: "requests",
      render: (requests: TeamUsers[]) => {
        const menu = (
          <Menu>
            {requests.map((request) => (
              <Menu.Item key={request.username}>
                <div className={styles.main_table_userInfo}>
                  <div className={styles.main_table_userInfo_avatar}>
                    {request.image ? (
                      <img
                        src={`${endpoints.baseUrl}/${request.image}`}
                        alt="user"
                      />
                    ) : (
                      <div className={styles.main_table_userInfo_avatar_text}>
                        {request.username.charAt(0).toUpperCase()}
                        {request.username.charAt(1).toUpperCase()}
                      </div>
                    )}
                  </div>
                  <div className={styles.main_table_userInfo_text}>
                    <div>
                      {request.firstName
                        ? `${request.firstName} ${request.lastName}`
                        : request.username}
                    </div>
                  </div>
                  <Tooltip title="Remove Request">
                    <div
                      className={styles.main_table_userInfo_icon}
                      onClick={() =>
                        handleRemoveRequest(
                          selectedTeam?._id as string,
                          request._id as string
                        )
                      }
                    >
                      <StopOutlined />
                    </div>
                  </Tooltip>
                </div>
              </Menu.Item>
            ))}
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <span>
              {requests.length} invitations <DownOutlined />
            </span>
          </Dropdown>
        );
      },
      responsive: ["lg"],
    },
    {
      title: "",
      key: "action",
      render: (record: Team) => {
        return record.admin
          .map((admin) => admin._id)
          .includes(accountInfo?._id as string) ? (
          <div className={styles.main_table_actions}>
            <Tooltip title="Add Member">
              <div
                className={styles.main_table_actions_icon}
                onClick={() => setAddMemberModalVisible(true)}
              >
                <PlusCircleOutlined style={{ color: "#01b503" }} />
              </div>
            </Tooltip>
            <Tooltip title="Remove Member">
              <div
                className={styles.main_table_actions_icon}
                onClick={() => setRemoveMemberModalVisible(true)}
              >
                <MinusCircleOutlined style={{ color: "#fd271f" }} />
              </div>
            </Tooltip>
            <Tooltip title="Delete Team">
              <div
                className={styles.main_table_actions_icon}
                onClick={() => setDeleteTeamConfirmModalVisible(true)}
              >
                <DeleteOutlined />
              </div>
            </Tooltip>
            <Tooltip title="Edit Team">
              <div
                className={styles.main_table_actions_icon}
                onClick={() => setEditModalVisible(true)}
              >
                <EditOutlined />
              </div>
            </Tooltip>
            {record.admin.length > 1 && (
              <div
                className={styles.main_table_actions}
                onClick={() => handleLeaveTeam(record._id)}
              >
                <Tooltip title="Leave Team">
                  <div className={styles.main_table_actions_icon}>
                    <UserDeleteOutlined />
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        ) : (
          <div
            className={styles.main_table_actions}
            onClick={() => handleLeaveTeam(record._id)}
          >
            <Tooltip title="Leave Team">
              <div className={styles.main_table_actions_icon}>
                <UserDeleteOutlined />
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.main}>
      {getTeamsLoading ? (
        <div className={styles.main_loading}>
          <LoadingOutlined style={{ color: "#f35d5a" }} />
        </div>
      ) : (
        <div className={styles.main_table}>
          <Table
            dataSource={teams}
            columns={mergedColumns}
            pagination={false}
            scroll={{ y: windowHeight - 280 }}
            rowKey={(record) => record._id}
            style={{
              width: collapsed ? "calc(100vw - 20rem)" : "100%",
            }}
            onRow={(record) => {
              return {
                onMouseEnter: () => setHoveredId(record._id),
                onMouseLeave: () =>
                  hoveredId === record._id && setHoveredId(""),
                onClick: () => {
                  setSelectedTeam(record);
                  setSelectedInvitation(record._id);
                  setBlink(false);
                },
              };
            }}
            rowClassName={(record) =>
              record._id === selectedInvitation && !blink
                ? styles.main_table_row_selected
                : record._id === selectedInvitation && blink
                ? styles.main_table_row_blink
                : ""
            }
            locale={{
              emptyText: (
                <div className={styles.main_table_empty}>
                  {isSearchResults ? (
                    <>
                      <img src="/images/notFoundSearch.jpg" alt="empty" />
                      <span className={styles.main_table_empty_text}>
                        Opps, no results were found that match your search
                        criteria.
                      </span>
                    </>
                  ) : (
                    <>
                      <img src="/images/noTeam.jpg" alt="empty" />
                      <span className={styles.main_table_empty_text}>
                        You do not have an active team. You can create your team
                        using the 'Create Team' button below.
                      </span>
                      <Button
                        type="primary"
                        className={styles.main_table_empty_button}
                        onClick={() => {
                          dispatch(setActivePage("Create Team"));
                        }}
                      >
                        <UsergroupAddOutlined />
                        Create Team
                      </Button>
                    </>
                  )}
                </div>
              ),
            }}
          />
        </div>
      )}
      {addMemberModalVisible && (
        <AddMemberModal
          visible={addMemberModalVisible}
          onClose={() => setAddMemberModalVisible(false)}
          teamId={selectedTeam?._id}
        />
      )}
      {removeMemberModalVisible && (
        <RemoveMemberModal
          visible={removeMemberModalVisible}
          onClose={() => setRemoveMemberModalVisible(false)}
          selectedTeam={selectedTeam}
        />
      )}
      {editModalVisible && (
        <EditTeamModal
          visible={editModalVisible}
          onCancel={() => setEditModalVisible(false)}
          selectedTeam={selectedTeam as Team}
        />
      )}
      {deleteTeamConfirmModalVisible && (
        <Modal
          title={
            <div className={styles.main_modal_title}>
              <ExclamationCircleOutlined
                style={{ color: "#fd271f", fontSize: "2rem" }}
              />
              Delete Team
            </div>
          }
          open={deleteTeamConfirmModalVisible}
          footer={false}
          onCancel={() => setDeleteTeamConfirmModalVisible(false)}
        >
          <div className={styles.main_modal}>
            <div className={styles.main_modal_text}>
              All links that the team is involved in will be public. Are you
              sure you want to delete the team?
            </div>
            <div className={styles.main_modal_footer}>
              <Button
                type="primary"
                onClick={() => setDeleteTeamConfirmModalVisible(false)}
                className={styles.main_modal_footer_button}
              >
                No
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  handleDeleteTeam(selectedTeam?._id as string);
                  setDeleteTeamConfirmModalVisible(false);
                }}
                className={styles.main_modal_footer_button}
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default TeamList;
