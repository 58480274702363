import React, { useEffect, useState, useCallback, useMemo } from "react";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Dropdown, Input, Modal, Tooltip } from "antd";
import { debounce } from "lodash";
import { AppDispatch } from "../../../../store";
import { setExportRetro, uiSelector } from "../../../../store/ui";
import {
  getInvitations,
  getTeams,
  searchInvitations,
  searchTeams,
  teamSelector,
} from "../../../../store/team";
import {
  changeRetroFinish,
  changeRetroOnShow,
  changeRetroStatus,
  retroSelector,
} from "../../../../store/retro";
import endpoints from "../../../../services/endpoints";
import { Team } from "../../../../store/team/types";
import {
  UnlockOutlined,
  LockOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  FieldTimeOutlined,
  ShareAltOutlined,
  CopyOutlined,
  StopOutlined,
  SyncOutlined,
  ClockCircleOutlined,
  QrcodeOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { accountSelector } from "../../../../store/account";

const Header = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { activePage } = useSelector(uiSelector);
  const { retro } = useSelector(retroSelector);
  const { teams } = useSelector(teamSelector);
  const { accountInfo } = useSelector(accountSelector);
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [teamInfo, setTeamInfo] = useState<Team>();
  const [countdown, setCountdown] = useState("");
  const [addRetroFinishTimeModalVisible, setAddRetroFinishTimeModalVisible] =
    useState(false);
  const [finishedAt, setFinishedAt] = useState("");
  const [shareModalVisible, setShareModalVisible] = useState(false);

  useEffect(() => {
    if (activePage === "Retro Dashboard" && retro) {
      const team = teams.find((team) => team._id === retro.teamId);
      if (team) {
        setTeamInfo(team);
      }
    } else {
      setTeamInfo(undefined);
    }
  }, [activePage, retro, teams]);

  const isAdminOrCreator = useMemo(() => {
    return (
      teamInfo?.admin.some((member) => member._id === accountInfo?._id) ||
      retro?.createdBy === accountInfo?._id
    );
  }, [teamInfo, accountInfo, retro]);

  useEffect(() => {
    if (search) {
      setSearch("");
    }
  }, [activePage]);

  useEffect(() => {
    if (retro?.finishedAt) {
      const targetTime = new Date(retro.finishedAt);
      const intervalId = setInterval(() => {
        const now = new Date();
        const difference = targetTime.getTime() - now.getTime();
        if (difference > 0) {
          const hours = Math.floor((difference / (1000 * 60 * 60)) % 24)
            .toString()
            .padStart(2, "0");
          const minutes = Math.floor((difference / 1000 / 60) % 60)
            .toString()
            .padStart(2, "0");
          const seconds = Math.floor((difference / 1000) % 60)
            .toString()
            .padStart(2, "0");
          setCountdown(`${hours}:${minutes}:${seconds}`);
        } else {
          clearInterval(intervalId);
          setCountdown("Time is up");
          if (retro?.finishedAt + 2000 > new Date().toISOString()) {
            dispatch(
              changeRetroOnShow({
                retroId: retro?._id as string,
                onShow: true,
                onSuccess: () => {},
              })
            );
          }
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [retro?.finishedAt]);

  const debouncedSearch = useCallback(
    debounce((query) => {
      switch (activePage) {
        case "My Teams":
          if (search) {
            dispatch(searchTeams({ search: query }));
            setIsSearch(true);
          }
          break;
        case "Team Invitations":
          if (search) {
            dispatch(searchInvitations({ search: query }));
            setIsSearch(true);
          }

          break;
        case "My Retros":
          if (search) {
            setIsSearch(true);
          }
          break;
        default:
          break;
      }
    }, 1000),
    [activePage, dispatch, search]
  );

  useEffect(() => {
    if (!search && isSearch) {
      switch (activePage) {
        case "My Teams":
          dispatch(getTeams());
          setIsSearch(false);
          break;
        case "Team Invitations":
          dispatch(getInvitations());
          setIsSearch(false);
          break;
        case "My Retros":
          setIsSearch(false);
          break;
        default:
          break;
      }
    }
    debouncedSearch(search);
    return debouncedSearch.cancel;
  }, [search, debouncedSearch]);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleChangeStatus = () => {
    if (isAdminOrCreator) {
      dispatch(
        changeRetroStatus({
          retroId: retro?._id as string,
          status: retro?.status === "inactive" ? "active" : "inactive",
          onSuccess: () => {},
        })
      );
    }
  };

  const handleChangeOnShow = () => {
    if (isAdminOrCreator) {
      dispatch(
        changeRetroOnShow({
          retroId: retro?._id as string,
          onShow: !retro?.onShow,
          onSuccess: () => {},
        })
      );
    }
  };

  const handleChangeFinishedAt = (finishedAt: any) => {
    if (isAdminOrCreator) {
      dispatch(
        changeRetroFinish({
          retroId: retro?._id as string,
          finishedAt: finishedAt,
          onSuccess: () => {},
        })
      );
    }
  };

  return (
    <Badge.Ribbon
      text={retro?.retroPrivate ? "Private" : "Public"}
      color={retro.retroPrivate ? "red" : "blue"}
      style={{ display: activePage === "Retro Dashboard" ? "block" : "none" }}
    >
      <div className={styles.main}>
        <div className={styles.main_title}>
          {activePage}
          {activePage === "Retro Dashboard" &&
            retro &&
            " - " + retro?.retroName}
          {/* {teamInfo && (
          <div className={styles.main_teamInfoArea}>
            <div className={styles.main_teamInfoArea_avatar}>
              {teamInfo?.image ? (
                <img
                  src={`${endpoints.baseUrl}/${teamInfo.image}`}
                  alt="team"
                />
              ) : (
                <div>
                  {teamInfo.name.charAt(0).toUpperCase()}
                  {teamInfo.name.charAt(1).toUpperCase()}
                </div>
              )}
            </div>
            <div className={styles.main_teamInfoArea_name}>{teamInfo.name}</div>
          </div>
        )} */}
        </div>
        {["My Teams", "Team Invitations", "My Retros"].includes(activePage) && (
          <Input
            className={styles.main_input}
            style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
            placeholder={`Search in ${activePage}...`}
            allowClear
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
          />
        )}
        {activePage === "Retro Dashboard" && (
          <div className={styles.main_actions}>
            {retro?.status === "inactive" ? (
              <div
                className={styles.main_actions_item}
                onClick={handleChangeStatus}
                style={{
                  cursor: teamInfo
                    ? teamInfo?.admin.find(
                        (member) => member._id === accountInfo?._id
                      )
                      ? "pointer"
                      : "not-allowed"
                    : retro?.createdBy === accountInfo?._id
                    ? "pointer"
                    : "not-allowed",
                }}
              >
                <div className={styles.main_actions_item_icon}>
                  <UnlockOutlined />
                </div>
                <div className={styles.main_actions_item_text}>Unlock</div>
              </div>
            ) : (
              <div
                className={styles.main_actions_item}
                onClick={handleChangeStatus}
                style={{
                  cursor: teamInfo
                    ? teamInfo?.admin.find(
                        (member) => member._id === accountInfo?._id
                      )
                      ? "pointer"
                      : "not-allowed"
                    : retro?.createdBy === accountInfo?._id // And here as well
                    ? "pointer"
                    : "not-allowed",
                }}
              >
                <div className={styles.main_actions_item_icon}>
                  <LockOutlined />
                </div>
                <div className={styles.main_actions_item_text}>Lock</div>
              </div>
            )}
            {!retro?.onShow ? (
              <div
                className={styles.main_actions_item}
                onClick={handleChangeOnShow}
                style={{
                  cursor: teamInfo
                    ? teamInfo?.admin.find(
                        (member) => member._id === accountInfo?._id
                      )
                      ? "pointer"
                      : "not-allowed"
                    : retro?.createdBy === accountInfo?._id // And here as well
                    ? "pointer"
                    : "not-allowed",
                }}
              >
                <div className={styles.main_actions_item_icon}>
                  <EyeOutlined />
                </div>
                <div className={styles.main_actions_item_text}>Show</div>
              </div>
            ) : (
              <div
                className={styles.main_actions_item}
                onClick={handleChangeOnShow}
                style={{
                  cursor: teamInfo
                    ? teamInfo?.admin.find(
                        (member) => member._id === accountInfo?._id
                      )
                      ? "pointer"
                      : "not-allowed"
                    : retro?.createdBy === accountInfo?._id // And here as well
                    ? "pointer"
                    : "not-allowed",
                }}
              >
                <div className={styles.main_actions_item_icon}>
                  <EyeInvisibleOutlined />
                </div>
                <div className={styles.main_actions_item_text}>Hide</div>
              </div>
            )}
            {retro?.finishedAt ? (
              <div
                className={styles.main_actions_item}
                style={{
                  cursor: teamInfo
                    ? teamInfo?.admin.find(
                        (member) => member._id === accountInfo?._id
                      )
                      ? "pointer"
                      : "not-allowed"
                    : retro?.createdBy === accountInfo?._id // And here as well
                    ? "pointer"
                    : "not-allowed",
                }}
              >
                <ClockCircleOutlined />
                {countdown}
                <Tooltip title="Remove Time">
                  <div
                    className={styles.main_actions_item_button}
                    onClick={() =>
                      teamInfo?.admin.find(
                        (member) => member._id === accountInfo?._id
                      ) || retro?.createdBy === accountInfo?._id
                        ? handleChangeFinishedAt(null)
                        : null
                    }
                    style={{
                      cursor: teamInfo
                        ? teamInfo?.admin.find(
                            (member) => member._id === accountInfo?._id
                          )
                          ? "pointer"
                          : "not-allowed"
                        : retro?.createdBy === accountInfo?._id // And here as well
                        ? "pointer"
                        : "not-allowed",
                    }}
                  >
                    <StopOutlined />
                  </div>
                </Tooltip>
                <Tooltip title="Retime">
                  <div
                    className={styles.main_actions_item_button}
                    onClick={() => {
                      if (isAdminOrCreator) {
                        setAddRetroFinishTimeModalVisible(true);
                      }
                    }}
                    style={{
                      cursor: teamInfo
                        ? teamInfo?.admin.find(
                            (member) => member._id === accountInfo?._id
                          )
                          ? "pointer"
                          : "not-allowed"
                        : retro?.createdBy === accountInfo?._id
                        ? "pointer"
                        : "not-allowed",
                    }}
                  >
                    <SyncOutlined />
                  </div>
                </Tooltip>
              </div>
            ) : (
              <div
                className={styles.main_actions_item}
                onClick={() => setAddRetroFinishTimeModalVisible(true)}
                style={{
                  cursor: teamInfo
                    ? teamInfo?.admin.find(
                        (member) => member._id === accountInfo?._id
                      )
                      ? "pointer"
                      : "not-allowed"
                    : retro?.createdBy === accountInfo?._id // And here as well
                    ? "pointer"
                    : "not-allowed",
                }}
              >
                <div className={styles.main_actions_item_icon}>
                  <FieldTimeOutlined />
                </div>
                Add Duration
              </div>
            )}
            <div
              className={styles.main_actions_item}
              onClick={() => setShareModalVisible(true)}
            >
              <div className={styles.main_actions_item_icon}>
                <ShareAltOutlined />
              </div>
              Share
            </div>
            <Tooltip
              title={
                retro?.status === "active" || retro?.onShow === false
                  ? "You can only export a retro when it is lock and is show"
                  : "Export Retro"
              }
            >
              <div
                className={styles.main_actions_item}
                onClick={() => {
                  retro?.status !== "active" &&
                    retro?.onShow === true &&
                    dispatch(setExportRetro(true));
                }}
                style={{
                  cursor:
                    retro?.status !== "active" && retro?.onShow === true
                      ? "pointer"
                      : "not-allowed",
                }}
              >
                <div className={styles.main_actions_item_icon}>
                  <ExportOutlined />
                </div>
                Export
              </div>
            </Tooltip>
          </div>
        )}
        {addRetroFinishTimeModalVisible && (
          <Modal
            title="Add Finish Time"
            visible={addRetroFinishTimeModalVisible}
            onCancel={() => setAddRetroFinishTimeModalVisible(false)}
            onOk={() => setAddRetroFinishTimeModalVisible(false)}
            footer={null}
          >
            <Input
              className={styles.main_actions_input}
              style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
              type="number"
              placeholder="Duration (in minutes)"
              allowClear
              value={finishedAt}
              onChange={(e) => setFinishedAt(e.target.value)}
              onPressEnter={() => {
                handleChangeFinishedAt(finishedAt);
                setAddRetroFinishTimeModalVisible(false);
                setFinishedAt("");
              }}
            />
            <Button
              type="primary"
              className={styles.main_actions_button}
              onClick={() => {
                handleChangeFinishedAt(finishedAt);
                setAddRetroFinishTimeModalVisible(false);
                setFinishedAt("");
              }}
            >
              Add Finish Time
            </Button>
          </Modal>
        )}
        {shareModalVisible && (
          <Modal
            title="Share Retro"
            visible={shareModalVisible}
            onCancel={() => setShareModalVisible(false)}
            onOk={() => setShareModalVisible(false)}
            footer={null}
          >
            <div className={styles.main_share}>
              <Input
                className={styles.main_actions_input}
                style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
                value={`${window.location.origin}/retro/${retro?._id}`}
                readOnly
              />
              <Button
                className={styles.main_share_button}
                type="primary"
                onClick={() => {
                  navigator.clipboard.writeText(retro?.url);
                }}
              >
                <CopyOutlined />
              </Button>
              <Dropdown
                overlay={
                  <div className={styles.main_share_qr}>
                    <img
                      src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${retro?.url}`}
                      alt="qr"
                    />
                  </div>
                }
                placement="bottomCenter"
                arrow
                trigger={["click"]}
              >
                <Button className={styles.main_share_button} type="primary">
                  <QrcodeOutlined />
                </Button>
              </Dropdown>
            </div>
          </Modal>
        )}
      </div>
    </Badge.Ribbon>
  );
};

export default Header;
