import React from "react";
import styles from "./index.module.scss";
import { Button } from "antd";

const Welcome = () => {
  return (
    <div className={styles.main}>
      <div className={styles.main_content}>
        <div className={styles.main_content_text}>
          retrothink.io is designed to maximize collaboration and interaction in
          the digital transformation era. With our innovative tools, we enable
          teams to work more efficiently, communicate more effectively on
          projects, and better understand their processes. Equipped with
          retrospective analyses, Scrum poker, wheel-spinning games, and more,
          this platform offers everything your team needs to unlock its
          potential. Aim for excellence in your projects and revolutionize your
          business processes with these team-spirited solutions.
        </div>
        <div className={styles.main_content_buttonArea}>
          <Button
            type="primary"
            size="large"
            href="/register"
            className={styles.main_content_buttonArea_button}
          >
            Get Started
          </Button>
        </div>
      </div>
      <div className={styles.main_image}>
        <img src="/images/retrothinkwelcome.jpg" alt="welcome" />
      </div>
    </div>
  );
};

export default Welcome;
