import React, { useEffect, useRef, useState } from "react";
import style from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import {
  deleteChatUser,
  setActiveChatUserIds,
  uiSelector,
} from "../../store/ui";
import { accountSelector } from "../../store/account";
import { User } from "../../store/account/types";
import {
  conversationsSelector,
  getMessages,
  markMessageRead,
  readConversation,
  sendMessage,
} from "../../store/conversations";
import { CloseCircleOutlined, SendOutlined } from "@ant-design/icons";
import { Badge, Input } from "antd";

const Chat = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { activeChatUser } = useSelector(uiSelector);
  const { accountInfo, loggedIn } = useSelector(accountSelector);
  const { messages, conversations } = useSelector(conversationsSelector);

  const messageEndRef = useRef<Record<string, HTMLDivElement | null>>({});

  const [queriedUsers, setQueriedUsers] = useState<string[]>([]);
  const [friends, setFriends] = useState<User[]>([]);
  const [sendMessageTexts, setSendMessageTexts] = useState<{
    [userId: string]: string;
  }>({});
  const [expandedChats, setExpandedChats] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    if (accountInfo) {
      setFriends(accountInfo.friends);
    }
  }, [accountInfo]);

  useEffect(() => {
    if (loggedIn) {
      const newUsers = activeChatUser.filter(
        (user) => !queriedUsers.includes(user)
      );
      newUsers.forEach((user) => {
        dispatch(getMessages({ receiverId: user }));
        setQueriedUsers((prev) => [...prev, user]);
        setExpandedChats((prev) => ({ ...prev, [user]: false }));
      });
    }
  }, [activeChatUser, dispatch, loggedIn, queriedUsers]);

  useEffect(() => {
    activeChatUser.forEach((user) => {
      if (expandedChats[user] && messageEndRef.current[user]) {
        messageEndRef.current[user]?.scrollIntoView({ behavior: "smooth" });
      }
    });
  }, [messages, expandedChats]);

  const createChatId = (userId1: string, userId2: any) => {
    return [userId1, userId2].sort().join("-");
  };

  const handleSendMessage = (receiverId: string) => {
    const message = sendMessageTexts[receiverId] || "";
    if (message.trim()) {
      dispatch(
        sendMessage({
          receiverId,
          message,
        })
      );
      setSendMessageTexts((prev) => ({ ...prev, [receiverId]: "" }));
    }
  };

  const handleChangeMessageText = (userId: string, text: string) => {
    setSendMessageTexts((prev) => ({ ...prev, [userId]: text }));
  };

  const handleToggleChat = (userId: string) => {
    setExpandedChats((prev) => ({ ...prev, [userId]: !prev[userId] }));
    if (!expandedChats[userId]) {
      dispatch(
        readConversation({
          receiverId: userId,
        })
      );
    }
  };

  const hasUnreadMessages = (userId: string) => {
    const isExpanded = expandedChats[userId];
    const chatId = createChatId(userId, accountInfo?._id);
    const read = messages[chatId]?.some((msg) => msg.isRead === false) ?? false;

    return !isExpanded && read;
  };

  return (
    <div className={style.main}>
      {activeChatUser.map((user) => {
        const chatId = createChatId(user, accountInfo?._id);
        const chatMessages = messages[chatId] || [];
        const filteredMessages = chatMessages.filter(
          (message) =>
            message && message.message && message.message.trim() !== ""
        );

        return (
          <div
            className={style.main_chatTemplate}
            key={user}
            style={{ height: expandedChats[user] ? "300px" : "40px" }}
          >
            <div className={style.main_chatTemplate_header}>
              <div
                className={style.main_chatTemplate_header_title}
                onClick={() => {
                  handleToggleChat(user);
                  dispatch(
                    markMessageRead({
                      chatId: createChatId(user, accountInfo?._id),
                    })
                  );
                }}
              >
                <Badge dot={hasUnreadMessages(user)} offset={[6, 0]}>
                  {friends.find((friend) => friend._id === user)?.username ||
                    "Unknown"}
                </Badge>
              </div>
              <div
                className={style.main_chatTemplate_header_icon}
                onClick={() => {
                  const chatIds = JSON.parse(
                    localStorage.getItem("chatIds") || "[]"
                  );
                  const newChatIds = chatIds.filter(
                    (chatId: string) => chatId !== user
                  );
                  localStorage.setItem("chatIds", JSON.stringify(newChatIds));
                  dispatch(deleteChatUser(user));
                }}
              >
                <CloseCircleOutlined />
              </div>
            </div>
            {expandedChats[user] && (
              <>
                <div className={style.main_chatTemplate_messages}>
                  {filteredMessages.length > 0 &&
                    filteredMessages.map((message, index) => (
                      <div
                        key={index}
                        className={
                          message.senderId === accountInfo?._id
                            ? style.main_chatTemplate_messages_sender
                            : style.main_chatTemplate_messages_receiver
                        }
                      >
                        {message.message}
                      </div>
                    ))}
                  <div ref={(el) => (messageEndRef.current[user] = el)} />
                </div>
                <Input
                  className={style.main_chatTemplate_input}
                  placeholder="Type a message"
                  value={sendMessageTexts[user] || ""}
                  onChange={(e) =>
                    handleChangeMessageText(user, e.target.value)
                  }
                  onPressEnter={() => handleSendMessage(user)}
                  onFocus={() => {
                    conversations.map((conversation) => {
                      if (
                        conversation.chatId === chatId &&
                        !conversation.isRead
                      ) {
                        dispatch(
                          readConversation({
                            receiverId: user,
                          })
                        );
                        dispatch(markMessageRead({ chatId }));
                      }
                    });
                  }}
                />
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Chat;
