import { createAsyncThunk } from "@reduxjs/toolkit";
import { ActionTypes } from "./types";
import axios from "axios";
import endpoints from "../../services/endpoints";
import authService, { http } from "../../services/authService";

interface RegisterPayload {
  username: string;
  email: string;
  password: string;
  onSuccess: (message: string) => void;
}

export const register = createAsyncThunk(
  ActionTypes.REGISTER,
  async (payload: RegisterPayload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${endpoints.baseUrl}${endpoints.register}`,
        payload
      );
      payload.onSuccess(response.data.message);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface CheckUsernamePayload {
  username: string;
  onSuccess: (message: string) => void;
  onError: (message: string) => void;
}

export const checkUsername = createAsyncThunk(
  ActionTypes.CHECK_USERNAME,
  async (payload: CheckUsernamePayload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${endpoints.baseUrl}${endpoints.checkUsername}`,
        { username: payload.username }
      );
      payload.onSuccess(response.data.message);
      return response.data;
    } catch (error: any) {
      payload.onError(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

interface CheckEmailPayload {
  email: string;
  onSuccess: (message: string) => void;
  onError: (message: string) => void;
}

export const checkEmail = createAsyncThunk(
  ActionTypes.CHECK_EMAIL,
  async (payload: CheckEmailPayload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${endpoints.baseUrl}${endpoints.checkEmail}`,
        { email: payload.email }
      );
      payload.onSuccess(response.data.message);
      return response.data;
    } catch (error: any) {
      payload.onError(error.response.data.message);
      return rejectWithValue(error.response.data);
    }
  }
);

interface LoginPayload {
  user: string;
  password: string;
  onSuccess: (message: string) => void;
}

export const login = createAsyncThunk(
  ActionTypes.LOGIN,
  async (payload: LoginPayload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${endpoints.baseUrl}${endpoints.login}`,
        payload
      );
      payload.onSuccess(response.data.message);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface ForgotPasswordPayload {
  email: string;
  onSuccess: (message: string) => void;
}

export const forgotPassword = createAsyncThunk(
  ActionTypes.FORGOT_PASSWORD,
  async (payload: ForgotPasswordPayload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${endpoints.baseUrl}${endpoints.forgotPassword}`,
        payload
      );
      payload.onSuccess(response.data.message);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUserInfo = createAsyncThunk(
  ActionTypes.GET_USER_INFO,
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `${endpoints.baseUrl}${endpoints.getUserInfo}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const logout = createAsyncThunk(
  ActionTypes.LOGOUT,
  async (_, { rejectWithValue }) => {
    try {
      await http.post(`${endpoints.baseUrl}${endpoints.logout}`);
      authService.logout();
      return;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface AddFriendPayload {
  userId: string;
  onSuccess: (message: string) => void;
}

export const addFriend = createAsyncThunk(
  ActionTypes.ADD_FRIEND,
  async (payload: AddFriendPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.addFriend}`,
        { friendId: payload.userId }
      );
      payload.onSuccess(response.data.message);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface DeleteFriendRequestPayload {
  userId: string;
  onSuccess: (message: string) => void;
}

export const deleteFriendRequest = createAsyncThunk(
  ActionTypes.DELETE_FRIEND_REQUEST,
  async (payload: DeleteFriendRequestPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.deleteFriendRequest}`,
        { friendId: payload.userId }
      );
      payload.onSuccess(response.data.message);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface SocketAddFriendRequestPayload {
  _id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  image: string;
}

export const socketAddFriendRequest = createAsyncThunk(
  ActionTypes.SOCKET_ADD_FRIEND_REQUEST,
  async (payload: SocketAddFriendRequestPayload) => {
    return payload;
  }
);

interface AcceptFriendRequestPayload {
  userId: string;
  onSuccess: (message: string) => void;
}

export const acceptFriendRequest = createAsyncThunk(
  ActionTypes.ACCEPT_FRIEND_REQUEST,
  async (payload: AcceptFriendRequestPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.acceptFriendRequest}`,
        { friendId: payload.userId }
      );
      payload.onSuccess(response.data.message);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface RejectFriendRequestPayload {
  userId: string;
  onSuccess: (message: string) => void;
}

export const rejectFriendRequest = createAsyncThunk(
  ActionTypes.REJECT_FRIEND_REQUEST,
  async (payload: RejectFriendRequestPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.rejectFriendRequest}`,
        { friendId: payload.userId }
      );
      payload.onSuccess(response.data.message);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface DeleteFriendPayload {
  userId: string;
  onSuccess: (message: string) => void;
}

export const deleteFriend = createAsyncThunk(
  ActionTypes.DELETE_FRIEND,
  async (payload: DeleteFriendPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.deleteFriend}`,
        { friendId: payload.userId }
      );
      payload.onSuccess(response.data.message);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFriends = createAsyncThunk(
  ActionTypes.GET_FRIENDS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `${endpoints.baseUrl}${endpoints.getFriendAndRequests}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface ResendVerificationEmailPayload {
  user?: string;
  onSuccess: (message: string) => void;
}

export const resendVerificationEmail = createAsyncThunk(
  ActionTypes.RESEND_VERIFICATION_EMAIL,
  async (payload: ResendVerificationEmailPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.reSendVerificationEmail}`,
        payload
      );
      payload.onSuccess(response.data.message);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface UpdateProfilePayload {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  gender: string;
  image: File;
  onSuccess: () => void;
}

export const updateProfile = createAsyncThunk(
  ActionTypes.UPDATE_PROFILE,
  async (payload: UpdateProfilePayload, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("username", payload.username);
    formData.append("email", payload.email);
    formData.append("firstName", payload.firstName);
    formData.append("lastName", payload.lastName);
    formData.append("gender", payload.gender);
    if (payload.image) {
      formData.append("profileImages", payload.image, payload.image.name);
    }

    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.updateProfile}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface UpdatePasswordPayload {
  oldPassword: string;
  newPassword: string;
  onSuccess: (message: string) => void;
}

export const updatePassword = createAsyncThunk(
  ActionTypes.UPDATE_PASSWORD,
  async (payload: UpdatePasswordPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.updatePassword}`,
        payload
      );
      payload.onSuccess(response.data.message);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface ChangeMailPayload {
  password: string;
  email: string;
  onSuccess: (message: string) => void;
}

export const changeMail = createAsyncThunk(
  ActionTypes.UPDATE_MAIL,
  async (payload: ChangeMailPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.updateMail}`,
        payload
      );
      payload.onSuccess(response.data.message);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface ResetPasswordPayload {
  password: string;
  token: string;
  onSuccess: () => void;
}

export const resetPassword = createAsyncThunk(
  ActionTypes.RESET_PASSWORD,
  async (payload: ResetPasswordPayload, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${endpoints.baseUrl}${endpoints.resetPassword}/${payload.token}`,
        {
          password: payload.password,
        }
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface VerifyEmailPayload {
  token: string;
  onSuccess: () => void;
  onError: () => void;
}

export const verifyEmail = createAsyncThunk(
  ActionTypes.VERIFY_EMAIL,
  async (payload: VerifyEmailPayload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${endpoints.baseUrl}${endpoints.verifyEmail}/${payload.token}`
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      payload.onError();
      return rejectWithValue(error.response.data);
    }
  }
);

interface ChangeVerifyEmailPayload {
  token: string;
  onSuccess: () => void;
  onError: () => void;
}

export const changeVerifyEmail = createAsyncThunk(
  ActionTypes.CHANGE_VERIFY_EMAIL,
  async (payload: ChangeVerifyEmailPayload, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${endpoints.baseUrl}${endpoints.chandeVerifyEmail}/${payload.token}`
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      payload.onError();
      return rejectWithValue(error.response.data);
    }
  }
);

interface ChangeNicknamePayload {
  nickname: string;
  onSuccess: () => void;
}

export const changeNickname = createAsyncThunk(
  ActionTypes.CHANGE_NICKNAME,
  async (payload: ChangeNicknamePayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.updateProfile}`,
        { nickname: payload.nickname }
      );
      const responseData = { ...response.data, nickname: payload.nickname };
      payload.onSuccess();
      return responseData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface InviteFriendPayload {
  email: string;
  onSuccess: (message: string) => void;
}

export const inviteFriend = createAsyncThunk(
  ActionTypes.INVITE_FRIEND,
  async (payload: InviteFriendPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.inviteFriend}`,
        payload
      );
      payload.onSuccess(response.data.message);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
