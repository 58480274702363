import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import { accountSelector } from "../../../store/account";
import {
  deleteMyRetro,
  getRetros,
  getRetroTemplates,
  Retro,
  retroSelector,
} from "../../../store/retro";
import styles from "./index.module.scss";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, Modal, Table, Tooltip } from "antd";
import { ColumnType } from "antd/lib/table";
import {
  LockOutlined,
  ShareAltOutlined,
  CopyOutlined,
  QrcodeOutlined,
  BookOutlined,
  DeleteOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import endpoints from "../../../services/endpoints";
import { get3Dots } from "../../../Hooks/Helper";
import { getTeams, teamSelector } from "../../../store/team";
import { setActivePage } from "../../../store/ui";

const RetroList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loggedIn, accountInfo } = useSelector(accountSelector);
  const { retros, retrosLoading, retroTemplates } = useSelector(retroSelector);
  const { teams } = useSelector(teamSelector);
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [retro, setRetro] = useState<Retro | null>(null);

  useEffect(() => {
    if (loggedIn) {
      dispatch(getRetros());
      dispatch(getRetroTemplates());
      dispatch(getTeams());
    }
  }, [dispatch]);

  const canDelete = (record: any) => {
    if (record.createdBy._id === accountInfo?._id) {
      return true;
    }

    const team = teams.find((team) => team._id === record.teamId);
    return team?.admin.find((admin) => admin._id === accountInfo?._id);
  };

  const mergedColumns: ColumnType<Retro>[] = [
    {
      title: "Retro Name",
      dataIndex: "retroName",
      key: "retroName",
      render: (text, record) => (
        <div className={styles.main_table_name}>{record.retroName}</div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <>
          {record.status === "inactive" ? (
            <div className={styles.main_table_statusInactive}>
              <LockOutlined /> Finished
            </div>
          ) : (
            <div className={styles.main_table_statusActive}>Active</div>
          )}
        </>
      ),
    },
    {
      title: "Access Type",
      dataIndex: "retroPrivate",
      key: "retroPrivate",
      render: (text, record) => (
        <div className={styles.main_table_owner}>
          {record.teamId ? (
            <>
              {record.team?.image ? (
                <div className={styles.main_table_owner_avatar}>
                  <img
                    src={`${endpoints.baseUrl}/${record.team.image}`}
                    alt="avatar"
                  />
                </div>
              ) : (
                <div className={styles.main_table_owner_avatar}>
                  {record.team?.name?.charAt(0).toUpperCase()}
                  {record.team?.name?.charAt(1).toUpperCase()}
                </div>
              )}
              {record.team?.name}
            </>
          ) : (
            <span
              style={{
                fontWeight: "500",
              }}
            >
              Public
            </span>
          )}
        </div>
      ),
    },
    {
      title: "Template",
      dataIndex: "template",
      key: "template",
      render: (text, record) => (
        <Tooltip title={record.template?.templateName} placement="top">
          <div className={styles.main_table_template}>
            {get3Dots({
              text:
                retroTemplates.find(
                  (template) => template._id === record.templateId
                )?.templateName || "",
              length: 20,
            })}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <div className={styles.main_table_createdAt}>
          {new Date(record.createdAt).toLocaleString("en-US", {
            dateStyle: "medium",
            timeStyle: "short",
          })}
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <div className={styles.main_table_actions}>
          <Tooltip title="Share Retro">
            <div
              className={styles.main_table_actions_icon}
              onClick={() => {
                setShareModalVisible(true);
              }}
            >
              <ShareAltOutlined />
            </div>
          </Tooltip>
          <Tooltip title="Go Retro">
            <div
              className={styles.main_table_actions_icon}
              onClick={() => {
                window.location.href = record.url;
              }}
            >
              <BookOutlined />
            </div>
          </Tooltip>
          <Tooltip title="Delete Retro">
            <div
              className={styles.main_table_actions_icon}
              style={{ cursor: canDelete(record) ? "pointer" : "not-allowed" }}
              onClick={() => {
                dispatch(
                  deleteMyRetro({
                    retroId: record._id,
                    onSuccess: () => {
                      dispatch(getRetros());
                    },
                  })
                );
              }}
            >
              <DeleteOutlined />
            </div>
          </Tooltip>
        </div>
      ),
    },
  ];
  return (
    <div className={styles.main}>
      {retrosLoading ? (
        <div className={styles.main_loading}>
          <LoadingOutlined />
        </div>
      ) : (
        <Table
          dataSource={retros}
          pagination={false}
          columns={mergedColumns}
          style={{
            userSelect: "none",
          }}
          rowKey="_id"
          locale={{
            emptyText: (
              <div className={styles.main_empty}>
                <img src="/images/noRetros.jpg" alt="empty" />
                <span className={styles.main_empty_text}>
                  No retros found. Click on the button below to create a new
                  retro.
                </span>
                <Button
                  type="primary"
                  className={styles.main_empty_button}
                  onClick={() => {
                    dispatch(setActivePage("Create Retro"));
                  }}
                >
                  <FileAddOutlined />
                  Create Retro
                </Button>
              </div>
            ),
          }}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                window.location.href = record.url;
              },
              onClick: () => {
                setRetro(record);
              },
            };
          }}
        />
      )}
      {shareModalVisible && (
        <Modal
          title="Share Retro"
          visible={shareModalVisible}
          onCancel={() => setShareModalVisible(false)}
          onOk={() => setShareModalVisible(false)}
          footer={null}
        >
          <div className={styles.main_share}>
            <Input
              className={styles.main_actions_input}
              style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
              value={`${window.location.origin}/retro/${retro?._id}`}
              readOnly
            />
            <Button
              className={styles.main_share_button}
              type="primary"
              onClick={() => {
                navigator.clipboard.writeText(retro?.url || "");
              }}
            >
              <CopyOutlined />
            </Button>
            <Dropdown
              overlay={
                <div className={styles.main_share_qr}>
                  <img
                    src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${retro?.url}`}
                    alt="qr"
                  />
                </div>
              }
              placement="bottomCenter"
              arrow
              trigger={["click"]}
            >
              <Button className={styles.main_share_button} type="primary">
                <QrcodeOutlined />
              </Button>
            </Dropdown>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default RetroList;
