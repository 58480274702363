import React, { useEffect, useMemo, useState } from "react";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { accountSelector } from "../../../store/account";
import { AppDispatch } from "../../../store";
import {
  changeScrumPokerCards,
  changeScrumPokerDescription,
  changeScrumPokerStatus,
  changeScrumPokerTitle,
  defaultScrumPokerCards,
  getScrumPoker,
  resetScrumPoker,
  scrumSelector,
  voteScrumPoker,
} from "../../../store/scrumPoker";
import { getTeams, teamSelector } from "../../../store/team";
import { Button, Input, Modal, Tooltip, Watermark } from "antd";
import {
  CopyOutlined,
  QuestionOutlined,
  CoffeeOutlined,
  LoadingOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";

const ScrumPokerDashboard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loggedIn } = useSelector(accountSelector);
  const { scrumPokerLoading, scrumPokerRoom } = useSelector(scrumSelector);
  const { teams } = useSelector(teamSelector);
  const { accountInfo } = useSelector(accountSelector);
  const [scrumPokerNewTitle, setScrumPokerNewTitle] = useState("");
  const [scrumPokerNewDescription, setScrumPokerNewDescription] = useState("");
  const [editCardsModalVisible, setEditCardsModalVisible] = useState(false);
  const [cards, setCards] = useState(scrumPokerRoom.cards);

  useEffect(() => {
    const scrumRoomId = localStorage.getItem("scrumPokerRoomId");
    if (loggedIn && scrumRoomId) {
      dispatch(
        getScrumPoker({
          roomId: scrumRoomId,
        })
      );
      dispatch(getTeams());
    }
  }, [loggedIn]);

  const teamName = useMemo(() => {
    const team = teams.find((team) => team._id === scrumPokerRoom?.roomId);
    return team ? team.name : scrumPokerRoom?.roomId;
  }, [teams, scrumPokerRoom?.roomId]);

  const isAdmin = useMemo(() => {
    const team = teams.find((team) => team._id === scrumPokerRoom?.roomId);
    const isAdmin = team?.admin.some(
      (member) => member._id === accountInfo?._id
    );
    const isOwner = scrumPokerRoom?.owner === accountInfo?._id;
    console.log("scrumPokerRoom", scrumPokerRoom);

    console.log(isAdmin, isOwner);

    return isAdmin || isOwner;
  }, [teams, scrumPokerRoom?.roomId, accountInfo?._id, scrumPokerRoom?.owner]);

  const handleTitleChange = (value: any) => {
    setScrumPokerNewTitle(value);
  };

  const handleDescriptionChange = (value: any) => {
    setScrumPokerNewDescription(value);
  };

  useEffect(() => {
    if (scrumPokerRoom.title) {
      setScrumPokerNewTitle(scrumPokerRoom.title);
    }
    if (scrumPokerRoom.description) {
      setScrumPokerNewDescription(scrumPokerRoom.description);
    }
    if (scrumPokerRoom.cards) {
      setCards(scrumPokerRoom.cards);
    }
  }, [scrumPokerRoom.title, scrumPokerRoom.description, scrumPokerRoom.cards]);

  const handleCardChange = (value: string, index: number) => {
    const newCards = cards.map((card, i) => {
      if (i === index) {
        return { ...card, value: value };
      }
      return card;
    });
    setCards(newCards);
  };

  const addCard = () => {
    setCards([...cards, { value: "", description: "" }]);
  };

  const removeCard = (index: number) => {
    const newCards = cards.filter((_, i) => i !== index);
    setCards(newCards);
  };

  const saveChanges = () => {
    dispatch(changeScrumPokerCards({ roomId: scrumPokerRoom.roomId, cards }));
    setEditCardsModalVisible(false);
  };

  return (
    <div className={styles.main}>
      {scrumPokerLoading ? (
        <div className={styles.main_loading}>
          <LoadingOutlined />
        </div>
      ) : (
        <>
          <div className={styles.main_header}>
            <div className={styles.main_header_content}>
              <div className={styles.main_header_content_row}>
                <span className={styles.main_header_content_row_title}>
                  Room Id
                </span>
                <Input
                  className={styles.main_header_content_row_input}
                  style={{
                    border: "1px solid #e0e0e0",
                    boxShadow: "none",
                  }}
                  value={teamName}
                  readOnly
                  suffix={
                    scrumPokerRoom?.roomId ? (
                      <Tooltip title="Copy Room Id">
                        <CopyOutlined
                          onClick={() => {
                            navigator.clipboard.writeText(
                              scrumPokerRoom?.roomId
                            );
                            toast.success("Room Id copied to clipboard");
                          }}
                        />
                      </Tooltip>
                    ) : null
                  }
                />
              </div>
              <div className={styles.main_header_content_row}>
                <span className={styles.main_header_content_row_title}>
                  Title
                </span>
                <Input
                  className={styles.main_header_content_row_input}
                  style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
                  placeholder="room title"
                  value={scrumPokerNewTitle}
                  onChange={(e) => handleTitleChange(e.target.value)}
                  allowClear
                  onBlur={() => {
                    scrumPokerNewTitle &&
                      dispatch(
                        changeScrumPokerTitle({
                          roomId: scrumPokerRoom.roomId,
                          title: scrumPokerNewTitle,
                        })
                      );
                  }}
                  onPressEnter={() => {
                    scrumPokerNewTitle &&
                      dispatch(
                        changeScrumPokerTitle({
                          roomId: scrumPokerRoom.roomId,
                          title: scrumPokerNewTitle,
                        })
                      );
                  }}
                  disabled={!isAdmin}
                />
              </div>
              <div className={styles.main_header_content_row}>
                <span className={styles.main_header_content_row_title}>
                  Description
                </span>
                <Input
                  className={styles.main_header_content_row_input}
                  style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
                  placeholder="room description"
                  value={scrumPokerNewDescription}
                  onChange={(e) => handleDescriptionChange(e.target.value)}
                  allowClear
                  onBlur={() => {
                    scrumPokerNewDescription &&
                      dispatch(
                        changeScrumPokerDescription({
                          roomId: scrumPokerRoom.roomId,
                          description: scrumPokerNewDescription,
                        })
                      );
                  }}
                  onPressEnter={() => {
                    scrumPokerNewDescription &&
                      dispatch(
                        changeScrumPokerDescription({
                          roomId: scrumPokerRoom.roomId,
                          description: scrumPokerNewDescription,
                        })
                      );
                  }}
                  disabled={!isAdmin}
                />
              </div>
            </div>
            <div className={styles.main_header_cardArea}>
              {scrumPokerRoom.cards.map((card, index) => (
                <div
                  key={index}
                  className={styles.main_card}
                  onClick={() => {
                    dispatch(
                      voteScrumPoker({
                        roomId: scrumPokerRoom.roomId,
                        value: card.value,
                      })
                    );
                  }}
                >
                  <div className={styles.main_header_cardArea_logo}>Retro</div>
                  <div className={styles.main_header_cardArea_content}>
                    {card.value === "question" ? (
                      <QuestionOutlined
                        style={{
                          fontSize: "1.2rem",
                          color: "#767162",
                        }}
                      />
                    ) : card.value === "coffee" ? (
                      <CoffeeOutlined
                        style={{
                          fontSize: "1.2rem",
                          color: "#767162",
                        }}
                      />
                    ) : (
                      card.value
                    )}
                  </div>
                  <div className={styles.main_header_cardArea_logo}>Retro</div>
                </div>
              ))}
              <Tooltip title="Edit Cards" placement="top">
                <div
                  className={styles.main_card}
                  style={{
                    backgroundColor: "#F25D5A",
                    cursor: isAdmin ? "pointer" : "not-allowed",
                  }}
                  onClick={() => {
                    isAdmin && setEditCardsModalVisible(true);
                  }}
                >
                  <div
                    className={styles.main_header_cardArea_logo}
                    style={{
                      color: "#fff",
                      opacity: 0.8,
                    }}
                  >
                    Retro
                  </div>
                  <div className={styles.main_header_cardArea_content}>
                    <EditOutlined
                      style={{
                        color: "#fff",
                      }}
                    />
                  </div>
                  <div
                    className={styles.main_header_cardArea_logo}
                    style={{
                      color: "#fff",
                      opacity: 0.8,
                    }}
                  >
                    Retro
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
          <div className={styles.main_content}>
            <div className={styles.main_content_header}>
              <Button
                type="primary"
                className={styles.main_content_header_button}
                disabled={!isAdmin}
                onClick={() => {
                  isAdmin &&
                    dispatch(
                      resetScrumPoker({
                        roomId: scrumPokerRoom.roomId,
                        onSuccess: () => {
                          !scrumPokerRoom.locked &&
                            dispatch(
                              changeScrumPokerStatus({
                                roomId: scrumPokerRoom.roomId,
                                locked: true,
                              })
                            );
                        },
                      })
                    );
                }}
              >
                Reset
              </Button>
              <Button
                type="primary"
                className={styles.main_content_header_button}
                disabled={!isAdmin}
                onClick={() => {
                  isAdmin &&
                    dispatch(
                      changeScrumPokerStatus({
                        roomId: scrumPokerRoom.roomId,
                        locked: !scrumPokerRoom.locked,
                      })
                    );
                }}
              >
                {scrumPokerRoom.locked ? "Show" : "Hide"}
              </Button>
            </div>
            <div className={styles.main_content_result}>
              <div className={styles.main_content_result_row}>
                <span className={styles.main_content_result_row_title}>
                  Name
                </span>
                <span className={styles.main_content_result_row_title}>
                  Story Point
                </span>
              </div>
              {scrumPokerRoom.votes.map((vote, index) => (
                <div key={index} className={styles.main_content_result_row}>
                  <span className={styles.main_content_result_row_user}>
                    {vote.user.nickname
                      ? vote.user.nickname
                      : vote.user.username}
                  </span>
                  <span className={styles.main_content_result_row_title}>
                    <div className={styles.main_content_result_card}>
                      {scrumPokerRoom.locked ? (
                        <>
                          {vote.user._id === accountInfo?._id ? (
                            <>
                              {vote.value === "question" ? (
                                <QuestionOutlined
                                  style={{
                                    fontSize: "1.2rem",
                                    color: "#767162",
                                  }}
                                />
                              ) : vote.value === "coffee" ? (
                                <CoffeeOutlined
                                  style={{
                                    fontSize: "1.2rem",
                                    color: "#767162",
                                  }}
                                />
                              ) : (
                                vote.value
                              )}
                            </>
                          ) : (
                            <FontAwesomeIcon icon={faUserSecret} />
                          )}
                        </>
                      ) : (
                        <>
                          {vote.value === "question" ? (
                            <QuestionOutlined
                              style={{
                                fontSize: "1.2rem",
                                color: "#767162",
                              }}
                            />
                          ) : vote.value === "coffee" ? (
                            <CoffeeOutlined
                              style={{
                                fontSize: "1.2rem",
                                color: "#767162",
                              }}
                            />
                          ) : (
                            vote.value
                          )}
                        </>
                      )}
                    </div>
                  </span>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {editCardsModalVisible && (
        <Modal
          title="Edit Cards"
          open={editCardsModalVisible}
          onCancel={() => {
            setEditCardsModalVisible(false);
            setCards(scrumPokerRoom.cards);
          }}
          onClose={() => {
            setEditCardsModalVisible(false);
            setCards(scrumPokerRoom.cards);
          }}
          footer={null}
          style={{ top: 30 }}
        >
          <div className={styles.main_modal}>
            {cards.map((card, index) => (
              <div key={index} className={styles.main_modal_row}>
                <Input
                  className={styles.main_modal_row_input}
                  style={{
                    border: "1px solid #e0e0e0",
                    boxShadow: "none",
                  }}
                  value={card.value}
                  onChange={(e) => handleCardChange(e.target.value, index)}
                  allowClear
                  disabled={
                    card.value === "question" ||
                    card.value === "coffee" ||
                    card.value === "∞"
                  }
                />
                <Tooltip title="Edit Value" placement="right">
                  <Button
                    type="primary"
                    className={styles.main_modal_row_button}
                    style={{ width: "40px" }}
                    onClick={() => removeCard(index)}
                    disabled={
                      card.value === "question" ||
                      card.value === "coffee" ||
                      card.value === "∞"
                    }
                  >
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
              </div>
            ))}
            <Button
              key={"add"}
              type="primary"
              className={styles.main_modal_row_button}
              onClick={addCard}
              disabled={cards.length >= 14}
            >
              Add Card
            </Button>
            <Button
              key={"save"}
              type="primary"
              className={styles.main_modal_row_button}
              onClick={saveChanges}
            >
              Save
            </Button>
            <Button
              type="primary"
              className={styles.main_modal_row_button}
              onClick={() => {
                dispatch(
                  defaultScrumPokerCards({ roomId: scrumPokerRoom.roomId })
                );
              }}
            >
              Default Get Cards
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ScrumPokerDashboard;
