// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.layout_main_logo__Hfwlu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.layout_main_logo_slideIn__DRBUn {
  animation: layout_slideIn__LGY2e 2s infinite;
}
@keyframes layout_slideIn__LGY2e {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/layout/index.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,aAAA;AAAJ;AACI;EACE,4CAAA;AACN;AAAM;EACE;IACE,UAAA;EAER;EAAM;IACE,UAAA;EAER;EAAM;IACE,UAAA;EAER;EAAM;IACE,UAAA;EAER;EAAM;IACE,UAAA;EAER;AACF","sourcesContent":[".main {\n  &_logo {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100vh;\n    &_slideIn {\n      animation: slideIn 2s infinite;\n      @keyframes slideIn {\n        0% {\n          opacity: 0;\n        }\n        25% {\n          opacity: 1;\n        }\n        50% {\n          opacity: 1;\n        }\n        75% {\n          opacity: 0;\n        }\n        100% {\n          opacity: 0;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_logo": `layout_main_logo__Hfwlu`,
	"main_logo_slideIn": `layout_main_logo_slideIn__DRBUn`,
	"slideIn": `layout_slideIn__LGY2e`
};
export default ___CSS_LOADER_EXPORT___;
