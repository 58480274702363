import axios, { AxiosInstance } from "axios";
import endpoints from "./endpoints";

class AuthService {
  public http: AxiosInstance;

  constructor() {
    this.http = axios.create({
      baseURL: endpoints.baseUrl,
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.http.interceptors.request.use(
      (config) => {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
          config.headers["Authorization"] = `Bearer ${accessToken}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.http.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          this.logout();
        }
        return Promise.reject(error);
      }
    );
  }

  public get(url: string) {
    return this.http.get(url);
  }

  public post(url: string, data: any) {
    return this.http.post(url, data);
  }

  public put(url: string, data: any) {
    return this.http.put(url, data);
  }

  logout() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("chatIds");
    window.location.href = "/";
  }
}

const authService = new AuthService();
export const http = authService.http;
export default authService;
