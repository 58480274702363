export enum ScrumPokerTypes {
  GET_SCRUM_POKER_ROOM_ID = "GET_SCRUM_POKER_ROOM_ID",
  CREATE_SCRUM_POKER_ROOM = "CREATE_SCRUM_POKER_ROOM",
  IS_SCRUM = "IS_SCRUM",
  GET_SCRUM_POKER = "GET_SCRUM_POKER",
  VOTE_SCRUM_POKER = "VOTE_SCRUM_POKER",
  SOCKET_SCRUM_POKER = "SOCKET_SCRUM_POKER",
  RESET_SCRUM_POKER = "RESET_SCRUM_POKER",
  CHANGE_SCRUM_POKER_STATUS = "CHANGE_SCRUM_POKER_STATUS",
  CHANGE_SCRUM_POKER_TITLE = "CHANGE_SCRUM_POKER_TITLE",
  CHANGE_SCRUM_POKER_DESCRIPTION = "CHANGE_SCRUM_POKER_DESCRIPTION",
  CHANGE_SCRUM_POKER_CARDS = "CHANGE_SCRUM_POKER_CARDS",
  DEFAULT_SCRUM_POKER_CARDS = "DEFAULT_SCRUM_POKER_CARDS",
}

export interface IScrumPokerState {
  getRoomIdLoading: boolean;
  roomId: string;
  scrumPokerRoom: ScrumPokerRoom;
  scrumPokerLoading: boolean;
  scrumPokerVoteLoading: boolean;
}

interface ScrumPokerRoom {
  owner: string;
  roomId: string;
  title: string;
  description: string;
  locked: boolean;
  createdAt: string;
  url: string;
  cards: ScrumPokerCard[];
  votes: ScrumPokerVotes[];
}

interface ScrumPokerCard {
  value: string;
  description: string;
}

interface ScrumPokerVotes {
  user: {
    username: string;
    nickname: string;
    _id: string;
  };
  value: string;
}
