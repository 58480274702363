import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../index";

export const selectAction = (state: RootState) => state.account;

export const accountSelector = createSelector(
  selectAction,
  (account) => account
);
