import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "../index";

export const selectAction = (state: RootState) => state.conversations;

export const conversationsSelector = createSelector(
  selectAction,
  (conversations) => conversations
);
