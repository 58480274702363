import React, { useState } from "react";
import styles from "./index.module.scss";
import { Button, Form, Input, Popover } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import Logo from "../../../components/Logo";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import { accountSelector, resetPassword } from "../../../store/account";

const ForgotPassword = () => {
  const { changePasswordLoading } = useSelector(accountSelector);
  const { token } = useParams<{ token?: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordLongEnough, setIsPasswordLongEnough] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumberCase, setHasNumberCase] = useState(false);

  const onPasswordChange = (e: any) => {
    const value = e.target.value;
    setNewPassword(value);
    setIsPasswordLongEnough(value.length >= 6);
    setHasUpperCase(/[A-Z]/.test(value));
    setHasNumberCase(/\d/.test(value));
  };

  const passwordCriteria = (
    <div className={styles.password_criteria}>
      <div>
        {isPasswordLongEnough ? (
          <CheckCircleOutlined
            style={{ color: "#00a35c", paddingRight: "10px" }}
          />
        ) : (
          <CloseCircleOutlined
            style={{ color: "#f45d5a", paddingRight: "10px" }}
          />
        )}
        <span>At least 6 characters</span>
      </div>
      <div>
        {hasUpperCase ? (
          <CheckCircleOutlined
            style={{ color: "#00a35c", paddingRight: "10px" }}
          />
        ) : (
          <CloseCircleOutlined
            style={{ color: "#f45d5a", paddingRight: "10px" }}
          />
        )}
        <span>Contains uppercase letter</span>
      </div>
      <div>
        {hasNumberCase ? (
          <CheckCircleOutlined
            style={{ color: "#00a35c", paddingRight: "10px" }}
          />
        ) : (
          <CloseCircleOutlined
            style={{ color: "#f45d5a", paddingRight: "10px" }}
          />
        )}
        <span>Contains number</span>
      </div>
    </div>
  );

  const onFinish = () => {
    if (token) {
      dispatch(
        resetPassword({
          token,
          password: newPassword,
          onSuccess() {
            navigate("/login");
          },
        })
      );
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.main_content}>
        <div className={styles.main_content_form}>
          <span className={styles.main_content_form_title}>Reset Password</span>
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item label="New Password">
              <Popover
                content={passwordCriteria}
                placement="left"
                trigger="focus"
              >
                <Input.Password
                  className={styles.main_content_form_input}
                  style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
                  placeholder="Password"
                  allowClear
                  value={newPassword}
                  onChange={onPasswordChange}
                />
              </Popover>
            </Form.Item>
            <Form.Item label="Confirm Password">
              <Input.Password
                className={styles.main_content_form_input}
                placeholder="Confirm Password"
                allowClear
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                style={
                  confirmPassword.length > 0
                    ? newPassword === confirmPassword
                      ? {
                          backgroundColor: "transparent",
                          border: "1px solid  #e0e0e0",
                          boxShadow: "none",
                        }
                      : {
                          backgroundColor: "#f45d5a30",
                          border: "1px solid  #e0e0e0",
                          boxShadow: "none",
                        }
                    : {
                        backgroundColor: "transparent",
                        border: "1px solid #e0e0e0",
                        boxShadow: "none",
                      }
                }
              />
            </Form.Item>
            <Form.Item>
              <Button
                className={styles.main_content_form_button}
                type="primary"
                htmlType="submit"
                disabled={
                  !isPasswordLongEnough ||
                  !hasUpperCase ||
                  !hasNumberCase ||
                  newPassword !== confirmPassword
                }
                loading={changePasswordLoading}
              >
                Reset Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className={styles.main_footer}>
        <Logo size="large" />
      </div>
    </div>
  );
};

export default ForgotPassword;
