import { createAsyncThunk } from "@reduxjs/toolkit";
import { TeamActionTypes } from "./types";
import axios from "axios";
import endpoints from "../../services/endpoints";
import authService, { http } from "../../services/authService";
import { toast } from "react-toastify";
import { RootState } from "../index";
import { updateNotification } from "../notification";

interface CreateTeamPayload {
  name: string;
  description: string;
  image: File;
  onSuccess: () => void;
}

export const createTeam = createAsyncThunk(
  TeamActionTypes.CREATE_TEAM,
  async (payload: CreateTeamPayload, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("name", payload.name);
    formData.append("description", payload.description);
    if (payload.image) {
      formData.append("teamImage", payload.image, payload.image.name);
    }

    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.createTeam}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTeams = createAsyncThunk(
  TeamActionTypes.GET_TEAMS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `${endpoints.baseUrl}${endpoints.getTeams}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface IsUserPayload {
  email: string;
  onSuccess: (message: string) => void;
  onError: (message: string) => void;
}

export const isUser = createAsyncThunk(
  TeamActionTypes.IS_USER,
  async (payload: IsUserPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.isUser}`,
        { user: payload.email }
      );
      payload.onSuccess(response.data.userId);
      return response.data;
    } catch (error: any) {
      payload.onError(error.response.data.message);
      toast.error(error.response.data.error);
      return rejectWithValue(error.response.data);
    }
  }
);

interface AddMemberPayload {
  user: string;
  teamId: string;
  onSuccess: () => void;
  onError: (message: string) => void;
}

export const addMemberToTeam = createAsyncThunk(
  TeamActionTypes.ADD_MEMBER,
  async (payload: AddMemberPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.addMember}`,
        { user: payload.user, teamId: payload.teamId }
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      payload.onError(error.response.data.error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const getInvitations = createAsyncThunk(
  TeamActionTypes.GET_INVITATIONS,
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await http.get(
        `${endpoints.baseUrl}${endpoints.getInvitations}`
      );
      const state = getState() as RootState;
      const unreadTeamRequestIds = state.notification.notifications
        .filter(
          (notification) =>
            notification.type === "teamRequest" && !notification.isRead
        )
        .map((notification) => notification._id);

      dispatch(updateNotification(unreadTeamRequestIds));

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface AcceptInvitationPayload {
  teamId: string;
  onSuccess: () => void;
}

export const acceptInvitation = createAsyncThunk(
  TeamActionTypes.ACCEPT_INVITATION,
  async (payload: AcceptInvitationPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.acceptInvitation}`,
        { teamId: payload.teamId }
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface RejectInvitationPayload {
  teamId: string;
  onSuccess: () => void;
}

export const rejectInvitation = createAsyncThunk(
  TeamActionTypes.REJECT_INVITATION,
  async (payload: RejectInvitationPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.rejectInvitation}`,
        { teamId: payload.teamId }
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface DeleteTeamPayload {
  teamId: string;
  onSuccess: () => void;
  onError: (message: string) => void;
}

export const deleteTeam = createAsyncThunk(
  TeamActionTypes.DELETE_TEAM,
  async (payload: DeleteTeamPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.deleteTeam}`,
        { teamId: payload.teamId }
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      payload.onError(error.response.data.error);
      return rejectWithValue(error.response.data);
    }
  }
);

interface RemoveMemberPayload {
  teamId: string;
  userId: string;
  onSuccess: () => void;
}

export const removeMember = createAsyncThunk(
  TeamActionTypes.REMOVE_MEMBER,
  async (payload: RemoveMemberPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.removeMember}`,
        { teamId: payload.teamId, userId: payload.userId }
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface LeaveTeamPayload {
  teamId: string;
  onSuccess: () => void;
}

export const leaveTeam = createAsyncThunk(
  TeamActionTypes.LEAVE_TEAM,
  async (payload: LeaveTeamPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.leaveTeam}`,
        { teamId: payload.teamId }
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface RemoveRequestPayload {
  teamId: string;
  userId: string;
  onSuccess: () => void;
}

export const removeRequest = createAsyncThunk(
  TeamActionTypes.REMOVE_REQUEST,
  async (payload: RemoveRequestPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.removeRequest}`,
        { teamId: payload.teamId, userId: payload.userId }
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface UpdateTeamPayload {
  teamId: string;
  name: string;
  description: string;
  image: File;
  admins: string[];
  onSuccess: () => void;
}

export const updateTeam = createAsyncThunk(
  TeamActionTypes.TEAM_UPDATE,
  async (payload: UpdateTeamPayload, { rejectWithValue }) => {
    const formData = new FormData();
    formData.append("teamId", payload.teamId);
    formData.append("name", payload.name);
    formData.append("description", payload.description);
    formData.append("admins", payload.admins.join(","));
    if (payload.image) {
      formData.append("teamImage", payload.image, payload.image.name);
    }

    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.updateTeam}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      payload.onSuccess();
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface SearchTeamsPayload {
  search: string;
}

export const searchTeams = createAsyncThunk(
  TeamActionTypes.SEARCH_TEAMS,
  async (payload: SearchTeamsPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.searchTeams}`,
        { search: payload.search }
      );
      if (response.data.length === 0) {
        toast.info("Oops! No teams found...");
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const searchInvitations = createAsyncThunk(
  TeamActionTypes.SEARCH_INVITATIONS,
  async (payload: SearchTeamsPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.searchInvitations}`,
        { search: payload.search }
      );
      if (response.data.length === 0) {
        toast.info("Oops! No invitations found...");
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
