import React from "react";
import styles from "./index.module.scss";
import { LogoProps } from "./types";

const Logo = ({ size }: LogoProps) => {
  const retroStyle = (size: "extraSmall" | "small" | "medium" | "large") => {
    switch (size) {
      case "extraSmall":
        return {
          fontSize: "0.5rem",
        };
      case "small":
        return {
          fontSize: "1.5rem",
        };
      case "medium":
        return {
          fontSize: "2.5rem",
        };
      case "large":
        return {
          fontSize: "3.5rem",
        };
      default:
        return {
          fontSize: "1rem",
        };
    }
  };

  const thinkStyle = (size: "extraSmall" | "small" | "medium" | "large") => {
    switch (size) {
      case "extraSmall":
        return {
          fontSize: "0.1rem",
        };
      case "small":
        return {
          fontSize: "1rem",
        };
      case "medium":
        return {
          fontSize: "1.5rem",
        };
      case "large":
        return {
          fontSize: "2.5rem",
        };
      default:
        return {
          fontSize: "0.5rem",
        };
    }
  };

  return (
    <div
      className={styles.main}
      onClick={() => {
        window.location.href = "/";
      }}
    >
      <div style={retroStyle(size)} className={styles.main_retro}>
        RETRO
      </div>
      <div style={thinkStyle(size)} className={styles.main_think}>
        think
      </div>
    </div>
  );
};

export default Logo;
