import React from "react";
import styles from "./index.module.scss";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className={styles.main}>
      <div className={styles.main_copy}>
        <span>{currentYear} RetroThink.All rights reserved.</span>
      </div>
    </div>
  );
};

export default Footer;
