import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { UIActionTypes } from "./types";
import axios from "axios";
import endpoints from "../../services/endpoints";
import authService, { http } from "../../services/authService";

export const setCollapsed = createAction<boolean>(UIActionTypes.SET_COLLAPSED);

export const setActivePage = createAction<string>(UIActionTypes.ACTIVE_PAGE);

export const setActiveChatUserIds = createAction<string[]>(
  UIActionTypes.ACTIVE_CHAT_USER
);

export const deleteChatUser = createAction<string>(
  UIActionTypes.DELETE_CHAT_USER
);

export const setExportRetro = createAction<boolean>(UIActionTypes.EXPORT_RETRO);
