import { Notification, NotificationState } from "./types";
import { createReducer } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getNotifications,
  socketAddNotification,
  updateNotification,
  deleteNotification,
  markRead,
} from "./actions";

const initialState: NotificationState = {
  notifications: [
    {
      _id: "",
      user: "",
      team: {
        _id: "",
        name: "",
        description: "",
      },
      content: "",
      type: "",
      url: "",
      isRead: false,
      createdAt: "",
    },
  ],
};

export const notificationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getNotifications.pending, (state) => {
      state.notifications = [];
    })
    .addCase(getNotifications.rejected, (state) => {
      toast.error("Failed to get notifications");
    })
    .addCase(getNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload as Notification[];
    })
    .addCase(socketAddNotification.fulfilled, (state, action) => {
      state.notifications = [action.payload, ...state.notifications];
    })
    .addCase(updateNotification, (state, action) => {
      state.notifications = state.notifications.map((notification) =>
        action.payload.includes(notification._id)
          ? { ...notification, isRead: true }
          : notification
      );
    })
    .addCase(deleteNotification.fulfilled, (state, action) => {
      state.notifications = state.notifications.filter(
        (notification) => notification._id !== action.payload.notificationId
      );
    })
    .addCase(markRead.fulfilled, (state, action) => {
      state.notifications = state.notifications.map((notification) =>
        action.payload.notificationId.includes(notification._id)
          ? { ...notification, isRead: true }
          : notification
      );
    });
});
