import { List, Modal, Button } from "antd";
import styles from "./index.module.scss";
import React from "react";
import { Team, TeamUsers } from "../../../../store/team/types";
import endpoints from "../../../../services/endpoints";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store";
import { removeMember } from "../../../../store/team";

interface RemoveMemberModalProps {
  visible: boolean;
  onClose: () => void;
  selectedTeam?: Team;
}

const RemoveMemberModal = ({
  visible,
  onClose,
  selectedTeam,
}: RemoveMemberModalProps) => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <Modal
      title="Remove Member"
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <List
        dataSource={selectedTeam?.members}
        itemLayout="horizontal"
        renderItem={(item: TeamUsers) => {
          const initialChars =
            item.firstName && item.lastName
              ? item.firstName[0].toUpperCase() + item.lastName[0].toUpperCase()
              : item.username
              ? item.username[0].toUpperCase() + item.username[1].toUpperCase()
              : "NN";

          const isAdmin = selectedTeam?.admin.some(
            (admin) => admin._id === item._id
          );

          return (
            <List.Item
              actions={[
                !isAdmin && (
                  <Button
                    danger
                    onClick={() => {
                      dispatch(
                        removeMember({
                          teamId: selectedTeam?._id as string,
                          userId: item._id,
                          onSuccess: () => {
                            onClose();
                          },
                        })
                      );
                    }}
                  >
                    Remove
                  </Button>
                ),
              ].filter(Boolean)}
            >
              <List.Item.Meta
                avatar={
                  <div className={styles.main_avatar}>
                    {item.image ? (
                      <img
                        src={`${endpoints.baseUrl}/${item.image}`}
                        alt={item.firstName || item.username}
                      />
                    ) : (
                      <div className={styles.main_avatar_text}>
                        {initialChars}
                      </div>
                    )}
                  </div>
                }
                title={
                  item.firstName
                    ? `${item.firstName} ${item.lastName}`
                    : item.username
                }
                description={item.email || "No email provided"}
              />
            </List.Item>
          );
        }}
      />
    </Modal>
  );
};

export default RemoveMemberModal;
