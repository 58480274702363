export enum TeamActionTypes {
  CREATE_TEAM = "CREATE_TEAM",
  GET_TEAMS = "GET_TEAMS",
  IS_USER = "IS_USER",
  ADD_MEMBER = "ADD_MEMBER",
  GET_INVITATIONS = "GET_INVITATIONS",
  ACCEPT_INVITATION = "ACCEPT_INVITATION",
  REJECT_INVITATION = "REJECT_INVITATION",
  DELETE_TEAM = "DELETE_TEAM",
  REMOVE_MEMBER = "REMOVE_MEMBER",
  LEAVE_TEAM = "LEAVE_TEAM",
  REMOVE_REQUEST = "REMOVE_REQUEST",
  TEAM_UPDATE = "TEAM_UPDATE",
  SEARCH_TEAMS = "SEARCH_TEAMS",
  SEARCH_INVITATIONS = "SEARCH_INVITATIONS",
}

export interface TeamState {
  createTeamLoading: boolean;
  getTeamsLoading: boolean;
  addMemberLoading: boolean;
  invitationsLoading: boolean;
  isSearchResults: boolean;
  teams: Team[];
  invitations: Invitations[];
}

export interface TeamUsers {
  _id: string;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  image: string;
}

export interface Team {
  _id: string;
  name: string;
  description: string;
  image: string;
  members: TeamUsers[];
  admin: TeamUsers[];
  requests: TeamUsers[];
  createdAt: string;
}

export interface Invitations {
  _id: string;
  name: string;
  description: string;
  image: string;
  admin: TeamUsers[];
  createdAt: string;
}
