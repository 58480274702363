import React, { useEffect, useState } from "react";
import Logo from "../../../components/Logo";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { accountSelector, verifyEmail } from "../../../store/account";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch } from "../../../store";
import { LoadingOutlined } from "@ant-design/icons";

const EmailVerify = () => {
  const { verifyEmailLoading } = useSelector(accountSelector);
  const { token } = useParams<{ token?: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (token) {
      dispatch(
        verifyEmail({
          token,
          onSuccess() {
            setMessage("Your email has been verified. You can now login...");
            setTimeout(() => {
              navigate("/");
            }, 2000);
          },
          onError() {
            setMessage("Invalid link please try again sending email...");
            setTimeout(() => {
              navigate("/");
            }, 2000);
          },
        })
      );
    }
  }, [token]);

  return (
    <div className={styles.main}>
      <div className={styles.main_content}>
        {verifyEmailLoading ? (
          <div className={styles.main_loading}>
            <LoadingOutlined style={{ color: "#f35d5a" }} />
          </div>
        ) : (
          <div className={styles.main_content_form}>{message}</div>
        )}
      </div>
      <div className={styles.main_footer}>
        <Logo size="large" />
      </div>
    </div>
  );
};

export default EmailVerify;
