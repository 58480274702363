import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import endpoints from "../../services/endpoints";
import authService, { http } from "../../services/authService";
import { ConversationTypes } from "./types";
import { RootState } from "../index";

export const getConversations = createAsyncThunk(
  ConversationTypes.GET_CONVERSATIONS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `${endpoints.baseUrl}${endpoints.getConversations}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface getMessagesPayload {
  receiverId: string;
}

export const getMessages = createAsyncThunk(
  ConversationTypes.GET_MESSAGES,
  async (
    payload: getMessagesPayload,
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const state = getState() as RootState;
      const account = state.account;
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.getMessages}`,
        { receiverId: payload.receiverId }
      );
      const userId = account.accountInfo?._id;
      const chatId = [userId, payload.receiverId].sort().join("-");

      if (!response.data.length) {
        return [{ chatId, messages: "" }];
      } else {
        return response.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface SendMessagePayload {
  receiverId: string;
  message: string;
}

export const sendMessage = createAsyncThunk(
  ConversationTypes.SEND_MESSAGE,
  async (payload: SendMessagePayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.sendMessage}`,
        { receiverId: payload.receiverId, message: payload.message }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface SocketAddMessagePayload {
  _id: string;
  senderId: string;
  receiverId: string;
  message: string;
  chatId: string;
  createdAt: string;
  isRead: boolean;
}

export const socketAddMessage = createAsyncThunk(
  ConversationTypes.SOCKET_ADD_MESSAGE,
  async (payload: SocketAddMessagePayload, { rejectWithValue }) => {
    try {
      return payload;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface MarkMessageReadPayload {
  chatId: string;
}

export const markMessageRead = createAsyncThunk(
  ConversationTypes.MARK_MESSAGE_READ,
  async (payload: MarkMessageReadPayload, { rejectWithValue }) => {
    try {
      return payload;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface ReadConversationPayload {
  receiverId: string;
}

export const readConversation = createAsyncThunk(
  ConversationTypes.READ_CONVERSATION,
  async (payload: ReadConversationPayload, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${endpoints.baseUrl}${endpoints.readConversation}`,
        { receiverId: payload.receiverId }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
