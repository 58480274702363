import { createReducer } from "@reduxjs/toolkit";
import { UIState } from "./types";
import {
  setCollapsed,
  setActivePage,
  setActiveChatUserIds,
  deleteChatUser,
  setExportRetro,
} from "./actions";

const initialState: UIState = {
  collapsed: false,
  activePage: "",
  activeChatUser: [],
  exportRetro: false,
};

export const uiReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCollapsed, (state, action) => {
      state.collapsed = action.payload;
    })
    .addCase(setActivePage, (state, action) => {
      state.activePage = action.payload;
    })
    .addCase(setActiveChatUserIds, (state, action) => {
      const userIds = action.payload;
      userIds.forEach((userId) => {
        if (!state.activeChatUser.includes(userId)) {
          state.activeChatUser.push(userId);
        }
      });
    })
    .addCase(deleteChatUser, (state, action) => {
      state.activeChatUser = state.activeChatUser.filter(
        (userId) => userId !== action.payload
      );
    })
    .addCase(setExportRetro, (state, action) => {
      state.exportRetro = action.payload;
    });
});
