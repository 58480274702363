import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import Logo from "../Logo";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptFriendRequest,
  accountSelector,
  changeNickname,
  deleteFriend,
  deleteFriendRequest,
  resendVerificationEmail,
} from "../../store/account";
import {
  TeamOutlined,
  MailOutlined,
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
  NotificationOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Badge, Button, Dropdown, Input, Menu, MenuProps, Tooltip } from "antd";
import { AppDispatch } from "../../store";
import { logout } from "../../store/account";
import {
  deleteNotification,
  getNotifications,
  markRead,
  notificationSelector,
} from "../../store/notification";
import { setActiveChatUserIds, setActivePage } from "../../store/ui";
import endpoints from "../../services/endpoints";
import AddFriendModal from "./AddFriendModal";
import {
  conversationsSelector,
  getConversations,
  markMessageRead,
  readConversation,
} from "../../store/conversations";
import { toast } from "react-toastify";
import UpdateProfile from "./UpdateProfile/UpdateProfile";

const Header = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { accountInfo, loggedIn } = useSelector(accountSelector);
  const { notifications } = useSelector(notificationSelector);
  const { conversations } = useSelector(conversationsSelector);
  const [friendDropdownVisible, setFriendDropdownVisible] = useState(false);
  const [profileDropdownVisible, setProfileDropdownVisible] = useState(false);
  const [addFriendModalVisible, setAddFriendModalVisible] = useState(false);
  const [messageDropdownVisible, setMessageDropdownVisible] = useState(false);
  const [updateProfileModalVisible, setUpdateProfileModalVisible] =
    useState(false);
  const [nickname, setNickname] = useState(accountInfo?.nickname);

  useEffect(() => {
    if (loggedIn) {
      dispatch(
        getNotifications({
          onSuccess: () => {},
          onError: () => {},
        })
      );
      dispatch(getConversations());
    }
  }, [dispatch]);

  const handleLogout = () => {
    dispatch(logout());
  };
  const formatMessage = (message: string) => {
    if (message.length > 70) {
      return message.substring(0, 70) + "...";
    }
    return message;
  };

  const notificationItems = notifications.map((notification) => {
    const handleNotificationClick = () => {
      localStorage.removeItem("retroId");

      const teamId = notification.url.split("/").slice(-1)[0];
      localStorage.setItem("teamId", teamId);

      switch (notification.type) {
        case "teamRequest":
          dispatch(setActivePage("Team Invitations"));
          localStorage.setItem("menuChildrenKey", "Team Invitations");
          break;
        case "friendRequest":
          setFriendDropdownVisible(true);
          break;
        case "teamPromotion":
          dispatch(setActivePage("My Teams"));
          localStorage.setItem("menuChildrenKey", "My Teams");
          break;
        case "retroCreated":
          window.location.replace(notification.url);
          break;
        default:
          break;
      }

      dispatch(
        markRead({
          notificationId: notification._id,
          onSuccess: () => {},
          onError: () => {},
        })
      );
    };

    const handleDeleteNotification = (e: any) => {
      e.stopPropagation();
      dispatch(
        deleteNotification({
          notificationId: notification._id,
          onSuccess: () => {},
          onError: () => {},
        })
      );
    };
    return {
      key: notification._id,
      label: (
        <div className={styles.main_notificationItem}>
          <span
            className={styles.main_notificationItem_content}
            onClick={handleNotificationClick}
          >
            {notification.content}
          </span>
          <div onMouseDown={handleDeleteNotification}>
            <CloseCircleOutlined />
          </div>
        </div>
      ),
      icon: (
        <InfoCircleOutlined
          style={{
            color: notification.isRead ? "black" : "red",
          }}
        />
      ),
    };
  });

  // const notificationItems = notifications.map((notification) => ({
  //   key: notification._id,
  //   label: (
  //     <div className={styles.main_notificationItem}>
  //       <span
  //         className={styles.main_notificationItem_content}
  //         onClick={() => {
  //           if (localStorage.getItem("retroId")) {
  //             localStorage.removeItem("retroId");
  //           }
  //           notification.type === "teamRequest" &&
  //             dispatch(setActivePage("Team Invitations"));
  //           localStorage.setItem("menuChildrenKey", "Team Invitations");

  //           localStorage.setItem(
  //             "teamId",
  //             notification.url.split("/").slice(-1)[0]
  //           );
  //           notification.type === "friendRequest" &&
  //             setFriendDropdownVisible(true);
  //           dispatch(
  //             markRead({
  //               notificationId: notification._id,
  //               onSuccess: () => {},
  //               onError: () => {},
  //             })
  //           );
  //           notification.type === "teamPromotion" &&
  //             dispatch(setActivePage("My Teams"));
  //           localStorage.setItem("menuChildrenKey", "My Teams");
  //           localStorage.setItem(
  //             "teamId",
  //             notification.url.split("/").slice(-1)[0]
  //           );
  //           notification.type === "retroCreated" &&
  //             dispatch(
  //               markRead({
  //                 notificationId: notification._id,
  //                 onSuccess: () => {},
  //                 onError: () => {},
  //               })
  //             );
  //           window.location.replace(notification.url);
  //         }}
  //       >
  //         {notification.content}
  //       </span>
  //       <div
  //         onMouseDown={(e) => {
  //           e.stopPropagation();
  //           dispatch(
  //             deleteNotification({
  //               notificationId: notification._id,
  //               onSuccess: () => {},
  //               onError: () => {},
  //             })
  //           );
  //         }}
  //       >
  //         <CloseCircleOutlined />
  //       </div>
  //     </div>
  //   ),
  //   icon: (
  //     <InfoCircleOutlined
  //       style={{
  //         color: notification.isRead ? "black" : "red",
  //       }}
  //     />
  //   ),
  // }));

  const notificationMenu = <Menu items={notificationItems} />;

  const menu = (
    <div className={styles.main_logged_profileDropdown}>
      <div className={styles.main_logged_profileDropdown_avatarArea}>
        <div className={styles.main_logged_profileDropdown_avatarArea_avatar}>
          {accountInfo?.image ? (
            <img src={`${endpoints.baseUrl}/${accountInfo.image}`} alt="" />
          ) : (
            <>
              {accountInfo?.firstName && accountInfo?.lastName
                ? accountInfo.firstName[0].toUpperCase() +
                  accountInfo.lastName[0].toUpperCase()
                : accountInfo?.username
                ? accountInfo.username[0].toUpperCase() +
                  accountInfo.username[1]?.toUpperCase()
                : "UN"}
            </>
          )}
        </div>
      </div>
      <div className={styles.main_logged_profileDropdown_username}>
        {accountInfo?.firstName
          ? accountInfo.firstName + " " + accountInfo?.lastName
          : accountInfo?.username}
      </div>
      {!accountInfo?.isVerified && (
        <div className={styles.main_logged_profileDropdown_items}>
          <div className={styles.main_logged_profileDropdown_items_icon}>
            <SendOutlined
              style={{
                color: "#f35c5ae0",
              }}
            />
          </div>
          <div
            className={styles.main_logged_profileDropdown_items_text}
            style={{
              color: "#f35c5ae0",
            }}
            onClick={() => {
              dispatch(
                resendVerificationEmail({
                  user: accountInfo?.email,
                  onSuccess: (message) => {
                    toast.success(message);
                  },
                })
              );
              setProfileDropdownVisible(false);
            }}
          >
            Resend Verification Email
          </div>
        </div>
      )}

      <div
        className={styles.main_logged_profileDropdown_items}
        onClick={() => {
          setUpdateProfileModalVisible(true);
          setProfileDropdownVisible(false);
        }}
      >
        <div className={styles.main_logged_profileDropdown_items_icon}>
          <SettingOutlined />
        </div>
        <div className={styles.main_logged_profileDropdown_items_text}>
          Profile Settings
        </div>
      </div>
      <div
        className={styles.main_logged_profileDropdown_items}
        onClick={handleLogout}
      >
        <div className={styles.main_logged_profileDropdown_items_icon}>
          <LogoutOutlined />
        </div>
        <div className={styles.main_logged_profileDropdown_items_text}>
          Logout
        </div>
      </div>
    </div>
  );

  const friendMenu = (
    <div className={styles.main_logged_friendArea}>
      <div className={styles.main_logged_friendArea_section}>
        <div className={styles.main_logged_friendArea_section_title}>
          Friend Requests
        </div>
        <div className={styles.main_logged_friendArea_section_itemsArea}>
          {accountInfo?.receiveFriendRequests.map((friend) => (
            <div
              className={styles.main_logged_friendArea_section_itemsArea_item}
              key={friend._id}
            >
              <div className={styles.main_logged_friendArea_section_avatar}>
                {friend.image ? (
                  <img src={`${endpoints.baseUrl}/${friend.image}`} alt="" />
                ) : (
                  friend.username[0]?.toUpperCase() +
                  friend.username[1]?.toUpperCase()
                )}
              </div>
              <div className={styles.main_logged_friendArea_section_username}>
                {friend.firstName
                  ? friend.firstName + " " + friend.lastName
                  : friend.username}
              </div>
              <div className={styles.main_logged_friendArea_section_actions}>
                <Tooltip title="Accept">
                  <div
                    className={
                      styles.main_logged_friendArea_section_actions_icon
                    }
                    onClick={() => {
                      dispatch(
                        acceptFriendRequest({
                          userId: friend._id,
                          onSuccess: () => {},
                        })
                      );
                    }}
                  >
                    <CheckCircleOutlined style={{ color: "#01b503" }} />
                  </div>
                </Tooltip>
                <Tooltip title="Reject">
                  <div
                    className={
                      styles.main_logged_friendArea_section_actions_icon
                    }
                  >
                    <CloseCircleOutlined style={{ color: "#fd271f" }} />
                  </div>
                </Tooltip>
              </div>
            </div>
          ))}
          {accountInfo?.receiveFriendRequests.length === 0 && (
            <div
              className={styles.main_logged_friendArea_section_itemsArea_item}
            >
              No friend requests
            </div>
          )}
        </div>
      </div>
      <div className={styles.main_logged_friendArea_section}>
        <div className={styles.main_logged_friendArea_section_title}>
          Friends
        </div>
        <div className={styles.main_logged_friendArea_section_itemsArea}>
          {accountInfo?.friends.map((friend) => (
            <div
              className={styles.main_logged_friendArea_section_itemsArea_item}
              key={friend._id}
            >
              <div className={styles.main_logged_friendArea_section_avatar}>
                {friend.image ? (
                  <img src={`${endpoints.baseUrl}/${friend.image}`} alt="" />
                ) : (
                  friend.username[0]?.toUpperCase() +
                  friend.username[1]?.toUpperCase()
                )}
              </div>
              <div className={styles.main_logged_friendArea_section_username}>
                {friend.firstName
                  ? friend.firstName + " " + friend.lastName
                  : friend.username}
              </div>
              <div className={styles.main_logged_friendArea_section_actions}>
                <Tooltip title="Delete">
                  <div
                    className={
                      styles.main_logged_friendArea_section_actions_icon
                    }
                    onClick={() => {
                      dispatch(
                        deleteFriend({
                          userId: friend._id,
                          onSuccess: () => {},
                        })
                      );
                    }}
                  >
                    <CloseCircleOutlined style={{ color: "#fd271f" }} />
                  </div>
                </Tooltip>
                <Tooltip title="Message">
                  <div
                    className={
                      styles.main_logged_friendArea_section_actions_icon
                    }
                    onClick={() => {
                      const chatIds = JSON.parse(
                        localStorage.getItem("chatIds") || "[]"
                      );
                      const userId = friend._id;

                      if (!chatIds.includes(userId)) {
                        chatIds.push(userId);
                        localStorage.setItem(
                          "chatIds",
                          JSON.stringify(chatIds)
                        );
                      }
                      dispatch(setActiveChatUserIds([userId]));
                    }}
                  >
                    <SendOutlined />
                  </div>
                </Tooltip>
              </div>
            </div>
          ))}
          {accountInfo?.friends.length === 0 && (
            <div
              className={styles.main_logged_friendArea_section_itemsArea_item}
            >
              Not friends yet
            </div>
          )}
        </div>
      </div>
      <div className={styles.main_logged_friendArea_section}>
        <div className={styles.main_logged_friendArea_section_title}>
          Sent Requests
        </div>
        <div className={styles.main_logged_friendArea_section_itemsArea}>
          {accountInfo?.sendFriendRequests.map((friend) => (
            <div
              className={styles.main_logged_friendArea_section_itemsArea_item}
              key={friend._id}
            >
              <div className={styles.main_logged_friendArea_section_avatar}>
                {friend.image ? (
                  <img src={`${endpoints.baseUrl}/${friend.image}`} alt="" />
                ) : (
                  friend.username[0]?.toUpperCase() +
                  friend.username[1]?.toUpperCase()
                )}
              </div>
              <div className={styles.main_logged_friendArea_section_username}>
                {friend.firstName
                  ? friend.firstName + " " + friend.lastName
                  : friend.username}
              </div>
              <div className={styles.main_logged_friendArea_section_actions}>
                <Tooltip title="Cancel">
                  <div
                    className={
                      styles.main_logged_friendArea_section_actions_icon
                    }
                    onClick={() => {
                      dispatch(
                        deleteFriendRequest({
                          userId: friend._id,
                          onSuccess: () => {},
                        })
                      );
                    }}
                  >
                    <CloseCircleOutlined style={{ color: "#fd271f" }} />
                  </div>
                </Tooltip>
              </div>
            </div>
          ))}
          {accountInfo?.sendFriendRequests.length === 0 && (
            <div
              className={styles.main_logged_friendArea_section_itemsArea_item}
            >
              No sent requests
            </div>
          )}
          <Button
            type="primary"
            className={styles.main_logged_button}
            onClick={() => {
              setAddFriendModalVisible(true);
              setFriendDropdownVisible(false);
            }}
          >
            Add Friend
          </Button>
        </div>
      </div>
    </div>
  );

  const conversationsMenu = (
    <div className={styles.main_conversationsMenu}>
      {conversations.map((conversation) => (
        <Badge dot={!conversation.isRead} offset={[-6, 6]}>
          <div
            className={styles.main_conversationsMenu_item}
            key={conversation._id}
            onClick={() => {
              const chatIds = JSON.parse(
                localStorage.getItem("chatIds") || "[]"
              );
              const userId = conversation.participants.filter(
                (participant) => participant._id !== accountInfo?._id
              )[0]._id;

              if (!chatIds.includes(userId)) {
                chatIds.push(userId);
                localStorage.setItem("chatIds", JSON.stringify(chatIds));
              }
              dispatch(setActiveChatUserIds([userId]));
              setMessageDropdownVisible(false);
              dispatch(markMessageRead({ chatId: conversation.chatId }));
              dispatch(readConversation({ receiverId: userId }));
            }}
          >
            <div className={styles.main_conversationsMenu_avatar}>
              {conversation.participants.filter(
                (participant) => participant._id !== accountInfo?._id
              )[0]?.image ? (
                <img
                  src={`${endpoints.baseUrl}/${
                    conversation.participants.filter(
                      (participant) => participant._id !== accountInfo?._id
                    )[0].image
                  }`}
                  alt=""
                />
              ) : (
                conversation.participants
                  .filter(
                    (participant) => participant._id !== accountInfo?._id
                  )[0]
                  ?.username[0]?.toUpperCase() +
                conversation.participants
                  .filter(
                    (participant) => participant._id !== accountInfo?._id
                  )[0]
                  ?.username[1]?.toUpperCase()
              )}
            </div>
            <div className={styles.main_conversationsMenu_content}>
              <div className={styles.main_conversationsMenu_content_username}>
                {
                  conversation.participants.filter(
                    (participant) => participant._id !== accountInfo?._id
                  )[0]?.username
                }
              </div>
              <div
                className={styles.main_conversationsMenu_content_lastMessage}
              >
                {conversation.lastMessage.senderId === accountInfo?._id && (
                  <span>You: </span>
                )}
                {formatMessage(conversation.lastMessage.message)}
              </div>
            </div>
          </div>
        </Badge>
      ))}
      {conversations.length === 0 && (
        <Button
          type="primary"
          className={styles.main_logged_button}
          onClick={() => {
            setFriendDropdownVisible(true);
            setMessageDropdownVisible(false);
          }}
        >
          Start a conversation
        </Button>
      )}
    </div>
  );

  const handleChangeNickname = () => {
    dispatch(
      changeNickname({
        nickname: nickname ? nickname : "",
        onSuccess: () => {},
      })
    );
  };

  return (
    <div className={styles.main}>
      <div>
        <Logo size="small" />
      </div>
      {loggedIn ? (
        <div className={styles.main_logged}>
          <Tooltip
            title={
              accountInfo?.nickname
                ? "Click to change nickname"
                : "Set a nickname"
            }
          >
            <Input
              className={styles.main_input}
              style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
              placeholder="nickname"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
              onPressEnter={handleChangeNickname}
              onBlur={handleChangeNickname}
            />
          </Tooltip>
          <Dropdown
            overlay={friendMenu}
            trigger={["click"]}
            placement="bottomRight"
            visible={friendDropdownVisible}
            onVisibleChange={(visible) => setFriendDropdownVisible(visible)}
          >
            <Badge count={accountInfo?.receiveFriendRequests.length}>
              <div className={styles.main_logged_icon}>
                <TeamOutlined />
              </div>
            </Badge>
          </Dropdown>
          <Dropdown
            overlay={conversationsMenu}
            trigger={["click"]}
            placement="bottomRight"
            visible={messageDropdownVisible}
            onVisibleChange={(visible) => setMessageDropdownVisible(visible)}
          >
            <Badge
              dot={conversations.some((conversation) => !conversation.isRead)}
            >
              <div className={styles.main_logged_icon}>
                <MailOutlined />
              </div>
            </Badge>
          </Dropdown>
          <Badge
            count={
              notifications.filter((notification) => !notification.isRead)
                .length
            }
          >
            <Dropdown
              overlay={notificationMenu}
              trigger={["click"]}
              overlayStyle={{
                maxHeight: 400,
                overflow: "auto",
                boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
              }}
            >
              <div className={styles.main_logged_icon}>
                <NotificationOutlined />
              </div>
            </Dropdown>
          </Badge>
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            placement="bottomRight"
            visible={profileDropdownVisible}
            onVisibleChange={(visible) => setProfileDropdownVisible(visible)}
          >
            <Badge dot={!accountInfo?.isVerified}>
              <div className={styles.main_logged_icon}>
                <UserOutlined />
              </div>
            </Badge>
          </Dropdown>
        </div>
      ) : (
        <div className={styles.main_notLogged}>
          <div
            className={styles.main_notLogged_item}
            onClick={() => {
              window.location.href = "/login";
            }}
          >
            <span>Login</span>
          </div>
          <div
            className={styles.main_notLogged_item}
            onClick={() => {
              window.location.href = "/register";
            }}
          >
            <span>Register</span>
          </div>
        </div>
      )}
      {addFriendModalVisible && (
        <AddFriendModal
          visible={addFriendModalVisible}
          onClose={() => {
            setAddFriendModalVisible(false);
          }}
        />
      )}
      {updateProfileModalVisible && (
        <UpdateProfile
          visible={updateProfileModalVisible}
          onClose={() => setUpdateProfileModalVisible(false)}
        />
      )}
    </div>
  );
};

export default Header;
