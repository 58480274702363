import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import { accountSelector } from "../../../store/account";
import {
  createRetro,
  getRetroTemplates,
  retroSelector,
  RetroTemplate,
} from "../../../store/retro";
import { Button, Form, Input, Select, Switch, Tooltip } from "antd";
import {
  LoadingOutlined,
  DoubleRightOutlined,
  LockOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { getTeams, teamSelector } from "../../../store/team";

const CreateRetro = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loggedIn } = useSelector(accountSelector);
  const { retroTemplateLoading, retroTemplates } = useSelector(retroSelector);
  const { teams } = useSelector(teamSelector);
  const [selectedTemplate, setSelectedTemplate] = useState<RetroTemplate>();

  const [isBlink, setIsBlink] = useState(false);
  const [retroName, setretroName] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);
  const [teamId, setTeamId] = useState("");
  const [retroStatus, setRetroStatus] = useState(false);
  const [retroOnShow, setRetroOnShow] = useState(false);
  const [finishedAt, setFinishedAt] = useState("");

  useEffect(() => {
    if (loggedIn) {
      dispatch(getRetroTemplates());
      dispatch(getTeams());
    }
  }, [loggedIn]);

  const onFinish = () => {
    dispatch(
      createRetro({
        templateId: selectedTemplate?._id as string,
        retroPrivate: isPrivate,
        teamId: teamId ? teamId : "",
        retroName: retroName,
        status: retroStatus ? "active" : "inactive",
        finishedAt: finishedAt ? finishedAt : "",
        onShow: retroOnShow,
        onSucces: (data) => {
          setretroName("");
          setIsPrivate(false);
          setTeamId("");
          setRetroStatus(false);
          setRetroOnShow(false);
          setFinishedAt("");
          setSelectedTemplate(undefined);
          window.location.href = data;
        },
      })
    );
  };

  return (
    <div className={styles.main}>
      <div className={styles.main_section}>
        <div className={styles.main_section_title}>Create Retro</div>
        <div className={styles.main_create}>
          <Form layout="vertical" style={{ width: "100%" }} onFinish={onFinish}>
            <Form.Item>
              <Input
                className={styles.main_create_input}
                style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
                type="text"
                placeholder="Retro Name"
                allowClear
                onChange={(e) => setretroName(e.target.value)}
                value={retroName}
                required
              />
            </Form.Item>
            <Form.Item>
              <Switch
                checkedChildren="Private"
                unCheckedChildren="Public"
                checked={isPrivate}
                onChange={(checked) => setIsPrivate(checked)}
                style={{
                  backgroundColor: isPrivate ? "#f35d5a" : "#e0e0e0",
                  width: "20%",
                }}
              />
            </Form.Item>
            {isPrivate && (
              <Form.Item>
                <Select
                  placeholder="Select Team"
                  allowClear
                  className={styles.main_create_input}
                  style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
                  onChange={(value) => setTeamId(value as string)}
                >
                  {teams.map((team) => (
                    <Select.Option key={team._id} value={team._id}>
                      {team.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item>
              <Input
                className={styles.main_create_inputSelect}
                style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
                type="text"
                placeholder="Select Template"
                allowClear
                value={selectedTemplate?.templateName}
                onFocus={() => setIsBlink(true)}
                onBlur={() => {
                  if (!selectedTemplate?.templateName) {
                    setIsBlink(false);
                  }
                }}
                required
                onChange={(e) => {
                  if (e.target.value === "") {
                    setSelectedTemplate(undefined);
                  }
                }}
                onKeyDown={(e) => {
                  if (
                    ![
                      "ArrowLeft",
                      "ArrowRight",
                      "ArrowUp",
                      "ArrowDown",
                      "Tab",
                    ].includes(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item>
              <Switch
                checkedChildren="Show"
                unCheckedChildren="Hide"
                checked={retroOnShow}
                onChange={(checked) => setRetroOnShow(checked)}
                style={{
                  backgroundColor: retroOnShow ? "#f35d5a" : "#e0e0e0",
                  width: "20%",
                }}
              />
            </Form.Item>
            <Form.Item>
              <Switch
                checkedChildren="Active"
                unCheckedChildren="Inactive"
                checked={retroStatus}
                onChange={(checked) => setRetroStatus(checked)}
                style={{
                  backgroundColor: retroStatus ? "#f35d5a" : "#e0e0e0",
                  width: "20%",
                }}
              />
            </Form.Item>
            <Form.Item>
              <Input
                className={styles.main_create_input}
                style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
                type="number"
                placeholder="Duration (in minutes)"
                allowClear
                value={finishedAt}
                onChange={(e) => setFinishedAt(e.target.value)}
                disabled={!retroStatus}
              />
            </Form.Item>
            <Button
              className={styles.main_create_button}
              type="primary"
              htmlType="submit"
            >
              Create Retro
            </Button>
          </Form>
        </div>
      </div>
      <div className={styles.main_section}>
        <div className={styles.main_section_title}>Retro Templates</div>
        {retroTemplateLoading ? (
          <div className={styles.main_loading}>
            <LoadingOutlined />
          </div>
        ) : (
          <div className={styles.main_section_content}>
            {retroTemplates.map((template) => (
              <div
                key={template._id}
                className={
                  isBlink
                    ? styles.main_section_content_item_blink
                    : styles.main_section_content_item
                }
                onClick={() => {
                  if (selectedTemplate?._id === template._id) {
                    setSelectedTemplate(undefined);
                  } else {
                    setSelectedTemplate(template);
                  }
                }}
                style={
                  selectedTemplate?._id === template._id
                    ? { backgroundColor: "#f35d5a", color: "white" }
                    : {}
                }
              >
                <div className={styles.main_section_content_item_text}>
                  {template.templateName}
                </div>
                <DoubleRightOutlined />
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={styles.main_section}>
        <div className={styles.main_section_title}>Retro Description</div>
        {retroTemplateLoading ? (
          <div className={styles.main_loading}>
            <LoadingOutlined />
          </div>
        ) : (
          <div className={styles.main_section_description}>
            {selectedTemplate?.columns.map((column) => (
              <div
                key={column._id}
                className={styles.main_section_description_item}
              >
                <div className={styles.main_section_description_item_title}>
                  {column.name}
                </div>
                <div className={styles.main_section_description_item_text}>
                  {column.description}
                </div>
              </div>
            ))}
            {selectedTemplate && (
              <div className={styles.main_section_description_item}>
                <div className={styles.main_section_description_item_title}>
                  Purpose
                </div>
                <div
                  className={styles.main_section_description_item_text}
                  style={{
                    whiteSpace: "pre-wrap",
                    fontWeight: "400",
                  }}
                >
                  {selectedTemplate?.purpose}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateRetro;
