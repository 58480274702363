import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRetro, isRetro, retroSelector } from "../../store/retro";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch } from "../../store";
import { LoadingOutlined } from "@ant-design/icons";
import styles from "./index.module.scss";
import Logo from "../../components/Logo";
import { toast } from "react-toastify";
import { isScrum } from "../../store/scrumPoker";

interface RedirectProps {
  type: "retro" | "scrum";
}

const Redirect = ({ type }: RedirectProps) => {
  const { id } = useParams<{ id?: string }>();

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (id && type === "retro") {
      dispatch(
        isRetro({
          retroId: id,
          onSuccess(retroId: string) {
            localStorage.setItem("menuChildrenKey", "Retro Dashboard");
            localStorage.setItem("retroId", retroId);
            setMessage("Redirecting to your retro...");
            setTimeout(() => {
              navigate(`/`);
            }, 2000);
          },
          onError() {
            toast.error("You do not have access to this retro...");
            setMessage("Redirecting to home page...");
            localStorage.setItem("menuChildrenKey", "My Retros");
            setTimeout(() => {
              navigate(`/`);
            }, 2000);
          },
        })
      );
    } else if (id && type === "scrum") {
      dispatch(
        isScrum({
          roomId: id,
          onSuccess(roomId: string) {
            localStorage.setItem("menuChildrenKey", "Scrum Poker Dashboard");
            localStorage.setItem("scrumPokerRoomId", roomId);
            setMessage("Redirecting to your scrum poker...");
            setTimeout(() => {
              navigate(`/`);
            }, 2000);
          },
          onError() {
            toast.error("You do not have access to this scrum poker...");
            setMessage("Redirecting to home page...");
            localStorage.setItem("menuChildrenKey", "Scrum Poker");
            setTimeout(() => {
              navigate(`/`);
            }, 2000);
          },
        })
      );
    }
  }, [id, dispatch, navigate]);

  return (
    <div className={styles.main}>
      <div className={styles.main_content}>
        <div className={styles.main_content_form}>{message}</div>
      </div>
      <div className={styles.main_footer}>
        <Logo size="large" />
      </div>
    </div>
  );
};

export default Redirect;
