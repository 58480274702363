import { ScrumPokerTypes, IScrumPokerState } from "./types";
import { createReducer } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getScrumPokerRoomId,
  createScrumPokerRoom,
  getScrumPoker,
  voteScrumPoker,
  socketUpdateScrumPoker,
} from "./actions";

const initialState: IScrumPokerState = {
  getRoomIdLoading: false,
  roomId: "",
  scrumPokerLoading: false,
  scrumPokerVoteLoading: false,
  scrumPokerRoom: {
    owner: "",
    roomId: "",
    title: "",
    description: "",
    locked: false,
    createdAt: "",
    url: "",
    cards: [
      {
        value: "",
        description: "",
      },
    ],
    votes: [
      {
        user: {
          username: "",
          _id: "",
          nickname: "",
        },
        value: "",
      },
    ],
  },
};

export const scrumPokerReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getScrumPokerRoomId.pending, (state) => {
      state.getRoomIdLoading = true;
    })
    .addCase(getScrumPokerRoomId.rejected, (state) => {
      state.getRoomIdLoading = false;
      toast.error("Please create a room first");
    })
    .addCase(getScrumPokerRoomId.fulfilled, (state, action) => {
      state.getRoomIdLoading = false;
      state.roomId = action.payload.roomId;
    })
    .addCase(createScrumPokerRoom.pending, (state) => {
      state.getRoomIdLoading = true;
    })
    .addCase(createScrumPokerRoom.rejected, (state) => {
      state.getRoomIdLoading = false;
      toast.error("Room creation failed");
    })
    .addCase(createScrumPokerRoom.fulfilled, (state, action) => {
      state.getRoomIdLoading = false;
      state.roomId = action.payload.roomId;
    })
    .addCase(getScrumPoker.pending, (state) => {
      state.scrumPokerLoading = true;
    })
    .addCase(getScrumPoker.rejected, (state) => {
      state.scrumPokerLoading = false;
      toast.error("Room not found");
    })
    .addCase(getScrumPoker.fulfilled, (state, action) => {
      state.scrumPokerLoading = false;
      state.scrumPokerRoom = action.payload;
    })
    .addCase(socketUpdateScrumPoker, (state, action) => {
      const scrumPokerRoomId = state.scrumPokerRoom.roomId;
      if (scrumPokerRoomId === action.payload.scrumPoker.roomId) {
        state.scrumPokerRoom = action.payload.scrumPoker;
      }
    });
});
