import { createReducer } from "@reduxjs/toolkit";
import { AccountState } from "./types";
import {
  register,
  login,
  forgotPassword,
  getUserInfo,
  addFriend,
  deleteFriendRequest,
  socketAddFriendRequest,
  acceptFriendRequest,
  rejectFriendRequest,
  deleteFriend,
  getFriends,
  updatePassword,
  updateProfile,
  changeMail,
  resetPassword,
  verifyEmail,
  changeNickname,
} from "./actions";
import { toast } from "react-toastify";

const initialState: AccountState = {
  registerLoading: false,
  loginLoading: false,
  forgotPasswordLoading: false,
  accountInfoLoading: false,
  friendLoading: false,
  loggedIn: false,
  changePasswordLoading: false,
  updateProfileLoading: false,
  updateMailLoading: false,
  verifyEmailLoading: false,
  accountInfo: {
    _id: "",
    username: "",
    firstName: "",
    lastName: "",
    email: "",
    role: "",
    isVerified: false,
    gender: "",
    birthdate: "",
    nickname: "",
    friends: [
      {
        _id: "",
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        image: "",
      },
    ],
    sendFriendRequests: [
      {
        _id: "",
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        image: "",
      },
    ],
    receiveFriendRequests: [
      {
        _id: "",
        username: "",
        firstName: "",
        lastName: "",
        email: "",
        image: "",
      },
    ],
    image: "",
    accessToken: "",
  },
};

export const accountReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(register.pending, (state) => {
      state.registerLoading = true;
    })
    .addCase(register.rejected, (state) => {
      state.registerLoading = false;
      toast.error("Registration failed");
    })
    .addCase(register.fulfilled, (state, action) => {
      state.registerLoading = false;
      localStorage.setItem("accessToken", action.payload.accessToken);
    })
    .addCase(login.pending, (state) => {
      state.loginLoading = true;
    })
    .addCase(login.rejected, (state) => {
      state.loginLoading = false;
      toast.error("Login failed");
    })
    .addCase(login.fulfilled, (state, action) => {
      state.loginLoading = false;
      localStorage.setItem("accessToken", action.payload.accessToken);
    })
    .addCase(forgotPassword.pending, (state) => {
      state.forgotPasswordLoading = true;
    })
    .addCase(forgotPassword.rejected, (state) => {
      state.forgotPasswordLoading = false;
      toast.error("Forgot password failed");
    })
    .addCase(forgotPassword.fulfilled, (state) => {
      state.forgotPasswordLoading = false;
    })
    .addCase(getUserInfo.pending, (state) => {
      state.accountInfoLoading = true;
    })
    .addCase(getUserInfo.rejected, (state) => {
      state.accountInfoLoading = false;
      state.loggedIn = false;
      localStorage.removeItem("accessToken");
      toast.error("Failed to get user info");
    })
    .addCase(getUserInfo.fulfilled, (state, action) => {
      state.accountInfoLoading = false;
      state.loggedIn = true;
      state.accountInfo = action.payload;
    })
    .addCase(addFriend.pending, (state) => {
      state.friendLoading = true;
    })
    .addCase(addFriend.rejected, (state) => {
      state.friendLoading = false;
    })
    .addCase(addFriend.fulfilled, (state, action) => {
      state.friendLoading = false;
      if (state.accountInfo) {
        state.accountInfo.sendFriendRequests.push(action.payload.friendData);
      }
    })
    .addCase(deleteFriendRequest.pending, (state) => {
      state.friendLoading = true;
    })
    .addCase(deleteFriendRequest.rejected, (state) => {
      state.friendLoading = false;
    })
    .addCase(deleteFriendRequest.fulfilled, (state, action) => {
      state.friendLoading = false;
      if (state.accountInfo) {
        state.accountInfo.sendFriendRequests =
          state.accountInfo.sendFriendRequests.filter(
            (user) => user._id !== action.payload.friendId
          );
      }
    })
    .addCase(socketAddFriendRequest.fulfilled, (state, action) => {
      if (state.accountInfo) {
        state.accountInfo.receiveFriendRequests.push(action.payload);
      }
    })
    .addCase(acceptFriendRequest.pending, (state) => {
      state.friendLoading = true;
    })
    .addCase(acceptFriendRequest.rejected, (state) => {
      state.friendLoading = false;
    })
    .addCase(acceptFriendRequest.fulfilled, (state, action) => {
      state.friendLoading = false;
      if (state.accountInfo) {
        state.accountInfo.friends.push(action.payload.friendData);
        state.accountInfo.receiveFriendRequests =
          state.accountInfo.receiveFriendRequests.filter(
            (user) => user._id !== action.payload.friendData._id
          );
      }
    })
    .addCase(rejectFriendRequest.pending, (state) => {
      state.friendLoading = true;
    })
    .addCase(rejectFriendRequest.rejected, (state) => {
      state.friendLoading = false;
    })
    .addCase(rejectFriendRequest.fulfilled, (state, action) => {
      state.friendLoading = false;
      if (state.accountInfo) {
        state.accountInfo.receiveFriendRequests =
          state.accountInfo.receiveFriendRequests.filter(
            (user) => user._id !== action.payload.friendId
          );
      }
    })
    .addCase(deleteFriend.pending, (state) => {
      state.friendLoading = true;
    })
    .addCase(deleteFriend.rejected, (state) => {
      state.friendLoading = false;
    })
    .addCase(deleteFriend.fulfilled, (state, action) => {
      state.friendLoading = false;
      if (state.accountInfo) {
        state.accountInfo.friends = state.accountInfo.friends.filter(
          (user) => user._id !== action.payload.friendId
        );
      }
    })
    .addCase(getFriends.fulfilled, (state, action) => {
      if (state.accountInfo) {
        state.accountInfo.friends = action.payload.friends;
        state.accountInfo.sendFriendRequests =
          action.payload.sendFriendRequests;
        state.accountInfo.receiveFriendRequests =
          action.payload.receiveFriendRequests;
      }
    })
    .addCase(updatePassword.pending, (state) => {
      state.changePasswordLoading = true;
    })
    .addCase(updatePassword.rejected, (state) => {
      state.changePasswordLoading = false;
    })
    .addCase(updatePassword.fulfilled, (state) => {
      state.changePasswordLoading = false;
    })
    .addCase(updateProfile.pending, (state) => {
      state.updateProfileLoading = true;
    })
    .addCase(updateProfile.rejected, (state) => {
      state.updateProfileLoading = false;
    })
    .addCase(updateProfile.fulfilled, (state) => {
      state.updateProfileLoading = false;
    })
    .addCase(changeMail.pending, (state) => {
      state.updateMailLoading = true;
    })
    .addCase(changeMail.rejected, (state) => {
      state.updateMailLoading = false;
    })
    .addCase(changeMail.fulfilled, (state) => {
      state.updateMailLoading = false;
    })
    .addCase(resetPassword.pending, (state) => {
      state.changePasswordLoading = true;
    })
    .addCase(resetPassword.rejected, (state) => {
      state.changePasswordLoading = false;
    })
    .addCase(resetPassword.fulfilled, (state) => {
      state.changePasswordLoading = false;
    })
    .addCase(verifyEmail.pending, (state) => {
      state.verifyEmailLoading = true;
    })
    .addCase(verifyEmail.rejected, (state) => {
      state.verifyEmailLoading = false;
    })
    .addCase(verifyEmail.fulfilled, (state) => {
      state.verifyEmailLoading = false;
    })
    .addCase(changeNickname.fulfilled, (state, action) => {
      if (state.accountInfo) {
        state.accountInfo.nickname = action.payload.nickname;
      }
    });
});
