// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.Logo_main__SwOhC {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  box-sizing: border-box;
  gap: 0.2rem;
  -webkit-user-select: none; /* Safari için */ /* Firefox için */ /* Internet Explorer/Edge için */
  user-select: none;
  cursor: pointer;
}
.Logo_main_retro__4TMbx {
  font-family: "Alfa Slab One";
  color: #767162;
}
.Logo_main_think__4R\\+LT {
  font-family: "Allura";
  transform: rotate(-20deg);
  margin-bottom: 0.8rem;
  color: #f45d5a;
}`, "",{"version":3,"sources":["webpack://./src/components/Logo/index.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,aAAA;EACA,mBAAA;EACA,qBAAA;EACA,sBAAA;EACA,WAAA;EACA,yBAAA,EAAA,gBAAA,EACA,iBAAA,EACA,gCAAA;EACA,iBAAA;EACA,eAAA;AAEF;AADE;EACE,4BAAA;EACA,cAAA;AAGJ;AADE;EACE,qBAAA;EACA,yBAAA;EACA,qBAAA;EACA,cAAA;AAGJ","sourcesContent":[".main {\n  display: flex;\n  flex-direction: row;\n  align-items: flex-end;\n  box-sizing: border-box;\n  gap: 0.2rem;\n  -webkit-user-select: none; /* Safari için */\n  -moz-user-select: none; /* Firefox için */\n  -ms-user-select: none; /* Internet Explorer/Edge için */\n  user-select: none;\n  cursor: pointer;\n  &_retro {\n    font-family: \"Alfa Slab One\";\n    color: #767162;\n  }\n  &_think {\n    font-family: \"Allura\";\n    transform: rotate(-20deg);\n    margin-bottom: 0.8rem;\n    color: #f45d5a;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Logo_main__SwOhC`,
	"main_retro": `Logo_main_retro__4TMbx`,
	"main_think": `Logo_main_think__4R+LT`
};
export default ___CSS_LOADER_EXPORT___;
