import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { accountSelector } from "../../../store/account";
import { AppDispatch } from "../../../store";
import {
  createRetroTemplate,
  deleteRetroTemplate,
  getRetroTemplates,
  retroSelector,
  RetroTemplate,
} from "../../../store/retro";
import {
  LoadingOutlined,
  DoubleRightOutlined,
  LockOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Tooltip } from "antd";

interface Column {
  id: number;
  name: string;
  description: string;
}

const RetroTemplates = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loggedIn } = useSelector(accountSelector);
  const { retroTemplateLoading, retroTemplates } = useSelector(retroSelector);
  const [selectedTemplate, setSelectedTemplate] = useState<RetroTemplate>();
  const [columns, setColumns] = useState<Column[]>([
    { id: 1, name: "", description: "" },
  ]);
  const [templateName, setTemplateName] = useState("");
  const [purpose, setPurpose] = useState("");

  useEffect(() => {
    if (loggedIn) {
      dispatch(getRetroTemplates());
    }
  }, [loggedIn]);

  const handleColumnChange = (
    index: number,
    key: keyof Column,
    value: string
  ) => {
    const newColumns = [...columns];
    if (key === "name" || key === "description") {
      newColumns[index][key] = value;
    }
    setColumns(newColumns);
  };

  const handleAddColumn = () => {
    setColumns([
      ...columns,
      { id: columns.length + 1, name: "", description: "" },
    ]);
  };

  const handleRemoveColumn = (index: number) => {
    const newColumns = [...columns];
    newColumns.splice(index, 1);
    setColumns(newColumns);
  };

  const onFinish = () => {
    dispatch(
      createRetroTemplate({
        templateName,
        columns,
        purpose,
        onSucces: () => {
          setTemplateName("");
          setPurpose("");
          setColumns([{ id: 1, name: "", description: "" }]);
        },
      })
    );
  };

  const handleDeleteTemplate = (templateId: string) => {
    dispatch(
      deleteRetroTemplate({
        retroTemplateId: templateId,
        onSucces: () => {
          setSelectedTemplate(undefined);
        },
      })
    );
  };

  return (
    <div className={styles.main}>
      <div className={styles.main_section}>
        <div className={styles.main_section_title}>Create Retro Template</div>
        <div className={styles.main_create}>
          <Form layout="vertical" style={{ width: "100%" }} onFinish={onFinish}>
            <Form.Item>
              <Input
                className={styles.main_create_input}
                style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
                type="text"
                placeholder="Template Name"
                allowClear
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Input.TextArea
                className={styles.main_create_inputTextArea}
                style={{
                  border: "1px solid #e0e0e0",
                  boxShadow: "none",
                }}
                placeholder="Purpose"
                allowClear
                value={purpose}
                onChange={(e) => setPurpose(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              {columns.map((column, index) => (
                <div key={column.id} className={styles.main_create_columns}>
                  <div className={styles.main_create_columns_index}>
                    {index + 1}
                  </div>
                  <div className={styles.main_create_columns_item}>
                    <Input
                      className={styles.main_create_input}
                      style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
                      type="text"
                      placeholder="Column Name"
                      allowClear
                      value={column.name}
                      onChange={(e) =>
                        handleColumnChange(index, "name", e.target.value)
                      }
                    />
                    <Input
                      className={styles.main_create_input}
                      style={{
                        border: "1px solid #e0e0e0",
                        boxShadow: "none",
                      }}
                      type="text"
                      placeholder="Column Description"
                      allowClear
                      value={column.description}
                      onChange={(e) =>
                        handleColumnChange(index, "description", e.target.value)
                      }
                    />
                  </div>
                  <Tooltip title="Delete Column">
                    <div
                      className={styles.main_create_columns_icon}
                      onClick={
                        columns.length > 1
                          ? () => handleRemoveColumn(index)
                          : undefined
                      }
                    >
                      <DeleteOutlined />
                    </div>
                  </Tooltip>
                </div>
              ))}
            </Form.Item>
            <Button
              className={styles.main_create_button}
              type="primary"
              onClick={handleAddColumn}
            >
              Add Column
            </Button>
            <Button
              className={styles.main_create_button}
              type="primary"
              loading={retroTemplateLoading}
              htmlType="submit"
            >
              Create Template
            </Button>
          </Form>
        </div>
      </div>
      <div className={styles.main_section}>
        <div className={styles.main_section_title}>Retro Templates</div>
        {retroTemplateLoading ? (
          <div className={styles.main_loading}>
            <LoadingOutlined />
          </div>
        ) : (
          <div className={styles.main_section_content}>
            {retroTemplates.map((template) => (
              <div
                key={template._id}
                className={styles.main_section_content_item}
                onClick={() => {
                  if (selectedTemplate?._id === template._id) {
                    setSelectedTemplate(undefined);
                  } else {
                    setSelectedTemplate(template);
                  }
                }}
                style={
                  selectedTemplate?._id === template._id
                    ? { backgroundColor: "#f35d5a", color: "white" }
                    : {}
                }
              >
                <div className={styles.main_section_content_item_icon}>
                  {template.locked ? (
                    <Tooltip title="Default Template">
                      <LockOutlined />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Delete Template">
                      <CloseCircleOutlined
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteTemplate(template._id);
                        }}
                        style={{
                          color: "#f35d5a",
                        }}
                      />
                    </Tooltip>
                  )}
                </div>
                <div className={styles.main_section_content_item_text}>
                  {template.templateName}
                </div>
                <DoubleRightOutlined />
              </div>
            ))}
          </div>
        )}
      </div>
      <div className={styles.main_section}>
        <div className={styles.main_section_title}>Retro Description</div>
        {retroTemplateLoading ? (
          <div className={styles.main_loading}>
            <LoadingOutlined />
          </div>
        ) : (
          <div className={styles.main_section_description}>
            {selectedTemplate?.columns.map((column) => (
              <div
                key={column._id}
                className={styles.main_section_description_item}
              >
                <div className={styles.main_section_description_item_title}>
                  {column.name}
                </div>
                <div className={styles.main_section_description_item_text}>
                  {column.description}
                </div>
              </div>
            ))}
            {selectedTemplate && (
              <div className={styles.main_section_description_item}>
                <div className={styles.main_section_description_item_title}>
                  Purpose
                </div>
                <div
                  className={styles.main_section_description_item_text}
                  style={{
                    whiteSpace: "pre-wrap",
                    fontWeight: "400",
                  }}
                >
                  {selectedTemplate?.purpose}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RetroTemplates;
