import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import Logo from "../../components/Logo";
import { Button, Form, Input, Popover, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import {
  accountSelector,
  checkEmail,
  checkUsername,
  getUserInfo,
  register,
} from "../../store/account";
import { debounce } from "lodash";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { registerLoading } = useSelector(accountSelector);

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [checkEmailStatus, setCheckEmailStatus] = useState<boolean>(false);
  const [checkEmailResponse, setCheckEmailResponse] = useState<string>("");
  const [checkUsernameStatus, setCheckUsernameStatus] =
    useState<boolean>(false);
  const [checkUsernameResponse, setCheckUsernameResponse] =
    useState<string>("");
  const [isPasswordLongEnough, setIsPasswordLongEnough] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasNumberCase, setHasNumberCase] = useState(false);

  const debouncedCheckEmail = useRef(
    debounce((email) => {
      dispatch(
        checkEmail({
          email,
          onSuccess: () => {},
          onError: () => {},
        })
      );
    }, 1000)
  );

  const debouncedCheckUsername = useRef(
    debounce((username) => {
      dispatch(
        checkUsername({
          username,
          onSuccess: () => {},
          onError: () => {},
        })
      );
    }, 1000)
  );

  useEffect(() => {
    debouncedCheckEmail.current = debounce((email: string) => {
      dispatch(
        checkEmail({
          email,
          onSuccess: (message) => {
            setCheckEmailStatus(true);
            setCheckEmailResponse(message);
          },
          onError: (message) => {
            setCheckEmailStatus(false);
            setCheckEmailResponse(message);
          },
        })
      );
    }, 1000);
  }, [dispatch]);

  useEffect(() => {
    debouncedCheckUsername.current = debounce((username: string) => {
      dispatch(
        checkUsername({
          username,
          onSuccess: (message) => {
            setCheckUsernameStatus(true);
            setCheckUsernameResponse(message);
          },
          onError: (message) => {
            setCheckUsernameStatus(false);
            setCheckUsernameResponse(message);
          },
        })
      );
    }, 1000);
  }, [dispatch]);

  const handleCheckEmail = (email: string) => {
    debouncedCheckEmail.current(email);
  };

  const handleCheckUsername = (username: string) => {
    debouncedCheckUsername.current(username);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\s/g, "");
    setEmail(inputValue);
    handleCheckEmail(inputValue);
  };

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\s/g, "");
    setUsername(inputValue);
    handleCheckUsername(inputValue);
  };

  const onPasswordChange = (e: any) => {
    const value = e.target.value;
    setPassword(value);
    setIsPasswordLongEnough(value.length >= 6);
    setHasUpperCase(/[A-Z]/.test(value));
    setHasNumberCase(/\d/.test(value));
  };

  const passwordCriteria = (
    <div className={styles.password_criteria}>
      <div>
        {isPasswordLongEnough ? (
          <CheckCircleOutlined
            style={{ color: "#00a35c", paddingRight: "10px" }}
          />
        ) : (
          <CloseCircleOutlined
            style={{ color: "#f45d5a", paddingRight: "10px" }}
          />
        )}
        <span>At least 6 characters</span>
      </div>
      <div>
        {hasUpperCase ? (
          <CheckCircleOutlined
            style={{ color: "#00a35c", paddingRight: "10px" }}
          />
        ) : (
          <CloseCircleOutlined
            style={{ color: "#f45d5a", paddingRight: "10px" }}
          />
        )}
        <span>Contains uppercase letter</span>
      </div>
      <div>
        {hasNumberCase ? (
          <CheckCircleOutlined
            style={{ color: "#00a35c", paddingRight: "10px" }}
          />
        ) : (
          <CloseCircleOutlined
            style={{ color: "#f45d5a", paddingRight: "10px" }}
          />
        )}
        <span>Contains number</span>
      </div>
    </div>
  );

  const onFinish = () => {
    dispatch(
      register({
        username,
        email,
        password,
        onSuccess: (message) => {
          setUsername("");
          setEmail("");
          setPassword("");
          setConfirmPassword("");
          toast.success(message);
          navigate("/");
        },
      })
    );
  };

  return (
    <div className={styles.main}>
      <div className={styles.main_content}>
        <div className={styles.main_content_form}>
          <span className={styles.main_content_form_title}>Register</span>
          <Form layout="vertical" onFinish={onFinish}>
            <Form.Item label="Username">
              <Input
                className={styles.main_content_form_input}
                style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
                placeholder="Username"
                allowClear
                value={username}
                onChange={handleUsernameChange}
                suffix={
                  username.length > 0 ? (
                    checkUsernameStatus ? (
                      <Tooltip title={checkUsernameResponse} placement="right">
                        <CheckCircleOutlined style={{ color: "#00a35c" }} />
                      </Tooltip>
                    ) : (
                      <Tooltip title={checkUsernameResponse} placement="right">
                        <CloseCircleOutlined style={{ color: "#f45d5a" }} />
                      </Tooltip>
                    )
                  ) : null
                }
              />
            </Form.Item>
            <Form.Item label="Email">
              <Input
                className={styles.main_content_form_input}
                style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
                placeholder="Email"
                allowClear
                value={email}
                onChange={handleEmailChange}
                suffix={
                  email.length > 0 ? (
                    checkEmailStatus ? (
                      <Tooltip title={checkEmailResponse} placement="right">
                        <CheckCircleOutlined style={{ color: "#00a35c" }} />
                      </Tooltip>
                    ) : (
                      <Tooltip title={checkEmailResponse} placement="right">
                        <CloseCircleOutlined style={{ color: "#f45d5a" }} />
                      </Tooltip>
                    )
                  ) : null
                }
              />
            </Form.Item>
            <Form.Item label="Password">
              <Popover
                content={passwordCriteria}
                placement="left"
                trigger="focus"
              >
                <Input.Password
                  className={styles.main_content_form_input}
                  style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
                  placeholder="Password"
                  allowClear
                  value={password}
                  onChange={onPasswordChange}
                />
              </Popover>
            </Form.Item>
            <Form.Item label="Confirm Password">
              <Input.Password
                className={styles.main_content_form_input}
                placeholder="Confirm Password"
                allowClear
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                style={
                  confirmPassword.length > 0
                    ? password === confirmPassword
                      ? {
                          backgroundColor: "transparent",
                          border: "1px solid  #e0e0e0",
                          boxShadow: "none",
                        }
                      : {
                          backgroundColor: "#f45d5a30",
                          border: "1px solid  #e0e0e0",
                          boxShadow: "none",
                        }
                    : {
                        backgroundColor: "transparent",
                        border: "1px solid #e0e0e0",
                        boxShadow: "none",
                      }
                }
              />
            </Form.Item>
            <Form.Item>
              <Button
                className={styles.main_content_form_button}
                type="primary"
                htmlType="submit"
                loading={registerLoading}
              >
                Register
              </Button>
            </Form.Item>
            <span className={styles.main_content_form_footer}>
              <a href="/login">Already have an account?</a>
            </span>
          </Form>
        </div>
      </div>
      <div className={styles.main_footer}>
        <Logo size="large" />
      </div>
    </div>
  );
};

export default Register;
