import React, { useState } from "react";
import styles from "./index.module.scss";
import { Button, Form, Input, Upload, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { accountSelector } from "../../store/account";
import ImgCrop from "antd-img-crop";
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from "antd/lib/upload/interface";
import { AppDispatch } from "../../store";
import { createTeam, teamSelector } from "../../store/team";
import { setActivePage } from "../../store/ui";

const CreateTeam = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { accountInfo } = useSelector(accountSelector);
  const { createTeamLoading } = useSelector(teamSelector);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [imageFile, setImageFile] = useState<File | null>(null);

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must be smaller than 2MB!");
      return Upload.LIST_IGNORE;
    }

    setImageFile(file);
    return false;
  };

  const onRemove = () => {
    setImageFile(null);
    return true;
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url || "";
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as Blob);
        reader.onload = () => resolve((reader.result as string) || "");
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onFinish = () => {
    dispatch(
      createTeam({
        name,
        description,
        image: imageFile as File,
        onSuccess: () => {
          setName("");
          setDescription("");
          setImageFile(null);
          dispatch(setActivePage("My Teams"));
        },
      })
    );
  };

  const uploadFileList: UploadFile[] = imageFile
    ? [
        {
          uid: "-1",
          name: imageFile.name,
          status: "done",
          url: URL.createObjectURL(imageFile),
          type: imageFile.type,
          size: imageFile.size,
        },
      ]
    : [];

  return (
    <div className={styles.main}>
      <div className={styles.main_form}>
        <Form layout="vertical" onFinish={onFinish} style={{ width: "100%" }}>
          <Form.Item label="Team Name">
            <Input
              className={styles.main_form_input}
              style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
              allowClear
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Item>
          <Form.Item label="Description">
            <Input
              className={styles.main_form_input}
              style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
              allowClear
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Team Lead">
            <Input
              className={styles.main_form_input}
              style={{ border: "1px solid #e0e0e0", boxShadow: "none" }}
              disabled
              placeholder={
                accountInfo?.firstName
                  ? accountInfo?.firstName + " " + accountInfo?.lastName
                  : accountInfo?.username
              }
            />
          </Form.Item>
          <Form.Item label="Team Image">
            <ImgCrop rotationSlider>
              <Upload
                listType="picture-card"
                fileList={uploadFileList}
                onPreview={onPreview}
                beforeUpload={beforeUpload}
                onRemove={onRemove}
              >
                {!imageFile && "+ Upload"}
              </Upload>
            </ImgCrop>
          </Form.Item>
          <Form.Item>
            <Button
              className={styles.main_form_button}
              type="primary"
              htmlType="submit"
              loading={createTeamLoading}
            >
              Create Team
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className={styles.main_image}>
        <img src="/images/createTeam.webp" alt="Create Team" />
      </div>
    </div>
  );
};

export default CreateTeam;
