export enum NotificationActionTypes {
  GET_NOTIFICATIONS = "GET_NOTIFICATIONS",
  SOCKET_ADD_NOTIFICATION = "SOCKET_ADD_NOTIFICATION",
  UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION",
  DELETE_NOTIFICATION = "DELETE_NOTIFICATION",
  MARK_READ = "MARK_READ",
}

export interface NotificationState {
  notifications: Notification[];
}

export interface Notification {
  _id: string;
  user: string;
  team: Team;
  content: string;
  type: string;
  url: string;
  isRead: boolean;
  createdAt: string;
}

interface Team {
  _id: string;
  name: string;
  description: string;
}
