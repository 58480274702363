// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RemoveMemberModal_main__-xkbe {
  display: flex;
}
.RemoveMemberModal_main_avatar__rj1ES {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f0f0f0;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
}
.RemoveMemberModal_main_avatar__rj1ES img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/TeamList/Components/RemoveMemberModal/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,yBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;EACA,uCAAA;EACA,uBAAA;AAEJ;AADI;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;AAGN","sourcesContent":[".main {\n  display: flex;\n  &_avatar {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border: 1px solid #f0f0f0;\n    border-radius: 50%;\n    width: 2rem;\n    height: 2rem;\n    object-fit: cover;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    background-color: white;\n    img {\n      width: 100%;\n      height: 100%;\n      border-radius: 50%;\n      object-fit: cover;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `RemoveMemberModal_main__-xkbe`,
	"main_avatar": `RemoveMemberModal_main_avatar__rj1ES`
};
export default ___CSS_LOADER_EXPORT___;
