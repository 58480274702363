export enum RetroActionTypes {
  GET_RETRO_TEMPLATES = "GET_RETRO_TEMPLATES",
  CREATE_RETRO_TEMPLATE = "CREATE_RETRO_TEMPLATE",
  DELETE_RETRO_TEMPLATE = "DELETE_RETRO_TEMPLATE",
  CREATE_RETRO = "CREATE_RETRO",
  IS_RETRO = "IS_RETRO",
  GET_RETRO = "GET_RETRO",
  SOCKET_UPDATE_RETRO = "SOCKET_UPDATE_RETRO",
  ADD_COLUMN_ITEM = "ADD_COLUMN_ITEM",
  DELETE_COLUMN_ITEM = "DELETE_COLUMN_ITEM",
  UPDATE_COLUMN_ITEM = "UPDATE_COLUMN_ITEM",
  ADD_COMMENT = "ADD_COMMENT",
  UPDATE_COMMENT = "UPDATE_COMMENT",
  DELETE_COMMENT = "DELETE_COMMENT",
  ADD_LIKE = "ADD_LIKE",
  DELETE_LIKE = "DELETE_LIKE",
  ADD_COLUMN = "ADD_COLUMN",
  DELETE_COLUMN = "DELETE_COLUMN",
  ADD_QUESTION = "ADD_QUESTION",
  ADD_CHOICE = "ADD_CHOICE",
  DELETE_QUESTION = "DELETE_QUESTION",
  CHANGE_RETRO_STATUS = "CHANGE_RETRO_STATUS",
  CHANGE_RETRO_ONSHOW = "CHANGE_RETRO_ONSHOW",
  CHANGE_RETRO_FINISH = "CHANGE_RETRO_FINISH",
  GET_RETROS = "GET_RETROS",
  DELETE_MY_RETRO = "DELETE_MY_RETRO",
}

export interface RetroState {
  retroTemplateLoading: boolean;
  retroTemplates: RetroTemplate[];
  retroLoading: boolean;
  retrosLoading: boolean;
  retro: Retro;
  retros: Retro[];
}

export interface RetroTemplate {
  _id: string;
  owner: string;
  templateName: string;
  columns: RetroTepmlateColumn[];
  locked: boolean;
  createdAt: string;
  purpose: string;
}

export interface RetroTepmlateColumn {
  _id: string;
  name: string;
  description: string;
}

export interface Retro {
  _id: string;
  templateId: string;
  retroPrivate: boolean;
  teamId: string;
  retroName: string;
  columns: RetroColumn[];
  createdBy: string;
  status: string;
  finishedAt: string;
  onShow: boolean;
  url: string;
  qrCode: string;
  questions: RetroQuestion[];
  createdAt: string;
  team?: {
    name: string;
    desripion: string;
    image: string;
  };
  template?: {
    purpose: string;
    templateName: string;
  };
}

export interface RetroColumn {
  _id: string;
  name: string;
  description: string;
  items: RetroItem[];
}

export interface RetroItem {
  _id: string;
  text: string;
  createdBy: string;
  createdAt: string;
  likes: RetroLike[];
  unLikes: RetroLike[];
  comments: RetroComment[];
}

export interface RetroLike {
  _id: string;
  createdBy: string;
}

export interface RetroComment {
  _id: string;
  text: string;
  createdBy: string;
  createdAt: string;
}

export interface RetroQuestion {
  _id: string;
  questionText: string;
  createdBy: string;
  choices: RetroChoice[];
}

export interface RetroChoice {
  _id: string;
  choiceText: string;
  createdBy: string;
  createdAt: string;
  answers: RetroAnswer[];
}

export interface RetroAnswer {
  createdBy: string;
}
