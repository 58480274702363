import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptInvitation,
  getInvitations,
  rejectInvitation,
  teamSelector,
} from "../../store/team";
import {
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CrownFilled,
  DownOutlined,
} from "@ant-design/icons";
import { AppDispatch } from "../../store";
import { Button, Dropdown, Menu, Table, Tooltip } from "antd";
import { setActivePage, uiSelector } from "../../store/ui";
import endpoints from "../../services/endpoints";
import { accountSelector } from "../../store/account";
import { Invitations, TeamUsers } from "../../store/team/types";
import { ColumnType } from "antd/lib/table";
import { notificationSelector } from "../../store/notification";

const TeamInvitationsList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { invitationsLoading, invitations, isSearchResults } =
    useSelector(teamSelector);
  const { collapsed } = useSelector(uiSelector);
  const { accountInfo, loggedIn } = useSelector(accountSelector);
  const { notifications } = useSelector(notificationSelector);

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [hoveredId, setHoveredId] = useState("");
  const [selectedInvitation, setSelectedInvitation] = useState("");
  const [blink, setBlink] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (loggedIn) {
      dispatch(getInvitations());
      const teamId = localStorage.getItem("teamId");
      if (teamId) {
        setSelectedInvitation(teamId);
        localStorage.removeItem("teamId");
        setBlink(true);
      }
    }
  }, [dispatch]);

  const handleAcceptInvitation = (teamId: string) => {
    dispatch(
      acceptInvitation({
        teamId,
        onSuccess: () => {
          dispatch(setActivePage("My Teams"));
        },
      })
    );
  };

  const handleRejectInvitation = (teamId: string) => {
    dispatch(
      rejectInvitation({
        teamId,
        onSuccess: () => {},
      })
    );
  };

  const mergedColumns: ColumnType<Invitations>[] = [
    {
      title: "Logo",
      dataIndex: "image",
      key: "logo",
      render: (image: string, record: { name: string }) => {
        const imgUrl = image ? `${endpoints.baseUrl}/${image}` : "";
        return image ? (
          <div className={styles.main_table_avatar}>
            <img src={imgUrl} alt="team" />
          </div>
        ) : (
          <div className={styles.main_table_avatar}>
            {record.name.charAt(0).toUpperCase()}
            {record.name.charAt(1).toUpperCase()}
          </div>
        );
      },
      width: "5rem",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      responsive: ["lg"],
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) => {
        const date = new Date(createdAt);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      },
      responsive: ["xl"],
    },
    {
      title: "Team Leader",
      dataIndex: "admin",
      key: "admin",
      render: (admin: TeamUsers[]) => {
        const menu = (
          <Menu>
            {admin.map((leader) => (
              <Menu.Item key={leader.username}>
                <div className={styles.main_table_userInfo}>
                  <div className={styles.main_table_userInfo_avatar}>
                    {leader.image ? (
                      <img
                        src={`${endpoints.baseUrl}/${leader.image}`}
                        alt="user"
                      />
                    ) : (
                      <div className={styles.main_table_userInfo_avatar_text}>
                        {leader.username.charAt(0).toUpperCase()}
                        {leader.username.charAt(1).toUpperCase()}
                      </div>
                    )}
                  </div>
                  <div className={styles.main_table_userInfo_text}>
                    <div>
                      {leader.firstName
                        ? `${leader.firstName} ${leader.lastName}`
                        : leader.username}
                    </div>
                  </div>
                </div>
              </Menu.Item>
            ))}
          </Menu>
        );
        return admin.length > 1 ? (
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            overlayStyle={{ userSelect: "none" }}
          >
            <span>
              {admin.length} admins{" "}
              <DownOutlined
                style={{
                  cursor: "pointer",
                }}
              />
            </span>
          </Dropdown>
        ) : (
          <>
            {accountInfo?.username === admin[0].username ? (
              <div className={styles.main_table_userInfo}>
                <div className={styles.main_table_userInfo_avatar}>
                  {admin[0].image ? (
                    <img
                      src={`${endpoints.baseUrl}/${admin[0].image}`}
                      alt="user"
                    />
                  ) : (
                    <div className={styles.main_table_userInfo_avatar_text}>
                      {admin[0].username.charAt(0).toUpperCase()}
                      {admin[0].username.charAt(1).toUpperCase()}
                    </div>
                  )}
                </div>
                <div className={styles.main_table_userInfo_text}>
                  <div>
                    {admin[0].firstName
                      ? `${admin[0].firstName} ${admin[0].lastName}`
                      : admin[0].username}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.main_table_userInfo}>
                <div className={styles.main_table_userInfo_avatar}>
                  {admin[0].image ? (
                    <img
                      src={`${endpoints.baseUrl}/${admin[0].image}`}
                      alt="user"
                    />
                  ) : (
                    <div className={styles.main_table_userInfo_avatar_text}>
                      {admin[0].username.charAt(0).toUpperCase()}
                      {admin[0].username.charAt(1).toUpperCase()}
                    </div>
                  )}
                </div>
                <div className={styles.main_table_userInfo_text}>
                  <div>
                    {admin[0].firstName
                      ? `${admin[0].firstName} ${admin[0].lastName}`
                      : admin[0].username}
                  </div>
                </div>
              </div>
            )}
          </>
        );
      },
      responsive: ["sm"],
    },
    {
      title: "",
      key: "action",
      render: (record: Invitations) => {
        return (
          <div className={styles.main_table_actions}>
            <Tooltip title="Accept Invitation">
              <div
                className={styles.main_table_actions_icon}
                onClick={() => handleAcceptInvitation(record._id)}
              >
                <CheckCircleOutlined style={{ color: "#01b503" }} />
              </div>
            </Tooltip>
            <Tooltip title="Decline Invitation">
              <div
                className={styles.main_table_actions_icon}
                onClick={() => handleRejectInvitation(record._id)}
              >
                <CloseCircleOutlined style={{ color: "#fd271f" }} />
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.main}>
      {invitationsLoading ? (
        <div className={styles.main_loading}>
          <LoadingOutlined style={{ color: "#f35d5a" }} />
        </div>
      ) : (
        <div className={styles.main_table}>
          <Table
            dataSource={invitations}
            columns={mergedColumns}
            pagination={false}
            scroll={{ y: windowHeight - 280 }}
            rowKey={(record) => record._id}
            style={{
              width: collapsed ? "calc(100vw - 20rem)" : "100%",
            }}
            onRow={(record) => {
              return {
                onMouseEnter: () => setHoveredId(record._id),
                onMouseLeave: () =>
                  hoveredId === record._id && setHoveredId(""),
                onClick: () => {
                  setSelectedInvitation(record._id);
                  setBlink(false);
                },
              };
            }}
            rowClassName={(record) =>
              record._id === selectedInvitation && !blink
                ? styles.main_table_row_selected
                : record._id === selectedInvitation && blink
                ? styles.main_table_row_blink
                : ""
            }
            locale={{
              emptyText: (
                <div className={styles.main_table_empty}>
                  {isSearchResults ? (
                    <>
                      <img src="/images/notFoundSearch.jpg" alt="empty" />
                      <span className={styles.main_table_empty_text}>
                        Opps, no results were found that match your search
                        criteria.
                      </span>
                    </>
                  ) : (
                    <>
                      <img src="/images/teamWork.jpg" alt="empty" />
                      <span className={styles.main_table_empty_text}>
                        You have not received any invitations yet. Invitations
                        will be listed here, keep checking!
                      </span>
                    </>
                  )}
                </div>
              ),
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TeamInvitationsList;
