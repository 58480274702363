import { User } from "../store/account/types";

interface getFriendNane {
  allFriends: User[];
  friendId: string;
}

export const getFriendName = ({ allFriends, friendId }: getFriendNane) => {
  const friend = allFriends.find((friend) => friend._id === friendId);
  return friend?.username;
};

interface get3Dots {
  text: string;
  length: number;
}

export const get3Dots = ({ text, length }: get3Dots) => {
  return text.length > length ? text.substring(0, length) + "..." : text;
};

const loremIpsum =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.";

export const generateRandomLoremIpsum = (wordCount: number) => {
  const words = loremIpsum.split(" ");
  let result = "";
  for (let i = 0; i < wordCount; i++) {
    result += words[Math.floor(Math.random() * words.length)] + " ";
  }
  return result.trim();
};
