// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainContent_main__hqSgz {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
}
.MainContent_main_content__XJn6U {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  box-sizing: border-box;
  padding: 1rem;
  gap: 1rem;
}
.MainContent_main_chatArea__xlEOC {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  bottom: 32px;
  right: 15px;
  position: fixed;
}`, "",{"version":3,"sources":["webpack://./src/components/MainContent/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,sBAAA;EACA,aAAA;EACA,SAAA;AAEJ;AAAE;EACE,aAAA;EACA,yBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;AAEJ","sourcesContent":[".main {\n  display: flex;\n  width: 100%;\n  height: 100vh;\n  flex-direction: column;\n  &_content {\n    display: flex;\n    flex-direction: row;\n    flex-grow: 1;\n    box-sizing: border-box;\n    padding: 1rem;\n    gap: 1rem;\n  }\n  &_chatArea {\n    display: flex;\n    justify-content: flex-end;\n    width: 100%;\n    bottom: 32px;\n    right: 15px;\n    position: fixed;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `MainContent_main__hqSgz`,
	"main_content": `MainContent_main_content__XJn6U`,
	"main_chatArea": `MainContent_main_chatArea__xlEOC`
};
export default ___CSS_LOADER_EXPORT___;
