// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddMemberModal_main__nL6sG {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
}
.AddMemberModal_main_input__eJ\\+51 {
  display: flex;
  align-items: center;
  justify-self: center;
  box-sizing: border-box;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  height: 2.5rem;
}
.AddMemberModal_main_input__eJ\\+51:hover {
  border-color: #1f1f1f;
}
.AddMemberModal_main_input__eJ\\+51:focus {
  border-color: #1f1f1f !important;
}
.AddMemberModal_main_button__C3BE9 {
  display: flex;
  align-items: center;
  justify-self: center;
  box-sizing: border-box;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  height: 2.5rem;
  background-color: #f35d5a;
  font-size: 1rem;
}
.AddMemberModal_main_button__C3BE9:hover {
  background-color: rgba(243, 92, 90, 0.8509803922) !important;
  color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/components/TeamList/Components/AddMemberModal/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,SAAA;AACF;AAAE;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,sBAAA;EACA,WAAA;EACA,qBAAA;EACA,wCAAA;EACA,cAAA;AAEJ;AADI;EACE,qBAAA;AAGN;AADI;EACE,gCAAA;AAGN;AAAE;EACE,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,sBAAA;EACA,qBAAA;EACA,wCAAA;EACA,cAAA;EACA,yBAAA;EACA,eAAA;AAEJ;AADI;EACE,4DAAA;EACA,uBAAA;AAGN","sourcesContent":[".main {\n  display: flex;\n  flex-direction: row;\n  width: 100%;\n  gap: 1rem;\n  &_input {\n    display: flex;\n    align-items: center;\n    justify-self: center;\n    box-sizing: border-box;\n    width: 100%;\n    border-radius: 0.5rem;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);\n    height: 2.5rem;\n    &:hover {\n      border-color: #1f1f1f;\n    }\n    &:focus {\n      border-color: #1f1f1f !important;\n    }\n  }\n  &_button {\n    display: flex;\n    align-items: center;\n    justify-self: center;\n    box-sizing: border-box;\n    border-radius: 0.5rem;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);\n    height: 2.5rem;\n    background-color: #f35d5a;\n    font-size: 1rem;\n    &:hover {\n      background-color: #f35c5ad9 !important;\n      color: white !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `AddMemberModal_main__nL6sG`,
	"main_input": `AddMemberModal_main_input__eJ+51`,
	"main_button": `AddMemberModal_main_button__C3BE9`
};
export default ___CSS_LOADER_EXPORT___;
